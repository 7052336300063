import { transformState, spreadState, pushState, omit } from "saga/transforms"

const init_state = {network:42}

export const metaMaskReducers = (state = init_state, action) => {
  const { type } = {...action}
  const payload = action.payload || {} //payload should always be an object
  //const user_id = payload.user_id || 'temp'
  const reducers = {
    default: () => state,
    R_ETH_UPDATE_NETWORK: () =>  {
      const {chain_id} = {...payload}
      return transformState(state, ['network'], chain_id)
    },
    R_ETH_UPDATE_ACCOUNT: () =>  {
      const {account} = {...payload}
      if(!account) return ({...state, metamask:'NO_ACCOUNT', account: null})
      return ({...state, metamask:'READY', account: account})
    },
  }
  const reducer = reducers[type] || reducers['default']
  return reducer()
}



