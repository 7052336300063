import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useTranslation } from 'react-i18next';

import { 
  usePolyConfig
} from "./use"

import { HandleErrorV3 } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3 } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"


export const LoadConfig = props => {
  const { children, networkId, accessToken} = {...props}
  const { t, i18n } = useTranslation();

  const [config, error, status, fetch, unmount] = useFetchOnMountV3(usePolyConfig, networkId, accessToken)
  useFetchOnChange(networkId, fetch)
  useUnmount([unmount])

  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING_CONFIG')}</Fragment>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>

  return children
}