import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'

import { createUnmount, createFailed, cancellableAsync} from 'saga/helpers'

import { 
  wLoadPolymathToken,
  wLoadPolymathTokenV2
} from './workers'

//'ETH_POLYMATH_DETAILS':wDetails,
const async_map = {
  'ETH_LOAD_POLYMATH_TOKEN': wLoadPolymathTokenV2,
}

export function* polymathWatchAsync() {
  while(true){
    const { type, payload, meta } = yield take(Object.keys(async_map))
    yield fork(cancellableAsync, async_map[type], type, payload, meta)
  }     
}