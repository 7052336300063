
import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import PropTypes from 'prop-types';  

import Joi from "joi";
import Decimal from 'decimal.js';
import { useTable, useFilters, usePagination } from 'react-table'
import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';


import { 
  useGetBalances
} from "./use"


import { ValidateData } from 'components/molecules/Validate'
//import { Toolbar } from 'components/molecules/Toolbar'
import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
//import { PKButton } from 'components/molecules/PKButton'
import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"

/*
const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
    <Typography>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}

*/
const useBalanceColumn = (reg) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('WALLET_ADDRESS'),
        accessor: 'walletAddress',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('SYMBOL'),
        accessor: 'SYMBOL',
      },
      {
        Header:  t('BALANCE'),
        accessor: 'BALANCE',
        Cell: ({value})=>{ 
          const decimals = 18  
          const amount = Decimal(value).mul(Decimal(10).pow(-decimals)).toString()
          return amount
        }
      },
      {
        Header:  t('RATIO'),
        accessor: 'ratio',
        Cell: ({value})=>{ 
          const ratio = Decimal(value).mul(100).toString()
          return `${ratio}%`
        }
      }
    ]
  ,[i18n.language])
  return columns  
}

const dataSchema = {
  'investors': Joi.array().items(
    Joi.object({
      investor_id: Joi.number().required(),
      email: Joi.string().required(),
      address: Joi.string().required(),
      balance: Joi.number().unsafe().required(),
      isWhitelisted: Joi.boolean().required(),
    }).required()
  ).required(),
}


export const AllBalances = props => {
  const { userId, networkId} = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetBalances, networkId, userId, 
  )

  const columns = useBalanceColumn()

  //useUnmount([ unmount, unmount2 ])
  //useRefreshOnSuccess(status2, fetch)
  //useFetchOnChange(networkId, fetch)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  const {results} = {...data}
  const parsed = []
  Object.entries(results).forEach(([stoAddress,stoData])=>{
    const { info, wallets } = {...stoData}
    const { DECIMALS, SUPPLY, SYMBOL } = {...info}
    return Object.entries(wallets).forEach(([walletAddress, walletData])=>{
      const { BALANCE } = {...walletData}
      const ratio = Decimal(BALANCE).dividedBy(Decimal(SUPPLY)).toString()
      if(BALANCE > 0){
        parsed.push({walletAddress, BALANCE, SUPPLY, SYMBOL, DECIMALS, ratio})
      }
      
    })
  })
  //return <pre>{JSON.stringify(parsed,0,2)}</pre>
  return <Fragment>
    <PaginationTable columns={columns} data={parsed} />
  </Fragment>
}


AllBalances.propTypes = {  
  userId: PropTypes.number.isRequired,  
  networkId: PropTypes.number.isRequired,  
}  



export const Logic = props => {
  const { userId, networkId } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetBalances, networkId, userId, 
  )

  const columns = useBalanceColumn()

  const {results} = {...data}
  const parsed = []
  Object.entries(results).forEach(([stoAddress,stoData])=>{
    const { info, wallets } = {...stoData}
    const { DECIMALS, SUPPLY, SYMBOL } = {...info}
    return Object.entries(wallets).forEach(([walletAddress, walletData])=>{
      const { BALANCE } = {...walletData}
      const ratio = Decimal(BALANCE).dividedBy(Decimal(SUPPLY)).toString()
      if(BALANCE > 0){
        parsed.push({walletAddress, BALANCE, SUPPLY, SYMBOL, DECIMALS, ratio})
      }
      
    })
  })
  //return <pre>{JSON.stringify(parsed,0,2)}</pre>
  return (data,columns, status, error)
}


export const Wrapped = props => {
  const { status, columns, data} = {...props}

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>

  return <Fragment>
    <PaginationTable columns={columns} data={data} />
  </Fragment>
}
