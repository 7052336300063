import config from 'setup/appConfig'

import { 
  race,call, put, takeEvery, all, 
  take, fork, cancel, select, join 
} from 'redux-saga/effects'

import { buffers, channel } from 'redux-saga'

//import jwtDecode from 'jwt-decode'
import get from 'lodash/get'

import { 
  handlePost, handleGet
} from 'saga/ETH/helpersV2'

import { 
  putSuccessV2, putFetchingV2, putFailedV2, putCleanV2, putPendingV2,
} from 'saga/puts'

import { selectAccessToken, selectRefreshToken } from 'utilities/selectors'
import { getData } from 'saga/ETH/wltoken/helpers'

export function* wLoginUser(type, payload, meta) {
  const { auth_code } = {...payload}
  const location = get(config,['backend_loc'], 'local')
  const resp = yield handlePost(`/auth/login`, { auth_code: auth_code, location })
  const data = getData(resp)
  const { access_token, roles } = {...data}
  yield put({
    type: 'STORE_ACCESS_TOKEN',
    payload: { access_token },
  }) 
  yield put({
    type: 'STORE_ROLES',
    payload: { roles },
  })  
  return data
}

export function* wLogoutUser(type, payload, meta) {
  yield put({
    type: 'REMOVE_ACCESS_TOKEN',
    //payload: { access_token },
  }) 
  return 'SUCCESS'
}

export function* wGetServerRoles(type, payload, meta) {
  const { userId } = {...payload}
  const resp = yield handlePost(
    "/auth/user_roles",
    { userId: userId },
  )
  const data = getData(resp)
  return data
}  

export function* wManageRoles(type, payload, meta) {
  const { 
    userId, roleData, 
  } = {...payload}
  const actions = Object.entries(roleData).map(([key,value])=>({
    userId: userId,
    role: key,
    method: value ? 'GRANT' : 'REVOKE'
  }))

  const resp = yield handlePost(
    "/auth/manage_roles", 
    { 
      actions: actions,
    }
  )
  const data = getData(resp)
  return data
}


/*
export function* _wLoginUser(type, payload, meta, api) {
  const {email,password} = {...payload}

  //throw an error if tokens are null?
  function* handleSuccess(resp){
    const access_token = get(resp, 'data.access_token', null)
    const refresh_token = get(resp, 'data.refresh_token', null)
    const decoded = jwtDecode(access_token)
    const userName = get(decoded, 'identity.email', null)
    const user_id = get(decoded, 'identity.id', null)
    yield put({
      type: 'STORE_TOKENS',
      payload: { access_token, refresh_token, userName, id: user_id },
    })
    yield put({
      type: 'UPDATE_STATUS',
      payload: { status: 'AUTHENTICATED' },
    })
    return ({ access_token, refresh_token, userName })
  }
  yield call(authHandleAsyncRedux, {
    name: type,
    func: handlePost(api, '/auth/login'),
    args: [{email,password}],
    handleSuccess,
    //handleError
  })  
}

export function* VALIDATE_TOKEN(type, payload, meta, api) {
  const { token } = {...payload}
  yield call(authHandleAsync, {
    meta,
    type,
    func: handlePost(api, '/auth/is_token_valid', token),
    args: [],
  })
}

export function* wRegisterUser(type, payload, meta, api) {
  const { email, password, first_name, last_name } = {...payload}
  yield call(authHandleAsyncRedux, {
    name: type,
    func: handlePost(api, '/auth/register_user'),
    args: [{email, password, first_name, last_name}],
  })
}

export function* wResetPasswordByCodeRequest(type, payload, meta, api) {
  const { email } = {...payload}
  yield call(authHandleAsync, {
    meta,
    type,
    func: handlePost(api, '/auth/reset_password_by_code_request'),
    args: [{email}],
    //handleError,
    //handleSuccess
  })

  return email
}

export function* wResetPasswordByCodeConfirm(type, payload, meta, api) {
  const { email, confirm_code } = {...payload}
  const { reset_token } = yield call(authHandleAsync, {
    meta,
    type,
    func: handlePost(api, '/auth/reset_password_by_code_confirm'),
    args: [{email, confirm_code}],
    //handleSuccess
  })
  return reset_token
}

export function* wResetPassword(type, payload, meta, api) {
  const { access_token, password } = {...payload}
  yield call(authHandleAsync, {
    meta,
    type,
    func: handlePost(api, '/auth/reset_password'),
    args: [{token: access_token, password}]
  })
}

export function* wResetPasswordByEmailRequest(type, payload, meta, api) {
  const { email } = {...payload}
  yield call(authHandleAsync, {
    meta,
    type,
    func: handlePost(api, '/auth/reset_password_request'),
    args: [{email}],
  })
}

export function* wLogoutUser(type, payload, meta) {
  //const { type, payload, meta } = yield take('LOGOUT_USER')
  const { all } = {...payload}
  yield put({ type: 'CLEAR_AUTH' })
  if(all){
    yield put({ type: 'CLEAR_GXC' })
  }
  yield put({
    type: 'UPDATE_STATUS',
    payload: { status: 'NOT_AUTHENTICATED' },
  })
}


export function* wRefreshAccessToken(type, payload, meta, api) {
  const { token, password } = {...payload}
  const refresh_token = yield select(selectRefreshToken)

  function* handleSuccess(resp){
    const access_token = get(resp, 'data.access_token', null)
    yield put({
      type: 'STORE_ACCESS_TOKEN',
      payload: { access_token },
    })
    yield put({
      type: 'UPDATE_STATUS',
      payload: { status: 'AUTHENTICATED' },
    })
    //return ({ access_token })
  }

  function* handleError(err){
    yield put({
      type: 'UPDATE_STATUS',
      payload: { status: 'NOT_AUTHENTICATED' },
    })
    return get(err, 'response.data.msg', err.message)
  }
  yield put({
    type: 'UPDATE_STATUS',
    payload: { status: 'REFRESHING' },
  })

  yield call(authHandleAsyncRedux, {
    //meta,
    name: type,
    func: handlePost(api, '/auth/refresh', refresh_token),
    args: [],
    handleSuccess,
    handleError
  })
}
*/