import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

import get from "lodash/get"
import reduce from "lodash/reduce"

import { 
  handleContractSign
} from 'saga/ETH/helpers'

import { 
  putSuccessV2, putFetchingV2, putFailedV2, putCleanV2, putPendingV2,
} from 'saga/puts'

import { handlePost, handleGet, waitForTask, handlePostFile } from 'saga/ETH/helpersV2'


//import {  } from "utilities/selectors"
import { joiValidate, } from "utilities/joi/sto"

import {  getData } from 'saga/ETH/wltoken/helpers'



export function* wOfferSell(type, payload, meta) {
  const { 
    privateKey, networkId, stoAddress, stoAmount,
    currency,price  
  } = {...payload}
  const resp = yield handlePost(
    `/swapcat/${networkId}/make_offer`, 
    { 
      private_key: privateKey, 
      maker_token: stoAddress, 
      taker_token: currency, 
      maker_token_amount: stoAmount,
      price 
    }
  )
  const data = getData(resp)
  return data
}

export function* wOfferList(type, payload, meta) {
  const { 
    networkId, stoAddress,  
  } = {...payload}
  const resp = yield handleGet(
    `/swapcat/${networkId}/offer_list/${stoAddress}`, 
  )
  const data = getData(resp)
  return data
}


export function* wTakeOffer(type, payload, meta) {
  const { 
    privateKey, networkId, offerId, makerAmount,
    price  
  } = {...payload}
  const resp = yield handlePost(
    `/swapcat/${networkId}/take_offer`, 
    { 
      private_key: privateKey,
      offer_id: offerId,
      maker_token_amount: makerAmount, 
      price: price, 
    }
  )
  const data = getData(resp)
  return data
}

export function* wDeleteOffer(type, payload, meta) {
  const { 
    privateKey, networkId, offerId  
  } = {...payload}
  const resp = yield handlePost(
    `/swapcat/${networkId}/delete_offer`, 
    { 
      private_key: privateKey,
      offer_id: offerId,
    }
  )
  const data = getData(resp)
  return data
}


export function* wUpdatePrice(type, payload, meta) {
  const { 
    privateKey, networkId, offerId, newPrice
  } = {...payload}
  const resp = yield handlePost(
    `/swapcat/${networkId}/update_price`, 
    { 
      private_key: privateKey,
      offer_id: offerId,
      newPrice: newPrice, 
    }
  )
  const data = getData(resp)
  return data
}


export function* wUpdateAmount(type, payload, meta) {
  const { 
    privateKey, networkId, offerId, newAmount
  } = {...payload}
  const resp = yield handlePost(
    `/swapcat/${networkId}/update_amount`, 
    { 
      private_key: privateKey,
      offer_id: offerId,
      newAmount: newAmount, 
    }
  )
  const data = getData(resp)
  return data
}