import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState } from "react-hook-form"
import Joi from "joi";
import { joiResolver } from '@hookform/resolvers/joi';
import { Decimal } from 'decimal.js'

import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { ethAddress } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"


export const useGetUsersV2 = (networkId, tokenAddress, types) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_USERS_V2'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: { networkId, tokenAddress, types }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]
}

export const useGetWallets = () => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_WALLETS'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: {} })
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]
}


export const useCreateFundV2 = (networkId, tokenAddress)=> {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_CREATE_FUND'
  const [data, error, status] = useThunkDataV3([action])

  const submit = (formData) => {

    const {yen, token_amount, walletAddress} = {...formData}
    const decimals = 18
    const _tokenAmount = Decimal(token_amount).mul(Decimal(10).pow(decimals)).toFixed()
    dispatch({type: action, payload: { 
      networkId, tokenAddress, 
      wallet_address: walletAddress, 
      yen, 
      token_amount:_tokenAmount
    }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, submit, unmount]
}

export const useCreateFundFormV2 = () => {
  const { register, handleSubmit, formState:{ errors, isValid, touchedFields }, watch, resetField} = useForm({
    resolver: joiResolver(Joi.object({
      walletAddress: ethAddress(),
      token_amount: Joi.number().greater(0).required().messages({
        "number.greater": "MUST_BE_GREATER_THAN_ZERO",
        "number.base":  "MUST_BE_NUMBER"
      }),
      yen: Joi.number().greater(0).required().messages({
        "number.greater": "MUST_BE_GREATER_THAN_ZERO",
        "number.base":  "MUST_BE_NUMBER"
      })
    })),
  });
  const walletAddress = watch('walletAddress')
  return [
    [register('walletAddress'), register('token_amount'), register('yen')], 
    handleSubmit,
    {
      errors: {
        walletAddress: get(errors,['walletAddress','message'], null),
        token_amount: get(errors,['token_amount','message'], null),
        yen: get(errors,['yen','message'], null),
      },
      touchedFields,
      fields: {
        walletAddress
      }
    },
    ()=> {
      resetField('walletAddress')
      resetField('token_amount')
    }

  ]
}

export const useCreateFund = (networkId, tokenAddress, walletAddress)=> {
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3(['ETH_STO_CREATE_FUND'])

  const submit = (formData) => {
    const {yen, token_amount} = {...formData}
    const decimals = 18
    const _tokenAmount = Decimal(token_amount).mul(Decimal(10).pow(decimals)).toFixed()
    dispatch({type: 'ETH_STO_CREATE_FUND', payload: { 
      networkId, tokenAddress, 
      wallet_address: walletAddress, yen, 
      token_amount:_tokenAmount
    }})
  }
  const unmount = createUnmountDispatch(dispatch, 'ETH_STO_CREATE_FUND')
  return [data, error, status, submit, unmount]
}

