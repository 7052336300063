import get from 'lodash/get'
import reduce from 'lodash/reduce'


//AUTH
export const selectStore = store => store
export const selectAccessToken = store => get(store, 'auth.access_token', null)
export const selectRefreshToken = store => get(store, 'auth.refresh_token', null)
export const selectUserId = store => get(store, ['auth','id'], 'temp')
export const selectUserName = store => get(store, ['auth','user_name'], 'nousername')
export const selectUserRoles = (store) => get(store, ['auth','roles'], [])



//GXC
export const selectGXCStorage = store => get(store, 'GXC.storage', [])
export const selectGXCAccountHead = store => get(store, 'GXC.account_head', null)
export const selectGXCAccountHeadId = store => get(store, 'GXC.account_head_id', null)

export const selectNEMStorage = user_id => store => get(store, ['NEM', user_id,'storage'], {})
export const selectNEMWalletHead = user_id => store => {
  const storage = selectNEMStorage(user_id)(store)
  return get(storage, ['0', 'address'], null)
}
export const selectAccountTxs = (user_id, address) => (store) => get(store, ['NEM',user_id,'account_tx_storage', address], [] )
export const selectAccountPendingTx = (user_id, address) => (store) => get(store, ['NEM',user_id,'account_data_storage', address, 'pending_tx'], [] )
export const selectAccountLatestTx = (user_id,address) => (store) => get(store, ['NEM',user_id,'account_data_storage', address, 'latest_tx'], [] )
export const selectAccountLatestTxNum = (user_id,address) => (store) => get(store, ['NEM',user_id,'account_data_storage', address, 'latest_tx_num'], 0 )
export const selectAccountLatestTxRead = (user_id,address) => (store) => get(store, ['NEM',user_id,'account_data_storage', address, 'latest_tx_read'], 0 )
export const selectAccountData = (user_id, address) => (store) => get(store, ['NEM',user_id,'account_data_storage', address], {} )
export const selectAccountStatus = (user_id,address) => (store) => {
  const status = get(store, ['NEM',user_id,'account_data_storage', address, 'status'], 'FRESH')
  return status
}
export const selectAccountBalance = (user_id,address) => (store) => {
  const balance = get(store, ['NEM',user_id,'account_data_storage', address, 'balance'], null)
  return balance
}
export const selectAccountLastUpdated = (user_id,address) => (store) => {
  const last_updated = get(store, ['NEM',user_id,'account_data_storage', address,'last_updated'], null)
  return last_updated
}
export const selectAccountLastBackup = (user_id,address) => (store) => {
  const last_backup = get(store, ['NEM',user_id,'account_data_storage', address, 'last_backup'], null)
  return last_backup
}

export const selectFetching = (type, _default=false) => (store) => {
  const fetching = get(store, ['thunk', 'FETCHING', type], _default)
  return fetching
}
export const selectFetchingByAddress = (type, address) => (store) => {
  const fetching = get(store, ['thunk', 'FETCHING', 'by_address', type, address], false)
  return fetching
}
export const selectQueued = (type) => (store) => {
  const queued = get(store, ['thunk', 'QUEUED', type], false)
  return queued
}
export const selectQueuedByAddress = (type, address) => (store) => {
  const queued = get(store, ['thunk', 'QUEUED', 'by_address', type, address], {})
  return queued
}
export const selectError = (type) => (store) => {
  const error = get(store, ['thunk', 'ERROR', type], null)
  return error
}
export const selectErrorByAddress = (type, address) => (store) => {
  const error = get(store, ['thunk', 'ERROR', 'by_address', type, address], null)
  return error
}
export const selectData = (type, _default=null) => (store) => {
  const data = get(store, ['thunk', 'DATA', type], _default)
  if(data) return data
  return _default
}

export const selectDataByAddress = (type, address, _default=null) => (store) => {
  const data = get(store, ['thunk', 'DATA', 'by_address', type, address], _default)
  return data
}
export const selectPending = (type, _default=false) => (store) => {
  const pending = get(store, ['thunk', 'PENDING', type], _default)
  return pending
}
export const selectMeta = (type, _default={}) => (store) => {
  const meta = get(store, ['thunk', 'META', type], _default)
  return meta
}

export const selectSideOpen = (store) => get(store, ['system', 'side_bar', 'open'], false)
export const selectModalOpen = (store) => get(store, ['system', 'modal', 'open'], false)
export const selectModalType = (store) => {
  const stack = get(store, ['system', 'modal', 'stack'], [])
  const modal_type = stack[stack.length-1] || null
  return modal_type
}
export const selectModalProps = (store) => get(store, ['system', 'modal', 'props'], {})
export const selectAlertSuccess = (store) => get(store, ['system', 'alert', 'success'], null)
export const selectAlertError = (store) => get(store, ['system', 'alert', 'error'], null)
export const selectNavTitle = (store) => get(store, ['system', 'nav', 'title'], null)
export const selectBgColor = (store) => get(store, ['system', 'background', 'color'], null)
export const selectSystemStatus = (store) => get(store,['system', 'status'], null)


export const selectETHPending = (store) => get(store,['ETH', 'pending'], false)
export const selectETHProducts = (store) => get(store,['ETH', 'products'], [])
export const selectETHProductMap = (store) => get(store,['ETH', 'product_map'], {})
export const selectETHProductList = (store) => get(store,['ETH', 'product_list'], [])
export const selectETHOrderMap = (store) => get(store,['ETH', 'order_map'], {})
export const selectETHOrderList = (store) => get(store,['ETH', 'order_list'], [])
export const selectETHCart = (store) => get(store,['ETH', 'cart'], [])
export const selectETHProcessedOrders = (store) => get(store,['ETH', 'processed_orders'], [])



//thunk
const parsePath = path => {
  let _path = path
  if(!Array.isArray(path)) _path = [path]
  return _path  
}
export const selectDataV2= (path, _default=null) => (store) => {
  const _path = parsePath(path)
  const data = get(store, ['thunk', 'DATA', ..._path], _default)
  if(data) return data
  return _default
}
export const selectErrorV2= (path, _default=null) => (store) => {
  const _path = parsePath(path)
  const data = get(store, ['thunk', 'ERROR', ..._path], _default)
  if(data) return data
  return _default
}
export const selectFetchingV2= (path, _default=null) => (store) => {
  const _path = parsePath(path)
  const data = get(store, ['thunk', 'FETCHING', ..._path], _default)
  if(data) return data
  return _default
}
export const selectPendingV2= (path, _default=null) => (store) => {
  const _path = parsePath(path)
  const data = get(store, ['thunk', 'PENDING', ..._path], _default)
  if(data) return data
  return _default
}
export const selectStatusV2= (path, _default=null) => (store) => {
  const _path = parsePath(path)
  const data = get(store, ['thunk', 'STATUS', ..._path], _default)
  if(data) return data
  return _default
}

export const selectErrorType = errorResp => get(errorResp, ['type'], 'UNKNOWN_TYPE')
export const selectErrorSubtype = errorResp => get(errorResp, ['payload','subtype'], selectErrorType(errorResp))

////metamask
export const getHasMetaMask = (store) => {
  return get(store, ['ETH','hasMetaMask'], false)
}
export const selectNetworkId = (store) => {
  return get(store, ['metamask','network'], false)
}
/*
export const getNetworkId = (store) => {
  return get(store, ['ETH','network'], false)
}
*/



/// poly
export const selectPolyConfig = network_id => store => {
  return get(store, ['polymath', 'config', network_id], {})
}

export const selectGtmAddress = (network_id, tokenAddress) => (store) =>  get(store, ['polymath', 'config', network_id, 'stoMap', tokenAddress, 'gtmAddress'], null)
export const selectPolyTitle = (network_id, tokenAddress) => (store) =>  get(store, ['polymath', 'config', network_id, 'stoMap', tokenAddress, 'NAME'], null)
export const selectPolySymbol = (network_id, tokenAddress) => (store) =>  get(store, ['polymath', 'config', network_id, 'stoMap', tokenAddress, 'SYMBOL'], null)



//WLTOKEN
export const selectSTOaddresses = network_id => store => get(store, ['wltoken', 'config', network_id, 'stoAddresses'], [])
export const selectSTOmap = network_id => store => get(store, ['wltoken', 'config', network_id, 'stoMap'], [])
export const selectMultiSTODetails= (network_id, tokenAddresses) => (store) =>  {
  const storage = get(store, ['wltoken', 'config', network_id, 'stoMap'], {})
  const data = tokenAddresses.map(address=> ({
    title: get(storage, [address,'title'], null),
    symbol: get(storage, [address,'symbol'], null),
    address: address
  }))
  return data
}
export const selectStoDetails= (network_id, tokenAddress) => (store) =>  {
  const details = get(store, ['wltoken', 'config', network_id, 'stoMap', tokenAddress], {})
  const data = [
    get(details, ['NAME'], null),
    get(details, ['SYMBOL'], null),
    get(details, ['DECIMALS'], null),
    get(details, ['SUPPLY'], null),
    tokenAddress
  ]
  return data
}

export const selectTokenRoles = (store) => {
  const role_dict = get(store, ['wltoken','roles'], {})
  const reduced = reduce(role_dict,(result, value, key)=>{
    Object.entries(value).forEach(([_key,_value])=>{
      if(_value){
        result[_key] = _value
      }      
    })
    return result
  },{})
  return Object.entries(reduced).map(([_key,_value])=>_key)
} 


