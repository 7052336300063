import { cancel, put } from 'redux-saga/effects'


import { 
  putSuccessV2, putFetchingV2, putFailedV2, putCleanV2, putPendingV2,
} from 'saga/puts'

import { waitForTask } from 'saga/ETH/helpersV2'

import get from "lodash/get"
import reduce from "lodash/reduce"

import { joiValidate } from "utilities/joi/sto"

export const getData = resp => get(resp, ['data'], {})

const parseError = (err) => {
  const response = err.response
  if(!response) {
    return ({ message: err.message, type: 'NETWORK_ERROR', payload: {subtype: 'NETWORK_ERROR'}})
  }
  const { status , statusText, data } = {...response}
  const { message, type, payload} = {...data}
  return { status , statusText, message, type, payload }
}

function* handleValidate(type, payload, schema) {
  const [value ,error] = joiValidate(payload, schema)
  const { details } = { ...error}
  if(error){ 
    yield putFailedV2(type, {
      type: 'REDUX_PAYLOAD_ERROR', 
      payload: {details}, 
      message: 'payload is incorrect'
    })
    yield cancel()
  }
  return value
}

export function validateWrapper(func, schema){
  function* validate(...args){
    const [ type, payload, meta ] = [...args]
    if(schema){
      yield handleValidate(type, payload, schema)
    }
    yield func(...args)
  }
  return validate
}
// needs to handle token related errors: expired etc
export function statusWrapper(func, options={}){
  const {pending} = {...options}
  function* handlePuts(...args){
    const [ type, payload, meta ] = [...args]
    try {
      yield putFetchingV2(type)
      let data = yield func(...args)
      if(pending){
        const {status, taskId} = {...data}
        console.warn(status, taskId)
        yield putPendingV2(type)
        data = yield waitForTask(type, taskId)
      }
      //const data = getData(resp)
      yield putSuccessV2(type, data)
    }
    catch(err) {
      console.warn(err)
      const { message, type: errType, payload } = parseError(err)
      const {subtype} = { ...payload}
      if(subtype === 'EXPIRED_TOKEN') {
        yield put({type: 'REMOVE_ACCESS_TOKEN'})
      }
      yield putFailedV2(type, {type: errType, payload, message})
    }
  }
  return handlePuts
}

export function csvJSON(csv){
  //var lines=csv.split('\n').trim(); //this left a \r as the head of last column entry
  var lines=csv.split(/\r\n|\n|\r/);
  var result = [];
  var headers=lines[0].split(',');
  lines.splice(0, 1);
  lines.forEach(function(line) {
    var obj = {};
    var currentline = line.split(',');
    headers.forEach(function(header, i) {
      if (header == "Wallet_list"){
        obj[header] = JSON.parse(currentline[i]);
      }
      else{
        obj[header] = currentline[i] ? currentline[i] : null;
      }
    });
    result.push(obj);
  });
  return result; //JavaScript object
  //return JSON.stringify(result); //JSON
}

export function readCsv(csvFile) {
  return new Promise((resolve,reject)=> {
    const reader  = new FileReader();
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsText(csvFile)
  })
}


export function* handleLinkClick(data, fileName='sample') {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`); //or any other extension
  document.body.appendChild(link);
  link.click();
  return 'SUCCESS'
}

export const investorReducer = (cur,nex) => {
  const nex_id = nex['investor_id'] || 'none'

  if(cur[nex_id]) {
    const _wallet = cur[nex_id]['wallet'] || []
    cur[nex_id]['wallet'] = [{
      address: nex['address'],
      balance: nex['balance'],
      isWhitelisted: nex['isWhitelisted']
    },..._wallet]
  } else {
    //const new_cur = cur[nex_id || 'none']
    cur[nex_id] = {
      investor_id: nex['investor_id'] || '-',
      email: nex['email'] || '-',
      wallet: [{      
        address: nex['address'],
        balance: nex['balance'],
        isWhitelisted: nex['isWhitelisted']
      }]
    }
  }
  return cur
}
