import React, {Fragment, useEffect, useMemo, useState} from 'react';

/*
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
*/

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { useTranslation } from 'react-i18next';

import { HandleError } from 'components/molecules/HandleStatus'
import { useDefaultRoute } from 'components/useHooks/useRoute'
import { useStyles } from 'components/useHooks/useStyles'


//import { Fetching, FetchText} from 'components/molecules/Fetching'
//import { useThunkData } from 'components/useHooks/useThunk'

import { useNetworkV2, useNetworkV3 } from "./use"
import { useHistory } from 'react-router-dom'


export const NetworkSelect = props => {
  const { t, i18n } = useTranslation();
  const [network_id, updateNetwork] = useNetworkV2()
  const handleChange = e => {
    updateNetwork(e.target.value)
  }
  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">{t('NETWORK')}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={network_id}
      label={t('NETWORK')}
      onChange={handleChange}
      sx={{backgroundColor: 'white'}}
      size='small'
    >
      <MenuItem value={1}>mainnet</MenuItem>
      <MenuItem value={3}>ropsten</MenuItem>
      <MenuItem value={42}>kovan</MenuItem>
      <MenuItem value={137}>polygon</MenuItem>
      <MenuItem value={5777}>ganache</MenuItem>
      <MenuItem value={80001}>mumbai</MenuItem>
      <MenuItem value={11155111}>sepolia</MenuItem>
    </Select>
  </FormControl>
}

export const NetworkSelectV3 = props => {
  //const { handleChange } = {...props}
  const { t, i18n } = useTranslation();
  const [network_id] = useNetworkV3()
  const defaultRoute = useDefaultRoute()

  const handleChange = (e) => {
    defaultRoute('/', { networkId:e.target.value })
  }
  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">{t('NETWORK')}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={network_id}
      label={t('NETWORK')}
      onChange={handleChange}
      sx={{backgroundColor: 'white'}}
      size='small'
    >
      <MenuItem value={1}>mainnet</MenuItem>
      <MenuItem value={3}>ropsten</MenuItem>
      <MenuItem value={42}>kovan</MenuItem>
      <MenuItem value={137}>polygon</MenuItem>
      <MenuItem value={5777}>ganache</MenuItem>
      <MenuItem value={80001}>mumbai</MenuItem>
    </Select>
  </FormControl>
}

export const NetworkSelectV4 = props => {
  const { handleChange } = {...props}
  const { t, i18n } = useTranslation();
  const [network_id] = useNetworkV3()


  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">{t('NETWORK')}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={network_id}
      label={t('NETWORK')}
      onChange={e=>handleChange(e.target.value)}
      sx={{backgroundColor: 'white'}}
      size='small'
    >
      <MenuItem value={1}>mainnet</MenuItem>
      <MenuItem value={3}>ropsten</MenuItem>
      <MenuItem value={42}>kovan</MenuItem>
      <MenuItem value={137}>polygon</MenuItem>
      <MenuItem value={5777}>ganache</MenuItem>
      <MenuItem value={80001}>mumbai</MenuItem>
      <MenuItem value={11155111}>sepolia</MenuItem>
    </Select>
  </FormControl>
}


export const NetworkSelectV5 = props => {
  const { 
    handleChange=()=>null,
    t=text=>text,
    network_id=null
  } = {...props}

  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">{t('NETWORK')}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={network_id}
      label={t('NETWORK')}
      onChange={e=>handleChange(e.target.value)}
      sx={{backgroundColor: 'white'}}
      size='small'
    >
      <MenuItem value={1}>mainnet</MenuItem>
      <MenuItem value={3}>ropsten</MenuItem>
      <MenuItem value={42}>kovan</MenuItem>
      <MenuItem value={137}>polygon</MenuItem>
      <MenuItem value={5777}>ganache</MenuItem>
      <MenuItem value={80001}>mumbai</MenuItem>
      <MenuItem value={11155111}>sepolia</MenuItem>
    </Select>
  </FormControl>
}
/*
export const RequireNetworkId = props => {
  const { reRoute, children } = {...props}
  const [network_id] = useNetworkV3()
  useEffect(()=>{
    if(!network_id) {
      reRoute()
    }    
  },[network_id])
  return children  
}
*/
/*
export const LoadNetwork = ({children})=> {
  const dispatch = useDispatch() 
  const [data, error, detectingNetwork] = useThunkData('ETH_LOAD_NETWORK')
  const [first, setFirst] = useState(true)
  const network_id = useSelector(store=>store['ETH']['network'])
  //const isRopsten = useSelector(store=>store['ETH']['isRopsten'])
  const loading = first || detectingNetwork
  useEffect(()=>{
    dispatch({type:'ETH_LOAD_NETWORK'})
    first && setFirst(false)
  },[])

  return children(network_id,error,loading)
}
*/
/*
export const RequireRopstenNetwork = ({children})=> {
  return <LoadNetwork>{(network_id,error,loading)=>{
    if(loading) return <div>detecting network...</div>
    if(network_id!==3) return <div>Please switch to the Ropsten network</div>
    return children
  }}</LoadNetwork>
}

export const RequireKovanNetwork = ({children})=> {
  const network_id = useSelector(selectNetworkId)
  if(network_id!==42) return <div>Please switch to the Kovan network</div>
  return children 
}

export const RequireMainnetNetwork = ({children})=> {
  return <LoadNetwork>{(network_id,error,loading)=>{
    if(loading) return <div>detecting network...</div>
    if(network_id!==1) return <div>Please switch to the Mainnet network</div>
    return children
  }}</LoadNetwork>
}

// not to be used as a wrap
export const WatchNetwork = ({children})=> {
  const dispatch = useDispatch() 
  const [data, error, fetching] = useThunkData('ETH_WATCH_NETWORK')

  useEffect(()=>{
    dispatch({type:'ETH_WATCH_NETWORK'})
    return ()=> dispatch({type:'ETH_WATCH_NETWORK_UNMOUNT'})
  },[])
  //if(fetching) return <div>detecting network...</div>
  return null
}
*/