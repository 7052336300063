import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';


import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Divider,
  Grid,
  TextField,
  Modal,
  FormControlLabel,
  Typography
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import { FormControlLabel_ } from 'components/molecules/Forms'
import { useStyles } from 'components/useHooks/useStyles'


export const PKButtonV2 = props => {
  const { open, handleOpen, handleClose, PKReg, handleSend, touched, errText} = {...props}
  const { t, i18n } = useTranslation();

  return <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  > 

    <form
      autoComplete="off"
      noValidate
      //{...props}
    >
      <Card sx = {{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        //backgroundColor: 'white',
        //border: '2px solid #000',
        boxShadow: 24,
        //p: 4, 
      }}>
        <CardHeader
          subheader={t('PRIVATE_KEY_DESCRIPTION')}
          title={t('PRIVATE_KEY')}
        />
        <Divider />
        <CardContent>
          <FormControlLabel_ 
            //name={"private_key"} 
            label={t("PRIVATE_KEY")} 
            reg={PKReg}
            touched={touched}
            errText={errText}
          />
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSend}
          >
            {t('SEND')}
          </Button>
        </Box>
      </Card>
    </form>
  
  </Modal>
};

export const PKButton = props => {
  const { 
    PKReg, handleSend, touched, errText, children, disabled=false,
    subheader='MOCK_DESCRIPTION', title='MOCK_TITLE'
  } = {...props}
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  return <Fragment>
    <Card >
      <CardHeader
        subheader={t(subheader)}
        title={t(title)}
      />
      <Divider />
      
      <CardContent>
        {children}
        { !disabled &&
          <FormControlLabel_ 
            //name={"private_key"} 
            label={t("PRIVATE_KEY")} 
            reg={PKReg}
            touched={touched}
            errText={errText}
          />          
        }

      </CardContent>

      <Divider />
      { !disabled &&
        <CardActions          
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            pr: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSend}
            disabled={disabled}
          >
            {t('SEND')}
          </Button>
        </CardActions>
      }
    </Card>
  </Fragment>
}
