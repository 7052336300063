 import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm } from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';

import { useThunkDataV3 } from 'components/useHooks/useThunk'
//import { unlinkWalletSchema } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"


export const useUnlinkWallet = ()=> {
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3(['ETH_STO_UNLINK_WALLET'])

  const submit = (user_id, wallet_address) => {
    //const { wallet_address } = {...formData}
    dispatch({type: 'ETH_STO_UNLINK_WALLET', payload: { 
      walletAddress: wallet_address,
      userId: user_id
    }})
  }
  const unmount = createUnmountDispatch(dispatch, 'ETH_STO_UNLINK_WALLET')
  return [data, error, status, submit, unmount]
}

/*
export const useUnlinkWallettForm = () => {
  const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver: joiResolver(unlinkWalletSchema),
  });
  return [register('wallet_address'), handleSubmit, errors]
}
*/