import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

import get from "lodash/get"
import reduce from "lodash/reduce"

import { 
  handleContractSign
} from 'saga/ETH/helpers'

import { 
  putSuccessV2, putFetchingV2, putFailedV2, putCleanV2, putPendingV2,
} from 'saga/puts'

import { handlePost, handleGet, waitForTask, handlePostFile } from 'saga/ETH/helpersV2'


import { selectAccessToken } from "utilities/selectors"
import { 
  joiValidate, loadConfigSchema, addToWhitelistSchema,
  removeWhitelistSchema, multiIssueFundSchema, getFundsSchema,
  getEmailSchema, setEmailSchema, createWFundSchema,
  cancelFundSchema, createUserSchema, wGetUsersSchema,
  wGetUsersV2Schema, wUsersInfoSchema, wGetInvestorsSchema
} from "utilities/joi/sto"

import { 
  getData, csvJSON, readCsv, handleLinkClick, investorReducer
} from './helpers'


export function* wAddToWhitelistSign(type, payload, meta) {
  const { 
    tokenAddress, investorAddresses=[], privateKey, networkId 
  } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/multi_add_to_whitelist`, 
    { private_key: privateKey, addresses: investorAddresses },
    accessToken
  )
  const data = getData(resp)
  return data
}




export function* wRemoveFromWhitelistSign(type, payload, meta) {
  const { 
    tokenAddress, addresses=[], privateKey, networkId 
  } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/multi_remove_from_whitelist`, 
    { private_key: privateKey, addresses: addresses },
    accessToken
  )
  const data = getData(resp)
  return data
} 



export function* wGetEmail(type, payload, meta) {
  const { type:email_type } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(`/config/get_email`, {email_type: email_type}, accessToken)
  const data = getData(resp)
  return data
}  

export function* wSetEmail(type, payload, meta) {
  const { type:email_type, subject, message } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    '/config/edit_email', 
    { email_type: email_type, subject, message }, 
    accessToken
  )
  const data = true
  return data
}  


/*
export function* wCreateUser(type, payload, meta) {
  const { email,addresses } = {...payload}
  const resp = yield handlePost('/wltoken/create_user', { email, addresses })
  const data = getData(resp)
  return data
}  
*/

export function* wGetUsers(type, payload, meta) {
  const { adminsOnly } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(`/wltoken/get_user`, {adminsOnly} , accessToken)
  const data = getData(resp)
  return data
}


export function* wGetUsersV2(type, payload, meta) {
  const { tokenAddress, networkId, types} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(`/wltoken/${networkId}/token/${tokenAddress}/get_users_v2`, {types}, accessToken)
  const data = getData(resp)
  return data
}  

export function* wGetInvestorWallets(type, payload, meta) {
  const { tokenAddress, networkId, types} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/get_investor_wallets`, 
    {types},
    accessToken
  )
  const data = getData(resp)
  return data
}  

export function* wUsersInfo(type, payload, meta) {
  const { userId, types} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/user/user_info/${userId}`, 
    {types},
    accessToken
  )
  const data = getData(resp)
  return data
}  




export function* wGetInvestors(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet(`/wltoken/${networkId}/token/${tokenAddress}/investors`, {}, accessToken)
  const data = getData(resp)
  return data
  const investorObject = reduce(data, investorReducer, {})
  const investorList = Object.keys(investorObject).map((key)=>({...investorObject[key]}))
  return investorList
}  

export function* wGetWallets(type, payload, meta) {
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet('/wltoken/get_wallets', {}, accessToken)
  const data = getData(resp)
  return data
}  


export function* wLoadConfig(type, payload, meta) {
  const { networkId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet(`/wltoken/${networkId}/network_config`, {}, accessToken)
  const data = getData(resp)
  const {stoList, stoDict} = {...data}
  const config = { stoAddresses: stoList, stoMap: stoDict }
  yield put({type: 'R_ETH_WLTOKEN_UPDATE_CONFIG', payload: {networkId, config: config} })
  return config
}  

export function* wLoadUserRoles(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/check_user_roles`,
    { roles: ['ADMIN_ROLE', 'PAUSER_ROLE', 'MINTER_ROLE', 'BURNER_ROLE', 'WHITELIST_ADMIN_ROLE'] },
    accessToken
  )
  const data = getData(resp)
  const { roles } = {...data}
  yield put({
    type: 'R_ETH_WLTOKEN_STORE_ROLES',
    payload: { roles },
  }) 


  return data
}  


export function* wGetPause(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet(`v1/${networkId}/token/${tokenAddress}/ispaused`, {},accessToken )
  const data = getData(resp)
  return data
}  


export function* wPause(type, payload, meta) {
  const { tokenAddress, privateKey, networkId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/pause`, 
    { private_key: privateKey },
    accessToken
  )
  const data = getData(resp)
  return data
}


export function* wUnpause(type, payload, meta) {
  const { tokenAddress, privateKey, networkId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/unpause`, 
    { private_key: privateKey },
    accessToken
  )
  const data = getData(resp)
  return data
}


export function* wGetInfo(type, payload, meta) {
  const { tokenAddress, networkId, types } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/sto_info`, 
    {types}, 
    accessToken
  )
  const data = getData(resp)
  return data
}  

export function* wLinkWallet(type, payload, meta) {
  const { userId, walletAddress } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/user/${userId}/link_wallet`, 
    { wallet_address: walletAddress },
    accessToken
  )
  const data = getData(resp)
  return data
}

export function* wUnlinkWallet(type, payload, meta) {
  const { walletAddress, userId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/user/unlink_wallet`, 
    { 
      wallet_address: walletAddress,
      user_id: userId
    },
    accessToken
  )
  const data = getData(resp)
  return data
}  


export function* wGetTxList(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet(
    `/wltoken/${networkId}/token/${tokenAddress}/txlist`, 
    {}, 
    accessToken
  )
  const data = getData(resp)
  return data
}  

export function* wExportUserCsv(type, payload, meta) {
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet("/wltoken/export_user_csv", {}, accessToken)
  const data = getData(resp)
  const status = yield handleLinkClick(data['csv'], 'users')
  return status
}  


export function* wImportUserCsv(type, payload, meta) {
  const { file, import_admins } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const csvData = yield call(readCsv,file[0])
  const parsed = csvJSON(csvData)
  const resp = yield handlePost('/wltoken/import_user_csv', {data: parsed, import_admins}, accessToken)
  const data = getData(resp)
  return data
} 



export function* wBurnAll(type, payload, meta) {
  const { tokenAddress, privateKey, networkId, investorAddress} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/burn_all`, 
    { private_key: privateKey, investorAddress },
    accessToken
  )
  const data = getData(resp)
  return data
}

export function* wBurnAndIssue(type, payload, meta) {
  const { tokenAddress, privateKey, networkId, fromAddress, toAddress} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/burn_and_issue`, 
    { private_key: privateKey, fromAddress, toAddress },
    accessToken
  )
  const data = getData(resp)
  return data
}

export function* wDeploySto(type, payload, meta) {
  const { privateKey, networkId, name, symbol, divisible } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/deploy_sto`, 
    { private_key: privateKey, name, symbol, divisible },
    accessToken
  )
  const data = getData(resp)
  return data
}


export function* wImportSto(type, payload, meta) {
  const { networkId, stoAddress} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/import_sto`, 
    { token_address: stoAddress },
    accessToken
  )
  const data = getData(resp)
  return data
}

export function* wRemoveSto(type, payload, meta) {
  const { networkId, stoAddress} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/remove_sto`, 
    { token_address: stoAddress },
    accessToken
  )
  const data = getData(resp)
  return data
}

export function* wCheckUsers(type, payload, meta) {
  const { emails, addresses } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/check_users`, 
    { emails, addresses },
    accessToken
  )
  const data = getData(resp)
  return data
}

export function* wManageRoles(type, payload, meta) {
  const { 
    tokenAddress, privateKey, networkId, roleData, walletAddress
  } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const actions = Object.entries(roleData).map(([key,value])=>({
    address: walletAddress,
    role: key,
    method: value ? 'grantRole' : 'revokeRole'
  }))

  //return { tokenAddress, privateKey, networkId, actions}
  
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/manage_roles`, 
    { 
      actions: actions,
      private_key: privateKey 
    },
    accessToken
  )
  const data = getData(resp)
  return data
}

export function* wCheckUserRoles(type, payload, meta) {
  const { tokenAddress, networkId, walletAddress, roles} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/check_roles`,
    { roles: roles, investor_address: walletAddress },
    accessToken
  )
  const data = getData(resp)
  return data
}  

export function* wAllUserBalances(type, payload, meta) {
  const { tokenAddress, networkId, userId, roles} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet(
    `/wltoken/${networkId}/user_balances/${userId}`,
    {},
    accessToken
  )
  const data = getData(resp)
  return data
}  

export function* wAllUserTransfers(type, payload, meta) {
  const { tokenAddress, networkId, userId, roles} = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handleGet(
    `/wltoken/${networkId}/user_transfers/${userId}`,
    {},
    accessToken
  )
  const data = getData(resp)
  return data
}  

export function* wIssueSto(type, payload, meta) {
  const { 
    tokenAddress, privateKey, networkId, file
  } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const csvData = yield call(readCsv,file[0])
  const parsed = csvJSON(csvData)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/multi_issue`, 
    { private_key: privateKey, data: parsed},
    accessToken
  )
  const data = getData(resp)
  return data
} 

export function* wGeneratePassword(type, payload, meta) {
  const { 
    email
  } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    '/wltoken/generate_password', 
    { email: email },
    accessToken
  )
  const data = getData(resp)
  return data
} 

export function* _wIssueSto(type, payload, meta) {
  const { 
    tokenAddress, privateKey, networkId, investorAddress, tokenAmount
  } = {...payload}
  const accessToken = yield select(selectAccessToken)
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/multi_issue`, 
    { private_key: privateKey, wallet_addresses: [investorAddress], amounts: [tokenAmount]},
    accessToken
  )
  const data = getData(resp)
  return data
} 

/*
export function* wCreateFund(type, payload, meta) {
  const { tokenAddress, wallet_address, yen, token_amount, networkId } = {...payload}
  const resp = yield handlePost(`/wltoken/${networkId}/token/${tokenAddress}/create_fund`, { wallet_address, yen, token_amount })
  const data = getData(resp)
  return data
}  


export function* wCancelFund(type, payload, meta) {
  const { tokenAddress, fundIds, networkId } = {...payload}

  const resp = yield handlePost(`/wltoken/${networkId}/token/${tokenAddress}/cancel_fund`, 
    { fund_ids: fundIds}
  )
  const data = getData(resp)
  return data
}  
*/



/*
export function* wMultiIssueFundSign(type, payload, meta) {
  const { 
    tokenAddress, fundIds, privateKey, networkId 
  } = {...payload}
  const resp = yield handlePost(
    `/wltoken/${networkId}/token/${tokenAddress}/multi_issue_funds`, 
    { private_key: privateKey, fund_ids: fundIds }
  )    
  const data = getData(resp)
  return data
}  
*/
/*
export function* wGetFunds(type, payload, meta) {
  const { 
    tokenAddress, networkId
  } = {...payload}
  const resp = yield handleGet(`/wltoken/${networkId}/token/${tokenAddress}/fundlist`)
  const data = getData(resp)
  return data
} 

*/
/*
export function* _wGetFunds(type, payload, meta) {
  const { 
    tokenAddress, networkId
  } = {...payload}

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`/wltoken/${networkId}/token/${tokenAddress}/fundlist`)
    const data = getData(resp)
    yield putSuccessV2(type, data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  
*/
/*
export function* _wAddToWhitelistSign(type, payload, meta) {
  const { 
    tokenAddress, investorAddresses=[], privateKey, networkId 
  } = yield handleValidate(type, payload, addToWhitelistSchema)

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})

    const task_data = yield handlePost(
      `/wltoken/${networkId}/token/${tokenAddress}/multi_add_to_whitelist`, 
      { private_key: privateKey, addresses: investorAddresses }
    )
    const {status, taskId} = {...task_data}

    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
}
*/

/*
export function* wGetUnwhitelisted(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`/wltoken/${networkId}/token/${tokenAddress}/unwhitelisted_users`)
    const data = getData(resp)
    const users = data['users']
    yield putSuccessV2(type, users, {subPath: [tokenAddress]})
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  
*/
/*
export function* wUploadCsv(type, payload, meta) {
  const { file } = {...payload}

  try {
    yield putFetchingV2(type)
    var formData = new FormData()
    formData.append("file", file[0])
    const resp = yield handlePostFile('/wltoken/upload_csv', formData)
    const data = getData(resp)
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message)
  }
}  
*/

/*
export function* wExportCsv(type, payload, meta) {

  try {
    yield putFetchingV2(type)
    const resp = yield handleGet("/wltoken/export_csv")
    const data = getData(resp)
    const status = yield handleLinkClick(data['csv'])
    yield putSuccessV2(type, status)
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message)
  }
}  
*/


/*
   const mock_config = {
      stoAddresses: ['0x7E27Bdd661533780a57838B844722d51Fe93f6F2'], //SPRT, PRJA
      stoMap: {
        '0x7E27Bdd661533780a57838B844722d51Fe93f6F2': { //SPRT
          title: 'Project WLT',
          symbol: 'WLT',
          coord: [35.66636, 139.75737]
        }
      }
    } 
    const _mock_config = {
      stoAddresses: ['0xDc5559860beE73B261904737E4fA2D10a46e9878', '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E'], //SPRT, PRJA
      stoMap: {
        '0xDc5559860beE73B261904737E4fA2D10a46e9878': { //SPRT
          balancerAddress: '0x88ACc4dB1B930354caa369f1bC8E35736f1BB2AC',
          title: 'Project SPRT',
          symbol: 'SPRT',
          coord: [35.66636, 139.75737],
          gtmAddress: '0x681Bb5f59577e385379c575994Cf2Ea9747f1179'
        },
        '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E': { //PRJA
          balancerAddress: '0x4bbd0c836Ba92BDcb58B5af3242C3Dc77c8FD8b0',
          title: 'Project A',
          symbol: 'PRJA',
          coord: [35.65889, 139.74541],
          gtmAddress: '0x7cc02b8A9f174aFA86FB4dB92D111a367189F3dC'
        }
      },
      balancerMap: {
        '0x88ACc4dB1B930354caa369f1bC8E35736f1BB2AC': {
          STO: '0xDc5559860beE73B261904737E4fA2D10a46e9878',  //SPRT
          stablecoin: '0x8186e1d916774ab8004cB84d7aDC945953ee966D'  //FDAI 0x8186e1d916774ab8004cB84d7aDC945953ee966D
        },
        '0x4bbd0c836Ba92BDcb58B5af3242C3Dc77c8FD8b0': {
          STO: '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E',  //PRJA
          stablecoin: '0x8186e1d916774ab8004cB84d7aDC945953ee966D'  //FDAI 0x8186e1d916774ab8004cB84d7aDC945953ee966D
        }
      }
    }
    */