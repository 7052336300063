import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm } from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';
import { Decimal } from 'decimal.js'

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createLoginSchema } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"

export const useLoginV2 = (auth_code)=> {
  const dispatch = useDispatch()
  const action = 'AUTH_LOGIN'
  const [data, error, status] = useThunkDataV3([action])

  const submit = (auth_code) => {
    dispatch({type: action, payload: { 
      auth_code
    }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, submit, unmount]
}

export const useLogin = (networkId, tokenAddress, walletAddress)=> {
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3(['AUTH_LOGIN'])

  const submit = (formData) => {
    const {email, password} = {...formData}
    dispatch({type: 'AUTH_LOGIN', payload: { 
      email, password
    }})
  }
  const unmount = createUnmountDispatch(dispatch, 'AUTH_LOGIN')
  return [data, error, status, submit, unmount]
}

export const useLoginForm = () => {
  const { register, handleSubmit, formState:{ errors } } = useForm({
    resolver: joiResolver(createLoginSchema),
  });
  return [register('email'), register('password'), handleSubmit, errors]
}