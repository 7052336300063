import { useState, useEffect, useCallback} from 'react';

export const useMemoizedOpen = (onClose) =>{
  const [ open, setOpen ] = useState(false)
  const memoizedClose = useCallback(() => {
    setOpen(false)
    if(onClose) onClose()
  },[])

  const memoizedOpen = useCallback(
    () => setOpen(true),
  [])
  const memoizedToggle = useCallback(
    () => setOpen(!open),
  [])

  return([open, memoizedOpen, memoizedClose, memoizedToggle])
}

export const useCloseOnZero = (count) =>{
  const [ open, setOpen ] = useState(false)
  const memoizedClose = useCallback(
    () => setOpen(false),
  [])
  const handleOpen = () => {
    setOpen(true)
  }
  const handleToggle = () => {
    setOpen(!open)
  }
  useEffect(()=>{
    if(count===0) setOpen(false)
  },[count])
  return([open, handleOpen, memoizedClose, handleToggle])
}
