import Joi from "joi";

import { 
  wOfferSell, wOfferList, wTakeOffer, wDeleteOffer
} from './workers'

import { 
  tokenAddress, emailAddress, privateKey, ethAddress,
  networkId, userId, ethAddresses, fundIds, types
} from "utilities/joi/sto"

import { 
   statusWrapper, validateWrapper
} from 'saga/ETH/wltoken/helpers'

export const wwOfferSell = validateWrapper(
  statusWrapper(wOfferSell), 
  Joi.object({
    networkId,
    privateKey: privateKey, 
    stoAddress: ethAddress(),
    currency: ethAddress(),
    stoAmount: Joi.number().unsafe().required(),
    price: Joi.number().unsafe().required()
  })
)

export const wwOfferList = validateWrapper(
  statusWrapper(wOfferList), 
  Joi.object({
    networkId,
    stoAddress: ethAddress(),
  })
)

export const wwTakeOffer = validateWrapper(
  statusWrapper(wTakeOffer), 
  Joi.object({
    networkId,
    privateKey: privateKey, 
    offerId: Joi.number().required(),
    makerAmount: Joi.number().unsafe().required(),
    price: Joi.number().unsafe().required()
  })
)

export const wwDeleteOffer = validateWrapper(
  statusWrapper(wDeleteOffer), 
  Joi.object({
    networkId,
    privateKey: privateKey, 
    offerId: Joi.number().required(),
  })
)