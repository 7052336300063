import Joi from "joi";

import { 
  wAddToWhitelistSign, wGetEmail, wSetEmail,
  wGetUsers, wGetUsersV2, wGetInvestors, wRemoveFromWhitelistSign, wLoadConfig,
  wGetPause, wPause, wUnpause, wGetInfo, wLinkWallet, wUnlinkWallet, wGetTxList,
  wGetUnwhitelisted, wExportUserCsv, wImportUserCsv,
  //wCreateUser, 
  wBurnAll,wBurnAndIssue,
  wDeploySto, wImportSto, wRemoveSto, wCheckUsers, wUsersInfo, wLoadUserRoles,
  wManageRoles, wCheckUserRoles, wGetWallets, wGetInvestorWallets,
  wAllUserBalances, wAllUserTransfers, wIssueSto, wGeneratePassword
  //wMultiIssueFundSign,wGetFunds,wCancelFund,wCreateFund,
} from './workers'

import { 
  tokenAddress, emailAddress, privateKey, ethAddress,
  networkId, userId, ethAddresses, fundIds, types
} from "utilities/joi/sto"

import { 
   statusWrapper, validateWrapper
} from './helpers'

export const wwLoadUserRoles = validateWrapper(
  statusWrapper(wLoadUserRoles), 
  Joi.object({
    networkId,
    tokenAddress,
  })
)

export const wwLoadConfig = validateWrapper(
  statusWrapper(wLoadConfig), 
  Joi.object({
    networkId,
  })
)

export const wwAddToWhitelistSign = validateWrapper(
  statusWrapper(wAddToWhitelistSign,{pending:true}), 
  Joi.object({
    tokenAddress,
    networkId,
    investorAddresses: ethAddresses,
    privateKey
  })
)

export const wwRemoveFromWhitelistSign = validateWrapper(
  statusWrapper(wRemoveFromWhitelistSign,{pending:true}), 
   Joi.object({
    tokenAddress,
    networkId,
    addresses: ethAddresses,
    privateKey
  })
)
/*
export const wwMultiIssueFundSign = validateWrapper(
  statusWrapper(wMultiIssueFundSign,{pending:true}), 
  Joi.object({
    tokenAddress,
    networkId,
    fundIds,
    privateKey
  })
)
*/
/*
export const wwGetFunds = validateWrapper(
  statusWrapper(wGetFunds),  
  Joi.object({
    tokenAddress,
    networkId,
  })
)
*/
export const wwGetEmail = validateWrapper(
  statusWrapper(wGetEmail), 
  Joi.object({type: Joi.string().required()})
)

export const wwSetEmail = validateWrapper(
  statusWrapper(wSetEmail), 
  Joi.object({
    type: Joi.string().required(),
    subject: Joi.string().required(),
    message:  Joi.string().required()
  })
)
/*
export const wwCreateFund = validateWrapper(
  statusWrapper(wCreateFund), 
  Joi.object({
    tokenAddress,
    networkId,
    wallet_address: ethAddress,
    yen: Joi.number().required(),
    token_amount: Joi.number().unsafe().required(),
  })
)

export const wwCancelFund = validateWrapper(
  statusWrapper(wCancelFund),
  Joi.object({
    tokenAddress,
    networkId,
    fundIds: fundIds,
  })
)
*/
/*
export const wwCreateUser = validateWrapper(
  statusWrapper(wCreateUser), 
  Joi.object({
    email: emailAddress,
    addresses: ethAddresses
  })
)
*/
export const wwGetUsers = validateWrapper(
  statusWrapper(wGetUsers), 
  Joi.object({
    adminsOnly: Joi.any()
  })
)

export const wwGetUsersV2 = validateWrapper(
  statusWrapper(wGetUsersV2), 
  Joi.object({
    tokenAddress,
    networkId,
    types: Joi.array().items(Joi.string().required()).required()
  })
)
export const wwGetWallets = validateWrapper(
  statusWrapper(wGetWallets), 
  Joi.object({})
)
export const wwGetInvestorWallets = validateWrapper(
  statusWrapper(wGetInvestorWallets), 
  Joi.object({
    tokenAddress,
    networkId,
    types: Joi.array().items(Joi.string().required()).required()
  })
)
export const wwUsersInfo = validateWrapper(
  statusWrapper(wUsersInfo), 
  Joi.object({
    userId: Joi.number().required(),
    types: Joi.array().items(Joi.string().required()).required()
  })
)

export const wwGetInvestors = validateWrapper(
  statusWrapper(wGetInvestors), 
  Joi.object({
    tokenAddress,
    networkId,
  })
)


export const wwGetPause = validateWrapper(
  statusWrapper(wGetPause), 
  Joi.object({
    tokenAddress,
    networkId,
  })
)

export const wwPause = validateWrapper(
  statusWrapper(wPause, {pending: true}), 
  Joi.object({
    tokenAddress,
    networkId,
    privateKey
  })
)

export const wwUnpause = validateWrapper(
  statusWrapper(wUnpause, {pending: true}), 
  Joi.object({
    tokenAddress,
    networkId,
    privateKey
  })
)

export const wwGetInfo = validateWrapper(
  statusWrapper(wGetInfo), 
  Joi.object({
    tokenAddress,
    networkId,
    types
  })
)

export const wwLinkWallet = validateWrapper(
  statusWrapper(wLinkWallet), 
  Joi.object({
    userId,
    walletAddress: ethAddress(),
  })
)

export const wwUnlinkWallet = validateWrapper(
  statusWrapper(wUnlinkWallet), 
  Joi.object({
    userId,
    walletAddress: ethAddress,
  })
)

export const wwGetTxList = validateWrapper(
  statusWrapper(wGetTxList), 
  Joi.object({
    tokenAddress,
    networkId,
  })
)

export const wwExportUserCsv = validateWrapper(
  statusWrapper(wExportUserCsv), 
  Joi.object({
  })
)

export const wwImportUserCsv = validateWrapper(
  statusWrapper(wImportUserCsv), 
  //Joi.object({
     //file: 
  //})
)


export const wwBurnAll = validateWrapper(
  statusWrapper(wBurnAll, {pending: true}), 
  Joi.object({
    tokenAddress, 
    privateKey, 
    networkId, 
    investorAddress: ethAddress
  })
)

export const wwBurnAndIssue = validateWrapper(
  statusWrapper(wBurnAndIssue, {pending: true}), 
  Joi.object({
    tokenAddress, 
    privateKey, 
    networkId, 
    fromAddress: ethAddress,
    toAddress: ethAddress,
  })
) 

export const wwDeploySto = validateWrapper(
  statusWrapper(wDeploySto, {pending: true}), 
  Joi.object({
    privateKey, 
    networkId, 
    name: Joi.string().required(), 
    symbol: Joi.string().required(), 
    divisible: Joi.boolean().required()
  })
)   

export const wwImportSto = validateWrapper(
  statusWrapper(wImportSto), 
  Joi.object({
    stoAddress: tokenAddress, 
    networkId, 
  })
)   

export const wwRemoveSto = validateWrapper(
  statusWrapper(wRemoveSto), 
  Joi.object({
    stoAddress: tokenAddress, 
    networkId, 
  })
)   

export const wwManageRoles = validateWrapper(
  statusWrapper(wManageRoles, {pending: true}), 
  Joi.object({
    tokenAddress, 
    privateKey,
    networkId, 
    walletAddress: tokenAddress,
    roleData: Joi.object().required()
  })
)   

export const wwCheckUserRoles = validateWrapper(
  statusWrapper(wCheckUserRoles), 
  Joi.object({
    tokenAddress, 
    networkId, 
    walletAddress: tokenAddress,
    roles: Joi.array().required()
  })
)   


export const wwAllUserBalances = validateWrapper(
  statusWrapper(wAllUserBalances), 
  Joi.object({
    networkId, 
    userId: Joi.number().required()
  })
)   

export const wwAllUserTransfers = validateWrapper(
  statusWrapper(wAllUserTransfers), 
  Joi.object({
    networkId, 
    userId: Joi.number().required()
  })
)
export const wwIssueSto = validateWrapper(
  statusWrapper(wIssueSto,{pending:true}), 
  Joi.object({
    networkId,
    privateKey, 
    tokenAddress,
    //investorAddress: tokenAddress,
    //tokenAmount: Joi.number().unsafe().required(),
    file: Joi.any()
  })
)   

export const wwGeneratePassword = validateWrapper(
  statusWrapper(wGeneratePassword)
)   
