import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import Joi from "joi";
import { joiResolver } from '@hookform/resolvers/joi';
import reduce from "lodash/reduce"
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { ethAddress, privateKey } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"


export const useGetInvestors= (networkId, tokenAddress) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_INVESTORS'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: { tokenAddress, networkId }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]

}

export const useBurnAll = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_BURN_ALL'
  const [data, error, status] = useThunkDataV3([action])

  const add = formdata => {
    const { private_key, walletAddress } = {...formdata}
    //const investorAddress = map[0]
    //const investorAddresses =  Object.entries(map).map((key,value)=>value['walletAddress'])
    dispatch({
      type: action, 
      payload: { 
        tokenAddress, 
        investorAddress: walletAddress, 
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [ data, error, status, add, unmount]
}


export const useBurnAllForm = () => {
  const { register, handleSubmit, watch, formState: {errors, touchedFields, isValid}, resetField} = useForm({
    resolver: joiResolver(Joi.object({
      walletAddress: ethAddress(),
      private_key: privateKey
    }))
  });
  //const createMapReg = idx => register(`map`)
  const walletAddress = watch("walletAddress", null)


  //const issueLength = watchIssue ? Array.isArray(watchIssue) ? watchIssue.length : 1 :  0
  return [ 
    [register('private_key'), register(`walletAddress`)], 
    handleSubmit,
    {
      errors: {
        private_key: get(errors,['private_key','type'], null),
        walletAddress: get(errors,['walletAddress','type'], null),
      },
      isValid,
      touchedFields,
      fields : {
        walletAddress
      }
    }, 
    ()=>{
      resetField('private_key')
      resetField('walletAddress')
    }
  ]
}