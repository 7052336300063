import { all, setContext } from 'redux-saga/effects'

//import NEMRootSaga from './NEM/sagas'
//import MockRootSaga from './Mock/sagas'
//import GXCSagas from './GXC/sagas'
import { AuthRootSaga } from './Auth'
import ETHRootSaga from './ETH/sagas'
//import AWSRootSaga from './AWS/sagas'
//import { sagas, reducers } from 'container_machabit'
//import * as MockSagas from './Mock/sagas'

import AuthReducers from './Auth/reducers'
//import GXCReducers from './GXC/reducers'
//import NEMReducers from './NEM/reducers'
//import AWSReducers from './AWS/reducers'
import ETHReducers from './ETH/reducers'
import SystemReducers from './System/reducers'
import ThunkReducers from './Thunk/reducers'
import { polyReducers } from './ETH/sto/reducers'
import { wltokenReducers } from './ETH/wltoken/reducers'
import { metaMaskReducers } from './ETH/metamask/reducers'
//import GXCInstance from 'instances/GXC'
//import NEMInstance from 'instances/NEM'
//import NEMWalletInstance from 'instances/NEMWallet'
//import MockInstance from 'instances/Mock'

import { combineReducers } from 'redux';

import { createReducer, combineSagas } from 'utilities/misc';

import config from 'setup/appConfig'

//import { reducer as thunkReducer } from 'redux-saga-thunk'

//const AuthReducers = createReducer({}, reducers['auth'])
//const AuthReducers = createReducer({}, _AuthReducers)
//const GXCReducers = createReducer({}, reducers['GXC'])
//const _NEMReducers = createReducer({}, NEMReducers)
//const _AWSReducers = createReducer({}, AWSReducers)



export const rootReducer = combineReducers({
  thunk: ThunkReducers,
  auth: AuthReducers,
  //GXC: GXCReducers,
  //NEM: NEMReducers,
  //AWS: _AWSReducers,
  wltoken: wltokenReducers,
  polymath: polyReducers,
  ETH: ETHReducers,
  metamask: metaMaskReducers,
  system: SystemReducers
});

//const GXCRootSaga = sagas['GXC']
//const AuthRootSaga = sagas['auth']

export function* rootSaga() {

  yield all([
    //MockRootSaga(),
    //NEMRootSaga(config.backend_url),
    //GXCRootSaga(GXCInstance),
    AuthRootSaga(config.backend_url),
    ETHRootSaga(config.backend_url),
    //AWSRootSaga()
  ])
}

