import React, { useState, useEffect, Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import UploadIcon from '@mui/icons-material/Upload';
import SearchIcon from '@mui/icons-material/Search';

import { useTable, useFilters } from 'react-table'

import { useTranslation } from 'react-i18next';


import { 
  HandleError, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending 
} from 'components/molecules/HandleStatus'
//import { Jdenticon } from 'components/molecules/Jdenticon'
import { NetworkSelectV4 } from "components/organisms/ETH/metamask/network"

import { NullColumnFilter, inputValueFilter } from 'components/molecules/TableBase'
import { Toolbar } from 'components/molecules/Toolbar'

import { RestrictUserRole } from 'components/organisms/Auth/Restrict'

import { useFetchOnMountV3 } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
//import { withRoles } from 'components/useHooks/useRoles'

import { 
  useStos, useStoDetails, useMultiStoDetails
} from './use'

import { createTokenHref } from 'utilities/ETH'




export const StoCardV2 = (props) => {
  const { networkId, tokenAddress, title, symbol, handleRoute=()=>console.warn('handleRoute is null')} = {...props}
  //const [title, symbol] = useStoDetails(networkId, tokenAddress)

  return <Card
    sx={{ height: '100%' }}
    {...props}
  >
    <CardContent>
      <CardActionArea onClick={()=>handleRoute(tokenAddress)}>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="overline"
            >
              {symbol}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {title}
            </Typography>
          </Grid>
        </Grid>
      </CardActionArea>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {/*<ArrowDownwardIcon color="error" />*/}
        <Typography
          color="textSecondary"
          variant="caption"
        >
          <Link 
            href={createTokenHref(networkId,tokenAddress)}
            target='blank'
          >
            {tokenAddress}
          </Link>
        </Typography>
      </Box>
    </CardContent>
  </Card>
}


const TableCards = props => {
  const {
    data, columns, 
    routeToOptions=()=>console.warn('requires routeToOptions prop'), 
    networkId,
    handleNetwork
  } = {...props}

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: NullColumnFilter,
    }),
    []
  )

  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn
  }, useFilters)

  return <Container>

      <Card >
        <CardContent>
          {/*<NetworkSelectV4 handleChange={handleNetwork}/>*/}
          <Box sx={{ py: 1}}/>
          <Stack spacing={1}>
            {headerGroups.map(headerGroup => headerGroup.headers.map((column, idx)=> <Fragment key={idx}>
              {column.canFilter ? column.render('Filter') : null}
            </Fragment>))}
          </Stack>
        </CardContent>
      </Card>
  

      <Box sx={{ py: 2}}/>


      <Grid
        container
        spacing={3}
      >
      {rows.map((row, i) => {
        prepareRow(row)
        //if (prepareRow(row)) return <div>preparing row...</div>
        return <Fragment key={i}> 
          <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={4}
          >
            <StoCardV2 
              key={row.original.address} 
              tokenAddress={row.original.address}
              title={row.original.NAME} 
              symbol={row.original.SYMBOL}  
              networkId={networkId} 
              handleRoute={routeToOptions}
            />
          </Grid>
        </Fragment>
      })}
      </Grid>

  </Container>
}

const useColumns = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: 'TITLE',
        accessor: 'NAME',
        Filter: inputValueFilter({placeholder:t('SEARCH_TITLE'), variant: 'outlined'})
      },
      {
        Header: 'SYMBOL',
        accessor: 'SYMBOL',
        Filter: inputValueFilter({placeholder:t('SEARCH_SYMBOL'), variant: 'outlined'})
      },
      {
        Header: 'ADDRESS',
        accessor: 'address',
        Filter: inputValueFilter({placeholder:t('SEARCH_ADDRESS'), variant: 'outlined'})
      },
    ]
  ,[i18n.language])
  return columns
}

export const STOListV2 = props => {
  const  { 
    routeToDeploy=()=>console.warn('requires routeToDeploy prop'),
    routeToOptions=()=>console.warn('requires routeToOptions prop'),
    routeToImport=()=>null, 
    routeToRemove=()=>null, 
    handleNetwork,
    networkId 
  } = {...props}

  const [STOaddresses, STOmap] = useStos(networkId)
  //const data = useMultiStoDetails(networkId, STOaddresses)
  const data = STOaddresses.map(address=>({address:address, ...STOmap[address]}))
  const columns = useColumns()

  const routes = [
    {
      routeFunc: routeToDeploy,
      text: "STO_DEPLOY",
      icon: UploadIcon
    },
    {
      routeFunc: routeToImport,
      text: "IMPORT",
      icon: UploadIcon
    },
    {
      routeFunc: routeToRemove,
      text: "REMOVE",
      icon: UploadIcon
    },
  ]

  return <Container>
    <RestrictUserRole roles={['STO_ROLE']}>
      <Toolbar routes={routes} />
    </RestrictUserRole>
    <Box p={1}/>
    <TableCards 
      data={data} 
      columns={columns} 
      networkId ={networkId} 
      routeToOptions={routeToOptions}
      handleNetwork={handleNetwork}
    />
  </Container>
}
 
STOListV2.propTypes = {
  routeToDeploy: PropTypes.func.isRequired,
  routeToOptions: PropTypes.func.isRequired,
  routeToImport: PropTypes.func.isRequired,
  routeToRemove: PropTypes.func.isRequired,
  networkId: PropTypes.number.isRequired,
};

