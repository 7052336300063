import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';

import reduce from "lodash/reduce"
import get from "lodash/get"
import omitBy from "lodash/omitBy"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

//import { investorTableSchema} from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"



export const useGetUserRoles = (networkId, tokenAddress, walletAddress, roles) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_CHECK_USER_ROLES'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: { tokenAddress, networkId, walletAddress, roles }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]
}


export const useManage = (networkId, tokenAddress, walletAddress, dirtyFields) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_MANAGE_ROLES'
  const [data, error, status] = useThunkDataV3([action])

  const submit = formdata => {
    const { roles, private_key } = {...formdata}
    const filterNotDirty = omitBy(roles, (value, key)=>!get(dirtyFields,['roles',key], false))

    dispatch({
      type: action, 
      payload: { 
        networkId, 
        tokenAddress, 
        walletAddress,
        privateKey: private_key,
        roleData: filterNotDirty
      }
    })
    
    //if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [ data, error, status, submit, unmount]
}


export const useManageForm = (roleData) => {
  const { register, handleSubmit, watch, formState: {errors, dirtyFields}, resetField, control} = useForm({
    defaultValues: {roles: roleData}
    //resolver: joiResolver(fundTableSchema)
  });
  //const removeAddress = watch('stoAddress')
  return [ 
    [],
    handleSubmit, 
    {dirtyFields}, 
    ()=>resetField(),
    control
  ]
}