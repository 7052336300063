import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

import reduce from "lodash/reduce"
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { privateKey } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"



export const useDeploySto = (networkId, onSend) => {
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3(['ETH_WLTOKEN_DEPLOY_STO'])

  const submit = formdata => {
    const { private_key, name, symbol, divisible} = {...formdata}
    dispatch({
      type: 'ETH_WLTOKEN_DEPLOY_STO', 
      payload: { 
        networkId, 
        privateKey: private_key,
        name,
        symbol,
        divisible: true
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, 'ETH_WLTOKEN_DEPLOY_STO')
  return [ data, error, status, submit, unmount]
}

export const useDeployStoForm = () => {
  const { register, handleSubmit, watch, formState: {errors, touchedFields}, resetField} = useForm({
    resolver: joiResolver(Joi.object({
      private_key: privateKey,
      name: Joi.string().regex(/^\w+(?:\s+\w+)*$/).max(20).required().messages({
        "string.empty": "REQUIRED",
        "string.max": "INVALID_TOKEN_NAME",
        "string.pattern.base": "INVALID_TOKEN_NAME"
      }),
      symbol: Joi.string().alphanum().max(10).required().messages({  //.regex(/^\S+$/) not sure what this was for
        "string.empty": "REQUIRED",
        "string.max": "INVALID_TOKEN_SYMBOL",
        "string.alphanum": "INVALID_TOKEN_SYMBOL"
      }),
      //divisible: Joi.boolean().required()
      //divisible: true
    }))
  });
  console.log(errors)
  return [ 
    [
      register('private_key'), register('name'), 
      register('symbol'),
      //register('divisible')
    ],
    handleSubmit, 
    {
      errors:{
        private_key: get(errors,['private_key','message'], null), 
        name: get(errors,['name','message'], null),
        symbol: get(errors,['symbol','message'], null),
        divisible: get(errors,['divisible','message'], null),
      }, 
      touchedFields
    }, 
    ()=>resetField('private_key')
  ]
}