import React, { useState, useEffect, Fragment, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import get from "lodash/get"
import reduce from "lodash/reduce"

import Container from '@mui/material/Container';

import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';


import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import { useGeneratePassword } from "./use"


import { HandleErrorV2, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"
import { useErrorV2 } from 'components/useHooks/useStatus'


export const GeneratePassword = props => {
  const { email, onSuccess=()=>null} = {...props}
  const { t, i18n } = useTranslation();
  
  const [ data, error, status, submit, unmount ] = useGeneratePassword(email)
  useRefreshOnSuccess(status, onSuccess)
  useUnmount([unmount])
  useErrorV2(status,error)
  return <Card>
    <HandleSendingAndPending sending={[status]} />
    {/*<HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>*/}
    <CardHeader
      subheader={t('GENERATE_PASSWORD_DESCRIPTION')}
      title={t('GENERATE_PASSWORD')}
    />
    <Divider />
    <CardContent>
      <pre>{get(data,['password'])}</pre>
      <Box p={2}/>
      <Divider />
      <CardActions sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        //p: 2
      }}>
        <Button 
          variant='contained' 
          onClick={submit}
        >
          {t('SEND')}
        </Button>
      </CardActions>
    </CardContent>
  </Card>  
}