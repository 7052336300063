import Joi from "joi";

import { cancel } from 'redux-saga/effects'

import { 
  putSuccessV2, putFetchingV2, putFailedV2, putCleanV2, putPendingV2,
} from 'saga/puts'

import { waitForTask } from 'saga/ETH/helpersV2'

import { 
  wLoginUser, wLogoutUser, wGetServerRoles, wManageRoles
} from './workers'

//import { } from "utilities/joi/sto"

import { 
   statusWrapper, validateWrapper
} from 'saga/ETH/wltoken/helpers'


export const wwLoginUser = validateWrapper(
  statusWrapper(wLoginUser), 
  Joi.object({
    auth_code: Joi.string().required()
  })
)
export const wwLogoutUser = validateWrapper(
  statusWrapper(wLogoutUser), 
  Joi.object({
    //auth_code: Joi.string().required()
  })
)
export const wwGetServerRoles = validateWrapper(
  statusWrapper(wGetServerRoles), 
  Joi.object({
    userId: Joi.number().required()
  })
)
export const wwManageRoles = validateWrapper(
  statusWrapper(wManageRoles), 
  Joi.object({
    userId: Joi.number().required(),
    roleData: Joi.object().required()
  })
)
