import React from 'react';
import { useDispatch } from 'react-redux'

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"

export const useGeneratePassword = (email)=> {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GENERATE_PASSWORD'
  const [data, error, status] = useThunkDataV3([action])

  const submit = () => {
    dispatch({type: action, payload: { 
      email
    }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, submit, unmount]
}
