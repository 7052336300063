import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Joi from "joi";
import Decimal from 'decimal.js';
import { useTable, useFilters, usePagination } from 'react-table'

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';



import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';

import { useTranslation } from 'react-i18next';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { 
  useGetInvestors, useBurnAndIssueForm, useBurnAndIssue,
} from "./use"


import { Jdenticon } from 'components/molecules/Jdenticon'
import { ValidateData } from 'components/molecules/Validate'
import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
import { PKButton } from 'components/molecules/PKButton'
import { HandleErrorV2, HandleErrorV3,HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"


const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
    <Typography>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}



const useInvestorColumn = (fromAddress, toAddress, setFrom, setTo) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
     {
        Header: t('BURN'),
        Cell: item => <ToggleButton
            //value={address}
            disabled={ toAddress===item.row.original.address }
            selected={fromAddress===item.row.original.address}
            onChange={(e) => {
              setFrom(item.row.original.address);
            }}
          >
            <CheckIcon />
        </ToggleButton>
      },
      {
        Header: t('ISSUE'),
        Cell: item => <ToggleButton
          //value={address}
          disabled={!item.row.original.isWhitelisted || fromAddress===item.row.original.address}
          selected={toAddress===item.row.original.address}
          onChange={(e) => {
            setTo(item.row.original.address);
          }}
        >
          <CheckIcon />
        </ToggleButton>
      },
      {
        Header: t('INVESTOR_ID'),
        accessor: 'investor_id',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('WALLET_ADDRESS'),
        accessor: 'address',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('BALANCE'),
        accessor: 'balance',
        Cell: ({value})=>{ 
          const decimals = 18  
          const amount = Decimal(value).mul(Decimal(10).pow(-decimals)).toString()
          return amount
        }
      },
      {
        Header:  t('IS_WHITELISTED'),
        accessor: 'isWhitelisted',
        Cell: item => item.value ? 'True':'False'
      },
            
    ]
  ,[i18n.language,fromAddress, toAddress])
  return columns  
}

const dataSchema = {
  'investors': Joi.array().items(
    Joi.object({
      investor_id: Joi.number().required(),
      email: Joi.string().required(),
      address: Joi.string().required(),
      balance: Joi.number().unsafe().required(),
      isWhitelisted: Joi.boolean().required(),
    }).required()
  ).required(),
}



export const BurnAndIssue = props => {
  const { tokenAddress, handleGoToFund, networkId, handleGoToNewAddress } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(useGetInvestors, networkId, tokenAddress)

  const [regs, handleSumbit,{
      errors: { private_key: PKError }, 
      touchedFields:{ private_key: PKTouched },
      fields: { toAddress, fromAddress }
    }, resetForm ] = useBurnAndIssueForm()

  const [PKReg, setFrom, setTo] = regs
  
  const columns = useInvestorColumn(fromAddress,toAddress,setFrom, setTo)

  //const [ open, handleOpen, handleClose ]  = useMemoizedOpen(resetPK)

  const [ data2, error2, status2, onSubmit, unmount2 ] = useBurnAndIssue(
    networkId,  
    tokenAddress, 
    //onSend=>{
      //handleClose()
    //}
  )

  useUnmount([ unmount, unmount2 ])
  useRefreshOnSuccess(status2, ()=>{
    resetForm()
    fetch()
  })
  //useFetchOnChange(networkId, fetch)
  //return <pre>{JSON.stringify({data, error, status},0,2)}</pre>
  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING')}</Fragment>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return <Fragment>
    <PKButton 
      PKReg={PKReg} 
      handleSend={handleSumbit(onSubmit)}
      touched={PKTouched}
      errText={t(PKError)}
      disabled={!fromAddress || !toAddress}
      title="BURN_AND_ISSUE"
      subheader="BURN_AND_ISSUE_DESCRIPTION"
    >
      <Info text={t('BURN_WALLET')} subtext={fromAddress ? fromAddress : 'Please select'}/>
      <Info text={t('ISSUE_WALLET')} subtext={toAddress ? toAddress : 'Please select'}/>
    </PKButton>
    <Box p={1}/>
    {/*<Toolbar handleGoToNewAddress={()=>handleGoToNewAddress(tokenAddress)} handleOpen={handleOpen}/>*/}
    {/*<RadioGroup name="radio-buttons-group">*/}
      <PaginationTable columns={columns} data={data['investors']} />
    {/*</RadioGroup>*/}
    <HandleSendingAndPending sending={[status2]} pending={[status2]}/>
    <HandleErrorV2 status={status2} type={selectErrorSubtype(error)}/>
  </Fragment>

}

