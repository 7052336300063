import React, { useState, useContext, useEffect, useRef, Fragment } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux'

import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"


export const useLoadUserRoles = (tokenAddress, networkId, accessToken) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_LOAD_USER_ROLES'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({ 
      type: action, 
      payload: { tokenAddress, networkId}
    })
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]
}