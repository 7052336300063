import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"


export const useGetUsers = (adminsOnly=false) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_USERS'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: { adminsOnly }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]
}

export const useExportUserCsv = ()=> {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_EXPORT_USER_CSV'
  const [data, error, status] = useThunkDataV3([action])
  const submit = () => {
    dispatch({type: action, payload: { 
      
    }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, submit, unmount]
}
