import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

import { 
  putSuccessV2, putFetchingV2, putFailedV2, putCleanV2, putPendingV2,
} from 'saga/puts'

import { W3, ETHEREUM } from "utilities/constants/ETH" 
import Web3 from "web3";



export function* wLoadMetaMask(type, payload, meta) {
  const w3 = yield getContext(W3)
  const ethereum = yield getContext(ETHEREUM)
  //ETH_DETECT_METAMASK
  yield putFetchingV2(type)
  if(ethereum==undefined || !ethereum.isMetaMask){
    yield put({type: 'R_ETH_TX_HAS_METAMASK_FALSE'})
    return false
  }
  w3.setProvider(ethereum)
  //yield delay(DELAY)
  yield put({type: 'R_ETH_TX_HAS_METAMASK_TRUE'})
  yield putSuccessV2(type)
  return true
}

export function* wLoadProvider(type, payload, meta) {
  const w3 = yield getContext(W3)

  const infura_proj_id = 'e37bf8530bbe4a0f9de481d912123d1e'
  const ropsten_url ="https://ropsten.infura.io/v3/"// "wss://ropsten.infura.io/ws/v3/"
  const kovan_url ="https://kovan.infura.io/v3/"//"wss://kovan.infura.io/ws/v3/"
  const ganache_url ="http://127.0.0.1:7545"
  
  const etherscan_api_key = 'V2G7APATWHHRXGKDEX6KDVE6AVA692SBAG'
  const infura_full_url = `${kovan_url}${infura_proj_id}`

  yield putFetchingV2(type)

  w3.setProvider(new Web3.providers.HttpProvider(ganache_url))

  yield putSuccessV2(type)
  return true
}

function* getSelectedAccount() {
  const ethereum  = yield getContext(ETHEREUM)
  const accounts = yield ethereum.request({ method: 'eth_accounts' })
  const selected_account = accounts[0]
  return selected_account
}

export function* wLoadAccount(type, payload, meta) {
  const ethereum  = yield getContext(ETHEREUM)
  yield putFetchingV2(type)
  //yield delay(DELAY)
  const account = yield getSelectedAccount()
  yield put({type: 'R_ETH_UPDATE_ACCOUNT', payload: {account}})
  yield putSuccessV2(type, account)

  const changeChannel = eventChannel(emitter => {
    ethereum.on('accountsChanged', (accounts) => emitter({ account: accounts[0] }));
    return () => {            
      // Perform any cleanup you need here                 
    };  
  })

  try {  
    while (true) {        
      const payload = yield take(changeChannel);
      yield putFetchingV2(type)
      //yield delay(DELAY)
      let account = yield getSelectedAccount()
      if(!account) account = 'LOCKED'
      console.warn(account)
      yield put({type: 'R_ETH_UPDATE_ACCOUNT', payload: {account}})
      yield putSuccessV2(type,account)
    }
  } finally {
    if (yield cancelled()) {
      console.warn('account channel closed')
      changeChannel.close()
    }    
  }
  return true
}

/*
export function* wLoadAccount(type, payload, meta) {
  const ethereum  = yield getContext(ETHEREUM)
  yield putFetchingV2(type)
  //yield delay(DELAY)
  const account = yield getSelectedAccount()
  yield put({type: 'R_ETH_UPDATE_ACCOUNT', payload: {account}})
  yield putSuccessV2(type)
  return true
}
*/
export function* wWatchAccount(type, payload, meta) {
  const ethereum  = yield getContext(ETHEREUM)
  const channel = eventChannel(emitter => {
    ethereum.on('accountsChanged', (accounts) => emitter({ account: accounts[0] }));
    return () => {            
      // Perform any cleanup you need here                 
    };  
  })
  try {  
    while (true) {        
      const payload = yield take(channel);
      yield putFetchingV2('ETH_LOAD_ACCOUNT')
      //yield putFetchingV2(type)

      const account = yield getSelectedAccount()
      yield put({type: 'R_ETH_UPDATE_ACCOUNT', payload: {account}})
      yield putSuccessV2('ETH_LOAD_ACCOUNT')
      //yield putSuccessV2(type)
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }    
  }
  return true
}

export function* wConnectAccount(type, payload, meta) {
  const ethereum  = yield getContext('ethereum')
  //yield putFetchingV2(type)
  yield putFetchingV2('ETH_LOAD_ACCOUNT')
  try {
    const res = yield ethereum.request({ method: 'eth_requestAccounts' })
    const account = yield getSelectedAccount()
    yield put({type: 'R_ETH_UPDATE_ACCOUNT', payload: {account}})
    yield putSuccessV2('ETH_LOAD_ACCOUNT')
    //yield putSuccessV2(type)
  }
  catch(err){
    const {message} = {...err}
    //yield putFailedV2(type,message)
    yield putFailedV2('ETH_LOAD_ACCOUNT',message)
    return false
  }
  return true
}


export function* wLoadNetwork(type, payload, meta) {
  const ethereum  = yield getContext(ETHEREUM)
  const w3 = yield getContext(W3)
  yield putFetchingV2(type)
 
  const chain_id_hex = ethereum.chainId
  const chain_id = parseInt(w3.utils.toDecimal(chain_id_hex))
  yield put({type: 'R_ETH_UPDATE_NETWORK', payload: {chain_id}})
  yield putSuccessV2(type)
  return chain_id
}

export function* wWatchNetwork(type, payload, meta) {
  const ethereum  = yield getContext(ETHEREUM)
  const w3 = yield getContext(W3)
  const channel = eventChannel(emitter => {
    ethereum.on('chainChanged', chain_id_hex => emitter(chain_id_hex))  
    return () => {            
      // Perform any cleanup you need here                 
    };  
  })
  try {  
    while (true) {
      const emitted_chain_id_hex = yield take(channel);  
      //yield putFetchingV2(type)
      yield putFetchingV2('ETH_LOAD_NETWORK')
      const emitted_chain_id = parseInt(w3.utils.toDecimal(emitted_chain_id_hex))
      yield put({type: 'R_ETH_UPDATE_NETWORK', payload: {chain_id: emitted_chain_id}})
      yield putSuccessV2('ETH_LOAD_NETWORK')
      //yield putSuccessV2(type)
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }    
  }
}

export function* wLoadNetworkV2(type, payload, meta) {
  const ethereum  = yield getContext(ETHEREUM)
  const w3 = yield getContext(W3)
  yield putFetchingV2(type)

  const chain_id  = yield w3.eth.getChainId()
  //console.warn(chainId)
  //const chain_id_hex = ethereum.chainId
  //const chain_id = parseInt(w3.utils.toDecimal(chain_id_hex))
  yield put({type: 'R_ETH_UPDATE_NETWORK', payload: {chain_id}})
  yield putSuccessV2(type, chain_id)

  const channel = eventChannel(emitter => {
    ethereum.on('chainChanged', chain_id_hex => emitter(chain_id_hex))  
    return () => {            
      // Perform any cleanup you need here                 
    };  
  })

  try {  
    while (true) {
      const emitted_chain_id_hex = yield take(channel);  
      //yield putFetchingV2(type)
      yield putFetchingV2(type)
      const emitted_chain_id = parseInt(w3.utils.toDecimal(emitted_chain_id_hex))
      yield put({type: 'R_ETH_UPDATE_NETWORK', payload: {chain_id: emitted_chain_id}})
      console.warn(chain_id)
      yield putSuccessV2(type, emitted_chain_id)
      //yield putSuccessV2(type)
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }    
  }

  return chain_id
}

export function* wWatchRPC(type, payload, meta) {
  const ethereum  = yield getContext(ETHEREUM)
  const channel = eventChannel(emitter => {
    ethereum.on('connect', (data) => emitter(data)) //disconnect
    return () => {            
      // Perform any cleanup you need here                 
    };  
  })
  yield putFetchingV2('ETH_LOAD_RPC')
  const isConnected = yield ethereum.isConnected()
  yield put({type: 'R_ETH_UPDATE_RPC', payload: {connected:isConnected}})
  yield putSuccessV2('ETH_LOAD_RPC')
  try {  
    while (true) {        
      const payload = yield take(channel);
      console.warn(payload)
      yield putFetchingV2('ETH_LOAD_RPC')
      yield put({type: 'R_ETH_UPDATE_RPC', payload: {connected:true}})
      yield putSuccessV2('ETH_LOAD_RPC')
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }    
  }
  return true
}