import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Joi from "joi";
import get from "lodash/get"
import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';
import reduce from "lodash/reduce"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { ethAddress, privateKey } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"

export const useGetInvestors= (networkId, tokenAddress) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_INVESTORS'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: { tokenAddress, networkId }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]

}


export const useBurnAndIssue = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_BURN_AND_ISSUE'
  const [data, error, status] = useThunkDataV3([action])

  const add = formdata => {
    const { private_key, fromAddress, toAddress } = {...formdata}
    console.warn(formdata)
    //const investorAddress = map[0]
   
    //const investorAddresses =  Object.entries(map).map((key,value)=>value['walletAddress'])
    dispatch({
      type: action, 
      payload: { 
        tokenAddress, 
        fromAddress: fromAddress,
        toAddress: toAddress,
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [ data, error, status, add, unmount]
}


export const useBurnAndIssueForm = () => {
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const { 
    register, handleSubmit, watch, 
    formState: {
      errors,  touchedFields, isValid
    }, 
    resetField, setValue
  } = useForm({
    resolver: joiResolver(Joi.object({
      fromAddress: ethAddress(),
      toAddress: ethAddress(),
      private_key: privateKey
    }))
  });
  //const createMapReg = idx => register(`map`)
  const fromAddress = watch("fromAddress")
  const toAddress = watch("toAddress")

  //const issueLength = watchIssue ? Array.isArray(watchIssue) ? watchIssue.length : 1 :  0
  return [ 
    [
      register('private_key'), 
      (address)=>setValue('fromAddress',address), 
      (address)=>setValue('toAddress',address)
    ], 
    handleSubmit,
    {
      errors: {
        private_key: get(errors,['private_key','type'], null),
        fromAddress: get(errors,['fromAddress','type'], null),
        toAddress: get(errors,['toAddress','type'], null),
      },
      isValid,
      touchedFields,
      fields : {
        fromAddress, toAddress
      }
    }, 
    ()=>{
      resetField('private_key')
      resetField('fromAddress')
      resetField('toAddress')
    }
  ]
}