import React, {Fragment, useEffect, useMemo, useState} from 'react';
import { useSelector } from 'react-redux'

import get from "lodash/get"
import { 
  Router, 
  Route, 
  BrowserRouter,
  Redirect,
  Switch,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom'

//import { ThemeProvider } from 'styled-components'
//import theme from 'setup/themeConfig'

import qs from 'qs'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import CardActionArea from '@mui/material/CardActionArea';
import Breadcrumbs from '@mui/material/Breadcrumbs';

import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PauseIcon from '@mui/icons-material/Pause';
import PeopleIcon from '@mui/icons-material/People';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import InfoIcon from '@mui/icons-material/Info';
import ReceiptIcon from '@mui/icons-material/Receipt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useTranslation } from 'react-i18next';

import { SnackbarProvider, useSnackbar  } from 'notistack';

import { HandleError } from 'components/molecules/HandleStatus'

import { DashboardSidebar } from 'components/organisms/SideBar'
import { DashboardLayout } from 'components/organisms/Layout'
import { DashboardNavbar } from 'components/organisms/NavBar'
import { LanguageSelect, LanguageSelectV3 } from 'components/organisms/LanguageSelect'

import { deviasTheme } from 'setup/themeConfig'

import { LoadMetaMask, LoadProvider } from 'components/organisms/ETH/metamask/load'
import { NetworkSelectV4  } from "components/organisms/ETH/metamask/network"
import { useNetworkV3, useTokenAddress, useWalletAddress } from "components/organisms/ETH/metamask/network/use"
import { LoadPolymathToken } from 'components/organisms/ETH/loaders/polymath'
import { LoadConfig } from 'components/organisms/ETH/sto/LoadConfig'
import { EmailFormat, EmailList } from 'components/organisms/ETH/sto/EmailFormat'
import { InvestorTable } from 'components/organisms/ETH/sto/InvestorTable'
import { STOList, STOListV2 } from 'components/organisms/ETH/sto/STOList'
//import { FundTable } from 'components/organisms/ETH/sto/FundTable'
//import { UserTableV2 } from 'components/organisms/ETH/sto/CreateFund'
import { Pause } from 'components/organisms/ETH/sto/Pause'
import { StoInfo,StoDetails } from 'components/organisms/ETH/sto/StoInfo'
import { UserTable, AdminTable } from 'components/organisms/ETH/sto/UserTable'
import { LinkWallet } from 'components/organisms/ETH/sto/LinkWallet'
//import { CreateUser } from 'components/organisms/ETH/sto/CreateUser'
import { TxTable } from 'components/organisms/ETH/sto/TxTable'
import { WhitelistNewAddress } from 'components/organisms/ETH/sto/WhitelistNewAddress'
import { RemoveWhitelist } from 'components/organisms/ETH/sto/RemoveWhitelist'
import { ImportCsv } from 'components/organisms/ETH/sto/ImportCsv'
import { ExportCsv } from 'components/organisms/ETH/sto/ExportCsv'
import { ImportUsers } from 'components/organisms/ETH/sto/ImportUsers'
//import { ControllerTransferTable } from 'components/organisms/ETH/sto/ControllerTransfer'
import { BurnAll } from 'components/organisms/ETH/sto/BurnAll'
import { BurnAndIssue } from 'components/organisms/ETH/sto/BurnAndIssue'
import { DeploySto } from 'components/organisms/ETH/sto/DeploySto'
import { ImportSto } from 'components/organisms/ETH/sto/ImportSto'
import { RemoveSto } from 'components/organisms/ETH/sto/RemoveSto'
import { UserWalletInfo, UserInfoV2 } from 'components/organisms/ETH/sto/UserInfo'
import { OptionPage } from 'components/organisms/ETH/sto/operations'
import { RolePauser, RemovePauser, AddPauser } from 'components/organisms/ETH/sto/RolePauser'
import { LoadUserRoles } from 'components/organisms/ETH/sto/LoadUserRoles'
import { ManageRoles, MRUserTable } from 'components/organisms/ETH/sto/ManageRoles'
import { AllBalances } from 'components/organisms/ETH/sto/AllBalances'
import { AllTransfers } from 'components/organisms/ETH/sto/AllTransfers'
import { IssueSto } from 'components/organisms/ETH/sto/IssueSto'


//import { SellSto, OfferList, TakeOffer, OfferListDelete} from 'components/organisms/ETH/sto/Swapcat'

import { RestrictAccessToken, OnlyUnauthorized } from 'components/organisms/Auth/Restrict'
import { ManageServerRoles } from 'components/organisms/Auth/ManageRoles'

import { usePolyConfig } from 'components/organisms/ETH/sto/LoadConfig/use'


import { Login } from 'components/organisms/Auth/Login'
import { Logout } from 'components/organisms/Auth/Logout'
import { GeneratePassword } from 'components/organisms/Auth/GeneratePassword'
//import { Register } from 'components/organisms/Auth/Register'

import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useDefaultRoute } from 'components/useHooks/useRoute'

import { selectAccessToken } from "utilities/selectors"

const theme = createTheme(deviasTheme)

const options = [
  {
    description: 'INVESTOR_DESCRIPTION',
    Icon: PeopleIcon,
    title: 'INVESTORS',
    //restrictedRoles: ['ADMIN_ROLE'],
    pathname: 'investor'
  },
  {
    description: 'STOINFO_DESCRIPTION',
    Icon: InfoIcon,
    title: 'STO_INFO',
    pathname: `sto_info`
  },
  {
    description: 'TXLIST_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'TX_LIST',
    pathname: `tx_list`
  }, 
  {
    description: 'OPERATION_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'OPERATIONS',
    pathname: `operations`,
    //restrictedUserRoles: ['OPERATION_ROLE'],
  },    
]




const operation_options = [
  {
    description: 'ISSUE_STO_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'ISSUE_STO',
    pathname: `issue_sto`,
    restrictedTokenRoles: ['MINTER_ROLE'],
  }, 
  {
    description: 'REMOVE_WHITELIST_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'REMOVE_WHITELIST',
    pathname: `remove_whitelist`,
    restrictedTokenRoles: ['WHITELIST_ADMIN_ROLE'],
  },  
  {
    description: 'ADD_WHITELIST_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'ADD_WHITELIST',
    pathname: `whitelist_new_address`,
    restrictedTokenRoles: ['WHITELIST_ADMIN_ROLE'],
  },
  {
    description: 'BURN_AND_ISSUE_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'BURN_AND_ISSUE',
    pathname: `burn_and_issue`,
    restrictedTokenRoles: ['BURNER_ROLE', 'MINTER_ROLE']
  },  
  {
    description: 'PAUSE_DESCRIPTION',
    Icon: PauseIcon,
    title: 'PAUSE',
    pathname: 'pause',
    restrictedTokenRoles: ['PAUSER_ROLE']
  }, 
  {
    description: 'BURN_ALL_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'BURN_ALL',
    pathname: `burn_all`,
    restrictedTokenRoles: ['BURNER_ROLE']
  },
  {
    description: 'MANAGE_ROLES_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'MANAGE_ROLES',
    pathname: `manage_roles`,
    restrictedTokenRoles: ['ADMIN_ROLE']
  }, 
]



const email_options = [
  {
    description: 'ISSUE_EMAIL_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'ISSUE_EMAIL',
    pathname: `issue`
  } 
]
const email_routes = [
  {
    title: 'ISSUE_EMAIL',
    pathname: 'issue',
    Icon: ReceiptIcon,
  },
  {
    title: 'BURN_EMAIL',
    pathname: 'burn',
    Icon: ReceiptIcon,
  },
  {
    title: 'BURN_AND_ISSUE_EMAIL',
    pathname: 'burn_and_issue',
    Icon: ReceiptIcon,
  },
  {
    title: 'LINK_WALLET_EMAIL',
    pathname: 'link_wallet',
    Icon: ReceiptIcon,
  },
  {
    title: 'UNLINK_WALLET_EMAIL',
    pathname: 'unlink_wallet',
    Icon: ReceiptIcon,
  }
]

const sidebarRoutes = [
  {
    title: 'STOS',
    Icon: ListAltIcon,
    route: "/sto/sto_list",
    restrictedRoles: ['STO_ROLE']
  },
  {
    title: 'EMAIL_FORMAT',
    Icon: MailIcon,
    route: "/email",
    restrictedRoles: ['EMAIL_ROLE'],
  },
  {
    title: 'INVESTORS',
    Icon: GroupIcon,
    route: "/users/user_list",
    restrictedRoles: ['USER_ROLE'],
  },
  {
    title: 'ADMINS',
    Icon: GroupIcon,
    route: "/users/admin_list",
    restrictedRoles: ['USER_ROLE'],
  },
  /*
  {
    title: 'SANDBOX',
    Icon: OpenInNewIcon,
    route: "/sand_box"
  }
  */
];

//move to dedicated folder?
function Overlay(props) {
  const {children} = {...props}
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const { pathname, search } = useLocation()
  const [ open, handleOpen, handleClose ]  = useMemoizedOpen()
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  const { lang } = {...query}
  const [pushCurPath, pushQuery] = usePushRoute()
  const handleLang = (_lang) => {
    pushQuery({lang: _lang})
  }
  const handleNetwork = network_id => {
    history.replace({pathname: pathname, search: `?networkId=${network_id}`})
  }
  /*
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });
  const _handleRoute = route => {
    onClose?.()
    handleRoute(route)
  }
  useEffect(
    () => {
      if (open) {
        onClose?.();
      }
    },
    []
  );
  */
  return <>
    <CssBaseline />
    <DashboardNavbar 
      onSidebarOpen={handleOpen} 
      handleLang={handleLang} 
      lang={lang} 
      handleNetwork={handleNetwork}
    />
    <DashboardSidebar 
      open={open} 
      onClose={handleClose} 
      handleRoute={pushCurPath}
      handleLogout={()=> history.push({pathname: '/logout'})}
      routes={sidebarRoutes}
      t={t}
    />
    <DashboardLayout>
      {children}
    </DashboardLayout>
  </>


}

//is there a better way?
const DefaultQuery = props => {
  const { defaultQ={} , children } = {...props}
  const history = useHistory()
  const { pathname, search } = useLocation()
  const _query = qs.parse(search, { ignoreQueryPrefix: true })
  const [first, setFirst] = useState(true)
  useEffect(()=>{
    const _search = qs.stringify({...defaultQ, ..._query})
    console.log(pathname)
    history.replace({pathname: pathname, search: `?${_search}`})
    setFirst(false)
  },[search])
  if(first) return <div>loading query</div>
  return children
}

//needs updating
const usePushRoute = () => {
  const history = useHistory()
  const { path } = useRouteMatch();
  const { search, pathname } = useLocation()
  //const _lang = '?lang=en'
  const routeCurPath = (newPath, query) => {
    const _search = qs.stringify(query)
    history.push({pathname: `${path}${newPath}`, search: search})
  }  
  const pushQuery = (query) => {
    const _search = qs.stringify({...search, ...query})
    history.push({pathname: pathname, search: `?${_search}`})
  }
  return [routeCurPath, pushQuery]
}

const ParsePage = props => {
  const history = useHistory()
  const match =  useRouteMatch()
  const params = useParams()
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  //const state = location['state']
  //const pathname = location['pathname']

  return <pre>{JSON.stringify({match,params, location, query},0,2)}</pre>
}

const UsersCrumbs = props => {
  const {last=null, curPath} =  {...props}
  let options = [{
    pathname: `${curPath}/list`,
    text: 'Users',
  }]
  if(last) last.forEach(item=> options.push(item))
  return <Breadcrumbs aria-label="breadcrumb" >
    {RouteCrumbs(options)}
  </Breadcrumbs>
}

export const useUserId = () => {
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  const { userId } = {...query}
  return [userId]
}

export const useEmail = () => {
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  const { email } = {...query}
  return [email]
}


const WrappedUserRoute = props => {
  const { t, i18n } = useTranslation();
  const { path } = useRouteMatch();
  const [userId] = useUserId()
  const [email] = useEmail()
  const  _handleRoute = useDefaultRoute() 
  const { enqueueSnackbar } = useSnackbar();
  const [networkId] = useNetworkV3()

  const handleOnSuccess = () => {
    //enqueueSnackbar('Success!', {variant:'success'})
    _handleRoute(`${path}/user_list`)
  }
  const handleOnSuccessAdmin = () => {
    //enqueueSnackbar('Success!', {variant:'success'})
    _handleRoute(`${path}/admin_list`)
  }
  const routeUserInfo = (userId) => {
    _handleRoute('info', {userId})
  }
  const routeUserWallet = (userId) => {
    _handleRoute('wallet', {userId})
  }
  const routeCreate = (userId) => {
    _handleRoute('create_user')
  }
  const routeImport = (userId) => {
    _handleRoute('import_users')
  }
  const routeImportAdmins = (userId) => {
    _handleRoute('import_admins')
  }
  const routeRoles = (userId) => {
    _handleRoute('manage_roles', {userId})
  }
  const routeBalances = (userId) => {
    _handleRoute('all_balances', {userId, networkId})
  }
  const routeTransfers = (userId) => {
    _handleRoute('all_transfers', {userId, networkId})
  }
  const routeGeneratePassword = (email) => {
    _handleRoute('generate_password', {email})
  } 
  return <Switch>
    <Route exact path={`${path}/user_list`}>
      {/*<UsersCrumbs curPath={path} />*/}
      <UserTable 
        routeUserWallet={routeUserWallet}
        routeUserInfo={routeUserInfo}
        //routeCreate={routeCreate}
        routeImport={routeImport}
        routeRoles={routeRoles}
        routeBalances={routeBalances}
        onSuccess={handleOnSuccess}
        routeTransfers={routeTransfers}
      />
    </Route>
    <Route exact path={`${path}/admin_list`}>
      <AdminTable 
        routeUserWallet={routeUserWallet}
        routeRoles={routeRoles}
        onSuccess={handleOnSuccess}
        routeImport={routeImportAdmins}
        routeGeneratePassword={routeGeneratePassword}
      />
    </Route>
    <Route exact path={`${path}/import_users`}>
      {/*<UsersCrumbs curPath={path} last={[{text: 'Import'}]} />*/}
      <ImportUsers onSuccess={handleOnSuccess}/>
    </Route>
    <Route exact path={`${path}/import_admins`}>
      {/*<UsersCrumbs curPath={path} last={[{text: 'Import'}]} />*/}
      <ImportUsers 
        import_admins={true} 
        onSuccess={handleOnSuccessAdmin}
      />
    </Route>    
    <Route exact path={`${path}/wallet`}>
      {/*<UsersCrumbs curPath={path} last={[{text: 'Info'}]} />*/}
      <UserWalletInfo userId={userId}/>
    </Route>
    <Route exact path={`${path}/info`}>
      {/*<UsersCrumbs curPath={path} last={[{text: 'Info'}]} />*/}
      <UserInfoV2 userId={userId}/>
    </Route>
    <Route exact path={`${path}/all_balances`}>
      <AllBalances userId={userId} networkId={networkId}/>
    </Route>
    <Route exact path={`${path}/all_transfers`}>
      <AllTransfers userId={userId} networkId={networkId}/>
    </Route>
    <Route exact path={`${path}/manage_roles`}>
      {/*<UsersCrumbs curPath={path} last={[{text: 'Info'}]} />*/}
      <ManageServerRoles userId={userId}/>
    </Route> 
    <Route exact path={`${path}/generate_password`}>
      {/*<UsersCrumbs curPath={path} last={[{text: 'Info'}]} />*/}
      <GeneratePassword email={email}/>
    </Route> 
    {/* 
    <Route exact path={`${path}/`}>
      <LinkWallet userId={userId} onSuccess={handleOnSuccess}/>
    </Route>
    */}
    <Redirect to={{pathname: `${path}/user_list`}} />

  </Switch>
}

const WrappedLoadConfig = props => {
  const {children} = {...props}
  //const accessToken = useSelector(selectAccessToken)
  const [networkId] = useNetworkV3()
  const _networkId = networkId ? networkId : 1

  return <LoadConfig networkId={networkId}>{children}</LoadConfig>

}

const StoOptionCrumbs = props => {
  const {tokenAddress, last=null} = {...props}
  let options = [
    {
      pathname: '/sto/sto_route',
      text: 'OPTIONS',
      search: {tokenAddress}
    } 
  ]
  if(last) last.forEach(item=> options.push(item))
  return <StoCrumbs last={options}/>
}

const StoCrumbs = props => {
  const { last=null} =  {...props}
  let options = [{
    pathname:'/main/sto/sto_list',
    text:'STOS',
  }]
  if(last) last.forEach(item=> options.push(item))
  return RouteCrumbs(options)
}


const RouteCrumbs = (options) => {
  const _handleRoute = useDefaultRoute()
  const { t, i18n } = useTranslation();
  return <Breadcrumbs aria-label="breadcrumb" >
  {options.map((item, idx)=>{
    const { pathname, search, text } = {...item}
    if(idx===options.length-1) return <Typography color="inherit">{t(text)}</Typography>
    return <Link href="#" color="inherit" onClick={(e)=>{
      e.preventDefault()
      _handleRoute(pathname, search)
    }}>
      {text}
    </Link>
  })}
  </Breadcrumbs>
}

const WrappedStoRoute = props => {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const { pathname, search } = useLocation()
  const { enqueueSnackbar } = useSnackbar();  
  const [networkId] = useNetworkV3()
  const [tokenAddress] = useTokenAddress()
  const { path, url } = useRouteMatch();
  const  _handleRoute = useDefaultRoute() 
  const [ , , , fetch] = usePolyConfig(networkId)

  const refreshStoList = messageType => {
    enqueueSnackbar(t(messageType), {variant: 'success'})
    fetch()
    _handleRoute(`${path}/sto_list`)
  }
  const routeToOptions = (address) => {
    _handleRoute('sto_route', {tokenAddress: address})
  }
  const routeToDeploy = (address) => {
    _handleRoute('deploy_sto')
  }
  const routeToImport = (address) => {
    _handleRoute('import_sto')
  }
  const routeToRemove = (address) => {
    _handleRoute('remove_sto')
  }
  /*
  const handleNetwork = network_id => {
    history.replace({pathname: pathname, search: `?networkId=${network_id}`})
  }*/
  return <Switch>
      <Route exact path={`${path}/sto_list`}>
        <STOListV2 
          networkId={networkId} 
          //handleNetwork={handleNetwork}
          routeToOptions={routeToOptions} 
          routeToDeploy={routeToDeploy}
          routeToImport={routeToImport}
          routeToRemove={routeToRemove}
        />
      </Route>
      <Route exact path={`${path}/deploy_sto`}>

        <DeploySto networkId={networkId} handleSuccess={()=>refreshStoList('DEPLOY_STO_SUCCESS')}/>
      </Route>
      <Route exact path={`${path}/import_sto`}>
        {/*<StoCrumbs last={[{text: t('IMPORT')}]}/>*/}
        <ImportSto networkId={networkId} handleSuccess={()=>refreshStoList('IMPORT_STO_SUCCESS')}/>
      </Route>
      <Route exact path={`${path}/remove_sto`}>
        {/*<StoCrumbs last={[{text: t('REMOVE')}]}/>*/}
        <RemoveSto networkId={networkId} handleSuccess={()=>refreshStoList('REMOVE_STO_SUCCESS')}/>
      </Route>
      <Route path={`${path}/sto_route`}>
        <WrappedPolymathRoute />
      </Route>
      <Redirect to={{pathname: `${path}/sto_list`}} /> 
  </Switch>

}

const WrappedPolymathRoute = props => {
  const { t, i18n } = useTranslation();
  const [networkId] = useNetworkV3()
  const [tokenAddress] = useTokenAddress()
  //const accessToken = useSelector(selectAccessToken)
  const [walletAddress] = useWalletAddress()
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const _handleRoute = useDefaultRoute() 

  const onSuccess = () => {
    enqueueSnackbar(t('SUCCESS'), {variant:'success'})
  }

  const routeWallets = () => {
    _handleRoute('create_fund', {tokenAddress,walletAddress})
  }
  const routeWhitelistNew = () => {
    _handleRoute('whitelist_new_address', {tokenAddress})
  }
  const routeAddPauser = () => {
    _handleRoute('add_pauser', {tokenAddress})
  }

  const routeOptions = (pathname) => {
    _handleRoute(`${path}/${pathname}`)
    //history.push({pathname: pathname, search: `?address=${address}`})
  }
  const routeManageRoles = (walletAddress) => {
    _handleRoute('manage_roles/user', { tokenAddress, walletAddress })
    //history.push({pathname: pathname, search: `?address=${address}`})
  } 
  return <Fragment>
    <LoadProvider>
      {/*<LoadPolymathToken tokenAddress={tokenAddress} network_id={networkId}>*/}
        <LoadUserRoles tokenAddress={tokenAddress} networkId={networkId}>
          <StoDetails networkId={networkId} tokenAddress={tokenAddress}/>
          <Box p={2}/>
          <Switch>
            <Route exact path={path}>
              {/*<StoCrumbs last={[{text: 'OPTIONS'}]} />*/}
              <OptionPage routes={options} handleRoute={routeOptions}/>
            </Route>
            {/*
            <Route exact path={`${path}/fund`}>
              <FundTable routeWallets={routeWallets} tokenAddress={tokenAddress} networkId={networkId}/>
            </Route>
            */}
            <Route exact path={`${path}/investor`}>
              {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'INVESTORS'}]}/>*/}
              <InvestorTable 
                //handleGoToFund={routeCreateFund}
                handleGoToNewAddress={routeWhitelistNew}
                tokenAddress={tokenAddress} 
                networkId={networkId}
                //accessToken={accessToken}
              />
            </Route>
    

            <Route exact path={`${path}/sto_info`}>
              {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'INFO'}]}/>*/}
              <StoInfo networkId={networkId} tokenAddress={tokenAddress}/>
            </Route>
            <Route exact path={`${path}/tx_list`}>
              {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'TRANSACTIONS'}]}/>*/}
              <TxTable networkId={networkId} tokenAddress={tokenAddress}/>
            </Route>


            <Route path={`${path}/operations`}>
              <WrappedOperationRoute/>
            </Route>

          </Switch>
        </LoadUserRoles>  
     {/* </LoadPolymathToken>*/}
    </LoadProvider>
  </Fragment>
}

const WrappedOperationRoute = props => {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const [networkId] = useNetworkV3()
  const [tokenAddress] = useTokenAddress()
  const accessToken = useSelector(store=> get(store, ['auth', 'access_token'], null))
  const [walletAddress] = useWalletAddress()
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const _handleRoute = useDefaultRoute() 
  const { pathname, search } = useLocation()


  const handleSuccess = () => {
    enqueueSnackbar(t('SUCCESS'), {variant:'success'})
  }

  const routeOptions = (_path) => {
    //_handleRoute(`${pathname}/${_path}`)
    history.push({pathname:`${path}/${_path}`, search: search})
  }
  const routeManageRoles = (walletAddress) => {
    _handleRoute('manage_roles/user', { tokenAddress, walletAddress })
    //history.push({pathname: pathname, search: `?address=${address}`})
  } 
  return <Fragment>
    <Switch>
      <Route path={`${path}/options`}>
        {/*<ParsePage/>*/}
        <OptionPage routes={operation_options} handleRoute={routeOptions}/>
      </Route>
      <Route exact path={`${path}/issue_sto`}>
        {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'BURN_ALL'}]}/>*/}
        <IssueSto 
          networkId={networkId} 
          tokenAddress={tokenAddress} 
          onSuccess={()=>_handleRoute('/main/sto/sto_route/tx_list')}
        />
      </Route>
      <Route exact path={`${path}/burn_all`}>
        {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'BURN_ALL'}]}/>*/}
        <BurnAll networkId={networkId} tokenAddress={tokenAddress}/>
      </Route>
      <Route exact path={`${path}/whitelist_new_address`}>
        {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'ADD_TO_WHITELIST'}]}/>*/}
        <WhitelistNewAddress networkId={networkId} tokenAddress={tokenAddress}/>
      </Route>
      <Route exact path={`${path}/remove_whitelist`}>
        {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'REMOVE_FROM_WHITELIST'}]}/>*/}
        <RemoveWhitelist networkId={networkId} tokenAddress={tokenAddress}/>
      </Route>
      <Route exact path={`${path}/pause`}>
        {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'PAUSE'}]}/>*/}
        <Pause tokenAddress={tokenAddress} networkId={networkId} handleSuccess={handleSuccess}/>
      </Route>
      <Route exact path={`${path}/burn_and_issue`}>
         {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'BURN_AND_ISSUE'}]}/>*/}
        <BurnAndIssue networkId={networkId} tokenAddress={tokenAddress}/>
      </Route>
      <Route exact path={`${path}/manage_roles`}>
        {/*<StoOptionCrumbs tokenAddress={tokenAddress} last={[{text: 'MANAGE_ROLES'}]}/>*/}
        <MRUserTable networkId={networkId} tokenAddress={tokenAddress} handleAddress={routeManageRoles} handleSuccess={handleSuccess}/>
      </Route>
      <Route exact path={`${path}/manage_roles/user`}>
        <ManageRoles networkId={networkId} tokenAddress={tokenAddress} walletAddress={walletAddress} handleSuccess={handleSuccess}/>
      </Route>      
      <Redirect to={{pathname: `${path}/options`, search:search}} />
    </Switch>
  </Fragment>
}

const WrappedEmailRoute = props => {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const _handleRoute = useDefaultRoute() 
  const { pathname, search } = useLocation()

  const onSuccess = () => {
    enqueueSnackbar(t('SUCCESS'), {variant:'success'})
  }

  const routeOptions = (_path) => {
    //_handleRoute(`${pathname}/${_path}`)
    history.push({pathname:`${path}/${_path}`, search: search})
  }

  return <Fragment>
    <Switch>
      <Route path={`${path}/options`}>
        <EmailList handleRoute={routeOptions} routes={email_routes}/>
        {/*<OptionPage routes={email_options} handleRoute={routeOptions}/>*/}
      </Route>
      <Route exact path={`${path}/issue`}>
        <EmailFormat  emailType={'ISSUE'} onSuccess={()=>{
          onSuccess()
          _handleRoute(`${path}/options`)
        }}/>
      </Route>
      <Route exact path={`${path}/burn`}>
        <EmailFormat emailType={'BURN'} onSuccess={()=>{
          onSuccess()
          _handleRoute(`${path}/options`)
        }}/>
      </Route>    
      <Route exact path={`${path}/burn_and_issue`}>
        <EmailFormat emailType={'BURN_AND_ISSUE'} onSuccess={()=>{
          onSuccess()
          _handleRoute(`${path}/options`)
        }}/>
      </Route> 

      <Route exact path={`${path}/link_wallet`}>
        <EmailFormat emailType={'LINK_WALLET'} onSuccess={()=>{
          onSuccess()
          _handleRoute(`${path}/options`)
        }}/>
      </Route> 
      <Route exact path={`${path}/unlink_wallet`}>
        <EmailFormat emailType={'UNLINK_WALLET'} onSuccess={()=>{
          onSuccess()
          _handleRoute(`${path}/options`)
        }}/>
      </Route> 
      <Redirect to={{pathname: `${path}/options`, search:search}} />
    </Switch>
  </Fragment>
}


const AuthRoute = props => {
  const { t, i18n } = useTranslation();
  const { path } = useRouteMatch();
  const [pushCurPath] = usePushRoute()

  return <Switch>
    <Route path={`${path}/login`}>
      <Login/>
    </Route>

    <Redirect to={{pathname: `${path}/login`}} />
  </Switch>
}



const WrappedRestrictAccessToken = ({children}) => {
  const history = useHistory()
  const routeToLogin = () => {
    history.push({pathname: '/auth'})
  }
  return <RestrictAccessToken routeToLogin={routeToLogin}>
    {children}
  </RestrictAccessToken>
}

const WrappedOnlyUnauthorized = ({children}) => {
  const history = useHistory()
  const routeToMain = () => {
    history.push({pathname: '/main'})
  }
  return <OnlyUnauthorized routeToMain={routeToMain}>
    {children}
  </OnlyUnauthorized>
}

//does not work correctly?
const RedirectWithQuery = props => {
  const { search } = useLocation()
  const {pathname } = {props}
  return <Redirect to={{pathname: pathname, search:search}} />
}


export const RestrictReroute = ({ requires, children, reroute }) => {
  useEffect(()=>{
    if(!requires) {
      reroute()
    }    
  },[requires])
  return children
}

//not needed. DefaultQuery better?
const RequireNetworkId = ({children}) => {
  const history = useHistory()
  const [network_id] = useNetworkV3()
  const reRoute = () => {
    history.push({pathname: '/'})
  }
  return <RestrictReroute requires={network_id} reroute={reRoute}>
    {children}
  </RestrictReroute>
}


const WrappedLogout = props => {
  const history = useHistory()
  //const { pathname, search } = useLocation()
  const handleSuccess = network_id => {
    history.push({pathname: '/auth'})
  }
  return <Logout handleSuccess={handleSuccess}/>
}

const PlainLangSelect = () => {
  const { search } = useLocation()
  const query = qs.parse(search, { ignoreQueryPrefix: true })
  const { lang } = {...query}
  const [pushCurPath, pushQuery] = usePushRoute()
  const handleLang = (_lang) => {
    pushQuery({lang: _lang})
  }
  return <Box display="flex" m={2}>
    <LanguageSelectV3 handleLang={handleLang} lang={lang} />
  </Box>
}

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <BrowserRouter>
            <Switch>

              <Route path='/auth'>
                <WrappedOnlyUnauthorized> 
                  <PlainLangSelect />
                  <Box p={'64px'}/>
                  <CssBaseline />
                  <AuthRoute/>
                </WrappedOnlyUnauthorized>
              </Route>
           
              <Route path='/main'>
                <WrappedRestrictAccessToken>
                  <DefaultQuery defaultQ={{networkId:1}}>
                    <Overlay>

                      <Switch>
                        
                        <Route path={`/main/sto`}>
                          <WrappedLoadConfig>
                            <WrappedStoRoute/>
                          </WrappedLoadConfig>
                        </Route>

                        <Route path='/main/email'>
                          <WrappedEmailRoute/>
                        </Route> 

                        <Route path='/main/users'>
                          <WrappedUserRoute />
                        </Route>

                        {/*<Redirect from="/main" to="/main/sto" exact /> */}
                        <Redirect to={{pathname: "/main/sto"}} />
                      </Switch>

                    </Overlay>

                  </DefaultQuery> 
                </WrappedRestrictAccessToken>
      
              </Route>
              {/*
              <Route exact path='/sand_box'>
                <ParsePage />
       
              </Route>
              */}
              <Route path={'/logout'}>
                <WrappedLogout />
              </Route>

              <Redirect to={{pathname: `/auth`}} />
              
            </Switch>
          </BrowserRouter>  
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}
export default App;



/*
const OptionCard = ({ option, handleRoute }) => {
  const { title, pathname, description, Icon } = {...option}
  return <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}
  >
    <CardActionArea onClick={()=>handleRoute(pathname)}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: 3
          }}
        >
          <Icon fontSize="large"/>
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {title}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {description}
        </Typography>
        
      </CardContent>

      <Box sx={{ flexGrow: 1 }} />
    </CardActionArea>
  </Card>
}
*/
/*
const make_options = [
  {
    description: 'OFFER_SELL_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'OFFER_SELL',
    pathname: `offer_sell`
  },    
  {
    description: 'OFFER_BUY_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'OFFER_BUY',
    pathname: `offer_buy`
  },    
]
*/
/*
const swapcat_options = [
  {
    description: 'MAKE_OFFER_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'MAKE_OFFER',
    pathname: `make_offer`
  },    
  {
    description: 'TAKE_OFFER_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'TAKE_OFFER',
    pathname: `take_offer`
  },
  {
    description: 'DELETE_OFFER_DESCRIPTION',
    Icon: ReceiptIcon,
    title: 'DELETE_OFFER',
    pathname: `delete_offer`
  },  
]
*/


/*
const mock_description = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'

const OptionPage = props => {
  const { t, i18n } = useTranslation();
  //const history = useHistory()
  //const match =  useRouteMatch()
  //const params = useParams()
  //const location = useLocation()
  //const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  //const {address} = {...query}
  const { path, url } = useRouteMatch();
  const _handleRoute = useDefaultRoute()
  const handleRoute = (pathname) => {
    _handleRoute(pathname)
    //history.push({pathname: pathname, search: `?address=${address}`})
  }
  const options = [
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: PeopleIcon,
      title: t('INVESTORS'),
      pathname: `${path}/investor`
    },
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: CreditScoreIcon,
      title: t('FUNDS'),
      pathname: `${path}/fund`
    },
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: PauseIcon,
      title: t('PAUSE'),
      pathname: `${path}/pause`
    },
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: InfoIcon,
      title: t('STO_INFO'),
      pathname: `${path}/sto_info`
    },
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: ReceiptIcon,
      title: t('TX_LIST'),
      pathname: `${path}/tx_list`
    },
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: ReceiptIcon,
      title: t('REMOVE_WHITELIST'),
      pathname: `${path}/remove_whitelist`
    },  
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: ReceiptIcon,
      title: t('ADD_WHITELIST'),
      pathname: `${path}/whitelist_new_address`
    }, 
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: ReceiptIcon,
      title: t('BURN_ALL'),
      pathname: `${path}/burn_all`
    }, 
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: ReceiptIcon,
      title: t('CREATE_FUND'),
      pathname: `${path}/create_fund_v2`
    },
    {
      description: t('MOCK_DESCRIPTION'),
      Icon: ReceiptIcon,
      title: t('BURN_AND_ISSUE'),
      pathname: `${path}/burn_and_issue`
    },       
  ]
  return <Fragment>
    
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8
      }}
    >
      <Container maxWidth={false}>
        <Box sx={{ pt: 3 }}>
          <Grid
            container
            spacing={3}
          >
            
            {options.map(option=> <Grid
              item
              //key={product.id}
              lg={4}
              md={6}
              xs={12}
            >
              <OptionCard option={option}  handleRoute={handleRoute}/>
            </Grid>)}
          </Grid>
        </Box> 
      </Container>
    </Box>
  </Fragment>
}


const PolymathRouteCrumbs = props => {
  const {tokenAddress, current='', last=null} = {...props}
  let options = [
    {
      text: current,
      search: `?address=${tokenAddress}`
    } 
  ]
  if(last) last.forEach(item=> options.push(item))
  return <StoOptionCrumbs tokenAddress={tokenAddress} last={options}/>
}
*/


/*
const WrappedSwapcatRoute = props => {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const [networkId] = useNetworkV3()
  const [tokenAddress] = useTokenAddress()
  const accessToken = useSelector(store=> get(store, ['auth', 'access_token'], null))
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname, search } = useLocation()
  const _handleRoute = useDefaultRoute() 

  //temp
  const query = qs.parse(search, { ignoreQueryPrefix: true })
  const { offerId, price } = {...query}

  const onSuccess = () => {
    enqueueSnackbar(t('SUCCESS'), {variant:'success'})
  }

  const routeOptions = (_path) => {
    //_handleRoute(`${pathname}/${_path}`)
    history.push({pathname:`${path}/${_path}`, search: search})
  }

  const routeTakeOffer = (offerId, price) => {
    _handleRoute(`${path}/take_offer_form`, { networkId, offerId, price })
    //history.push({pathname:`${path}/take_offer_form`, search: {offerId, price, ...search}})
  }
  return <Fragment>
    <Switch>
      <Route path={`${path}/options`}>
        <OptionPage routes={swapcat_options} handleRoute={routeOptions}/>
      </Route>
      <Route path={`${path}/make_offer`}>
        <WrappedMakeOfferRoute />
      </Route>
      <Route path={`${path}/take_offer`}>
        <OfferList stoAddress={tokenAddress} networkId={networkId} routeTakeOffer={routeTakeOffer}/>
      </Route>
      <Route path={`${path}/take_offer_form`}>
        <TakeOffer networkId={networkId} offerId={offerId} price={price}/>
      </Route>
      <Route path={`${path}/delete_offer`}>
        <OfferListDelete stoAddress={tokenAddress} networkId={networkId}/>
      </Route>  
      <Redirect to={{pathname: `${path}/options`, search:search}} />
    </Switch>
  </Fragment>
}

const WrappedMakeOfferRoute = props => {
  const { t, i18n } = useTranslation();
  const history = useHistory()
  const [networkId] = useNetworkV3()
  const [tokenAddress] = useTokenAddress()
  const accessToken = useSelector(store=> get(store, ['auth', 'access_token'], null))
  const { path } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();
  const { pathname, search } = useLocation()


  const onSuccess = () => {
    enqueueSnackbar(t('SUCCESS'), {variant:'success'})
  }

  const routeOptions = (_path) => {
    //_handleRoute(`${pathname}/${_path}`)
    history.push({pathname:`${path}/${_path}`, search: search})
  }

  return <Fragment>
    <Switch>
      <Route path={`${path}/options`}>
        <OptionPage routes={make_options} handleRoute={routeOptions}/>
      </Route>
      <Route path={`${path}/offer_sell`}>
        <SellSto stoAddress={tokenAddress} networkId={networkId}/>
      </Route>
      <Route path={`${path}/offer_buy`}>
        <ParsePage/>
      </Route>       
      <Redirect to={{pathname: `${path}/options`, search:search}} />
    </Switch>
  </Fragment>
}
*/