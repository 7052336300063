import React, { useState, useEffect, useMemo, Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar  } from 'notistack';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from "@mui/material/SnackbarContent";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import reduce from 'lodash/reduce'

import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useStyles } from 'components/useHooks/useStyles'
import { calcFetching, calcError, calcPending } from "utilities/misc"

export const HandleFetching = props => {
  const { children, error, fetching } = {...props}
  if(fetching) return <div>Fetching data...</div>
  if(error) return <pre>{JSON.stringify(error,0,2)}</pre>
    return children
}

export const HandleRefresh = props => {
  const { children, data, sending, fetch=()=>console.warn('no fetch provided') } = {...props}
  useEffect(()=>{
    if(data) fetch()
  },[data])
  return <Fragment>
    {sending && <div>Updating data...</div>}
    {children}
  </Fragment>
}

export const HandleError = props => {
  const {message, status, duration=3000} = {...props}
  const [open, handleOpen, handleClose] = useMemoizedOpen()

  useEffect(()=>{
    if(calcError(status)) handleOpen()
  },[status])

  return <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
    <Alert onClose={handleClose} severity="error" variant="filled" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
}

export const HandleErrorV2 = props => {
  const {type, status, duration=null, persist=false} = {...props}
  const { t, i18n } = useTranslation();
  //const [open, handleOpen, handleClose] = useMemoizedOpen()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(()=>{
    if(calcError(status)) {
      //handleOpen()
      enqueueSnackbar(type, {
        //variant:'error',
        persist: persist,
        content:(key, _type) => (
          <Alert id={key} severity="error" variant='filled'>
            <AlertTitle><strong>{t('ERROR')}: </strong>{t(_type)}</AlertTitle>
            {t(`${_type}_DESCRIPTION`)} - {t(`${type}_MESSAGE`)}
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              //className={classes.close}
              onClick={()=>closeSnackbar(key)}
            >
              <CloseIcon 
                //className={classes.icon} 
              />
            </IconButton>
          </Alert>

        )
      })
    }
  },[status])
  return null
}
    
export const HandleErrorV3 = props => {
  const { type, status } = {...props}
  const { t, i18n } = useTranslation();

  return <Alert severity="error" variant='filled'>
    <AlertTitle><strong>{t('ERROR')}: </strong>{t(type)}</AlertTitle>
    {t(`${type}_DESCRIPTION`)} - {t(`${type}_MESSAGE`)}
  </Alert>
}

export const HandleSuccess = props => {
  const {message, status, duration=3000} = {...props}
  const [open, handleOpen, handleClose] = useMemoizedOpen()

  useEffect(()=>{
    if(calcError(status)) handleOpen()
  },[status])

  return <Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
    <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
      {message}
    </Alert>
  </Snackbar>
}
export const HandleSending = props => {
  const { watchData,sending=[], fetch=()=>console.warn('no fetch provided') } = {...props}
  //const classes = useStyles();

  const rSending = useMemo(()=>reduce(sending,(acc,cur)=>{
    if(calcFetching(cur)) acc=true
    return acc
  },false),[sending])
  return <Fragment>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={rSending}
      //onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </Fragment>
}

export const HandlePending = props => {
  const { children, pending=[] } = {...props}
  const rPending = useMemo(()=>reduce(pending,(acc,cur)=>{
    if(calcPending(cur)) acc=true
    return acc
  },false),[pending])
  return <Fragment>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={rPending}
      //onClick={handleClose}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
    {children}
  </Fragment>
}

export const HandleSendingAndPending = props => {
  const { t, i18n } = useTranslation();
  const { children, sending=[], pending=[] } = {...props}

  const rSending = useMemo(()=>reduce(sending,(acc,cur)=>{
    if(calcFetching(cur)) acc=true
    return acc
  },false),[sending])
  const rPending = useMemo(()=>reduce(pending,(acc,cur)=>{
    if(calcPending(cur)) acc=true
    return acc
  },false),[pending])
  return <Fragment>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={rSending||rPending}
      //onClick={handleClose}
    >
      {(rSending || rPending) && <CircularProgress color="inherit" />}
      {rSending &&<Typography>{t('SENDING')}</Typography>}
      {rPending &&<Typography>{t('PENDING')}</Typography>}
    </Backdrop>
    {children}
  </Fragment>
}

/*
export const HandleSendingAndPending = props => {
  const { children, sending=[], pending=[] } = {...props}
  const rSending = useMemo(()=>reduce(sending,(acc,cur)=>{
    if(cur==='FETCHING') acc=true
    return acc
  },false),[sending])
  const rPending = useMemo(()=>reduce(pending,(acc,cur)=>{
    if(cur==='PENDING') acc=true
    return acc
  },false),[pending])
  return <Fragment>
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1 }}
      open={rSending||rPending}
      //onClick={handleClose}
    >
      <CircularProgress color="inherit" />
      <Typography>{rSending ? 'Sending' : 'Pending'}</Typography>
    </Backdrop>
    {children}
  </Fragment>
}

*/