import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';
import reduce from "lodash/reduce"
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

//import { investorTableSchema} from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"
import Joi from "joi";
import { ethAddress, privateKey } from "utilities/joi/sto"

export const useRemoveFromWhitelist = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_REMOVE_FROM_WHITELIST_SIGN'
  const [data, error, status] = useThunkDataV3([action])

  const remove = formdata => {
    const { private_key, walletAddresses } = {...formdata}
    const _walletAddresses = Array.isArray(walletAddresses) ? walletAddresses : [walletAddresses]

    dispatch({
      type: action, 
      payload: { 
        tokenAddress, 
        addresses: _walletAddresses, 
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [ data, error, status, remove, unmount]
}


export const useRemoveFromWhitelistFormV2 = () => {
  const { 
    register, handleSubmit, watch, 
    formState: {errors, isValid, touchedFields}, 
    resetField
  } = useForm({
    resolver: joiResolver(Joi.object({
      walletAddresses: Joi.array().items(ethAddress(false)).single().unique('address').messages({
        "array.unique":  "MUST_BE_UNIQUE"
      }),
      private_key: privateKey,
    }))
  });

  const walletAddresses = watch("walletAddresses", [])
  
  return [ 
    [register('private_key'), register(`walletAddresses`)], 
    handleSubmit, 
    {
      errors: {
        private_key: get(errors,['private_key','message'], null),
        walletAddresses: get(errors,['walletAddresses','message'], null),        
      },
      isValid,
      touchedFields,
      fields : {
        walletAddresses: walletAddresses ? (Array.isArray(walletAddresses) ? walletAddresses: [walletAddresses] ) : []
      }
    },
    ()=> {
      resetField('private_key')
      resetField('walletAddresses')
    }
  ]
}