import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm } from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUserSchema } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"



/*
export const useUploadCsv = ()=> {
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3(['ETH_STO_IMPORT_USER_CSV'])

  const submit = (formData) => {
    const {file} = {...formData}
    dispatch({type: 'ETH_STO_IMPORT_USER_CSV', payload: { 
      file: file
    }})
  }
  const unmount = createUnmountDispatch(dispatch, 'ETH_STO_IMPORT_USER_CSV')
  return [data, error, status, submit, unmount]
}

export const useUploadCsvForm = ()=> {
  const { register, handleSubmit } = useForm();

  return [register('file'), handleSubmit]
}
*/

export const useImportUserCsvForm = () => {
  const { register, handleSubmit, formState:{ errors }, getValues } = useForm({
    //resolver: joiResolver(createUserSchema),
  });
  return [[register('file')], handleSubmit, errors, getValues]
}

export const useImportUserCsv = (import_admins=false)=> {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_IMPORT_USER_CSV'
  const [data, error, status] = useThunkDataV3([action])

  const submit = (formData) => {
    const {file} = {...formData}
    dispatch({type: action, payload: { 
      file: file,
      import_admins
    }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, submit, unmount]
}