import { transformState, spreadState, pushState, omit, popState } from "saga/transforms"
import get from 'lodash/get'

export default function systemReducer(state = {connected:true}, action) {

  const { type, payload } = {...action}

  const reducers = {
    default: () => state,
    'SIDE_OPEN': (state, payload) => {
      return transformState(state, ['side_bar', 'open'], true)
    },
    'SIDE_CLOSE': (state, payload) => {
      return transformState(state, ['side_bar','open'], false)
    },
    'MODAL_OPEN': (state, payload) => {
      const { type, props } = payload
      return transformState(state, ['modal'], {
        open: true,
        type: type,
        props: props
      })
    },
    'MODAL_CLOSE': (state, payload) => {
      return transformState(state, ['modal'], {
        open: false,
        type: null,
        props: null
      })
    },
    'MODAL_STACK_PUSH': (state, payload) => {
      const { type, props } = {...payload}
      const current_stack = get(state,['modal','stack'],[])
      const current_props = get(state,['modal','props'],{})
      return transformState(state, ['modal'],{
        stack: [...current_stack, type],
        props: {...current_props, ...props}
      })
    },
    'MODAL_STACK_PUSH_GROUP': (state, payload) => {
      const { group, props } = {...payload}
      const current_stack = get(state,['modal','stack'],[])
      const current_props = get(state,['modal','props'],{})
      return transformState(state, ['modal'],{
        stack: [...current_stack, ...group],
        props: {...current_props, ...props}
      })
    },
    'MODAL_STACK_POP': (state, payload) => {
      const current_stack = get(state,['modal','stack'],[])
      if(current_stack.length === 1) {
        return transformState(state, ['modal'], {
          stack: [],
          props: {}
        })
      }
      return popState(state, ['modal','stack'])
    },
    'MODAL_ADD_SHARED': (state, payload) => {
      //const { shared } = payload
      const current = get(state,['modal','shared'],{})
      return transformState(state, ['modal', 'shared'],{
        ...current,
        ...payload
      })   
    },
    'MODAL_ADD_PROPS': (state, payload) => {
      //const { shared } = payload
      const current = get(state,['modal','props'],{})
      return transformState(state, ['modal', 'props'],{
        ...current,
        ...payload
      })   
    },
    'MODAL_CLEAR_PROPS': (state, payload) => {
      //const { shared } = payload
      const current = get(state,['modal','props'],{})
      return transformState(state, ['modal', 'props'],{})   
    },
    'MODAL_STACK_CLEAN': (state, payload) => {
      return transformState(state, ['modal'], {
        stack: [],
        props: {}
      })
    },
    'ALERT_SUCCESS': (state, payload) => {
      const message = payload
      return transformState(state, ['alert', 'success'], message)
    },
    'ALERT_ERROR': (state, payload) => {
      const message = payload
      return transformState(state, ['alert', 'error'], message)
    },
    'ALERT_CLEAN': (state, payload) => {
      return transformState(state, ['alert'], {
        success: null,
        error: null
      })
    },
    'NAV_TITLE': (state, payload) => {
      const title = payload
      return transformState(state, ['nav', 'title'], title)
    },
    'NAV_CLEAN': (state, payload) => {
      return transformState(state, ['nav', 'title'], null)
    },
    'BACKGROUND': (state, payload) => {
      const color = payload
      return transformState(state, ['background', 'color'], color)
    },
    'DISCONN': (state, payload) => {
      return transformState(state, ['connected'], false)
    },
    'CONN': (state, payload) => {
      return transformState(state, ['connected'], true)
    },
    'DEMO_INFO': (state, payload) => {
      const json = payload
      return transformState(state, ['demo_info'], json)
    },
    'UPDATE_STATUS': (state, payload) => {
      const status = payload.status || 'NOT_AUTHENTICATED'
      return transformState(state, ['status'], status)
    },
    'POPUP_CLEAN': (state, payload) => {
      return transformState(state, ['popup'], {
        type: null,
        props: null
      })
    },
    'POPUP_PUSH': (state, payload) => {
      const { type, props } = {...payload}
      return transformState(state, ['popup'],{
        type: type,
        props: {...props}
      })
    }
  }
  const reducer = reducers[type] || reducers['default']
  return reducer(state, payload)  
}