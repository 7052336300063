import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext
} from 'redux-saga/effects'

import { 
  createSuccess, createFailed, createPending,
  createFetching, createMeta, createClean
} from 'saga/helpers'


export function* putSuccessV2(name, payload, options) {
  const { subPath } = {...options}
  yield put({
    type: createSuccess(name),
    payload:  payload,
    meta: {
      name, 
      subPath,
      status: 'DATA'
    }
  })       
}


export function* putFailedV2(name, message, options) {
  const { subPath } = {...options}
  yield put({
    type: createFailed(name),
    payload: message,
    meta: {
      name,
      subPath,
      status: 'ERROR', 
    }
  })     
}

export function* putFetchingV2(name, options) {
  const { subPath } = {...options}
  yield put({
    type: createFetching(name),
    meta: {
      name,
      subPath,
      status: 'FETCHING'
    }
  })     
}

export function* putCleanV2(name, options) {
  const { subPath } = {...options}
  yield put({
    type: createClean(name),
    meta: {
      name,
      subPath,
      status: 'CLEAN'
    }
  })     
}

export function* putPendingV2(name, options) {
  const { address, subPath} = {...options}
  yield put({
    type: createPending(name),
    meta: {
      name,
      subPath,
      address: address ? address : null,
      status: 'PENDING'
    }
  })     
}