import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import Joi from "joi";
import Decimal from 'decimal.js';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Container from '@mui/material/Container';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { 
  useGetPause, usePause, usePauseForm,
  useUnpause, useUnpauseForm
} from "./use"

import { useGetInfo } from "components/organisms/ETH/sto/StoInfo/use"
import { PKButtonV2,PKButton } from 'components/molecules/PKButton'
import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { joiValidate, joiValidateV2, ethAddress } from "utilities/joi/sto"
import { selectErrorSubtype } from "utilities/selectors"


const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
    <Typography>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}

const PauseComp = props => {
  const { tokenAddress, networkId, handleSuccess=()=>null} = {...props}
  const { t, i18n } = useTranslation();
  const [ 
    [pausePKReg], 
    handleSumbit, 
    {errors:{private_key: pauseError}, isValid: pauseValid, touchedFields:{private_key: pausedTouched}}, 
    pauseResetPK
  ] = usePauseForm()  
  const [ data, error, status, submit,unmount ] = usePause(
    networkId,
    tokenAddress, 
  ) 
  useUnmount([unmount])
  useRefreshOnSuccess(status, handleSuccess)
  return <Fragment>
    <HandleSendingAndPending sending={[status]} pending={[status]}/>
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
    <PKButton 
      PKReg={pausePKReg} 
      handleSend={handleSumbit(submit)}
      title={'PAUSE'}
      subheader={'PAUSE_DESCRIPTION'}
      //touched={PKTouched}
      //errText={t(PKError)}
    />
  </Fragment>
}

const UnpauseComp = props => {
  const { tokenAddress, networkId, handleSuccess=()=>null} = {...props}
  const { t, i18n } = useTranslation();
  const [ 
    [PKReg], 
    handleSumbit, 
    {errors:{private_key: PKError}, isValid, touchedFields:{private_key: pausedTouched}}, 
    pauseResetPK
  ] = useUnpauseForm()  
  const [ data, error, status, submit, unmount ] = useUnpause(
    networkId,
    tokenAddress, 
  ) 
  useUnmount([unmount])
  useRefreshOnSuccess(status, handleSuccess)
  return <Fragment>
    <HandleSendingAndPending sending={[status]} pending={[status]}/>
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
    <PKButton 
      PKReg={PKReg} 
      handleSend={handleSumbit(submit)}
      title={t('UNPAUSE_TITLE')}
      subheader={t('UNPAUSE_DESCRIPTION')}
      //touched={PKTouched}
      //errText={t(PKError)}
    />
  </Fragment>
}
const dataSchema = {
  'IS_PAUSED': Joi.boolean().required(),
  'PAUSER_ROLE': Joi.array().items(
    Joi.array().items(ethAddress(false)).unique().required(),
    Joi.object().required(),
  ).required()
}

export const Pause = props => {
  const { tokenAddress, networkId, handleSuccess=()=>null} = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetInfo, networkId, tokenAddress, 
    ['IS_PAUSED','PAUSER_ROLE']
  )
  const isPaused = data ? data['IS_PAUSED'] : false

  useUnmount([unmount])

  const _handleSuccess = () => {
    handleSuccess()
    fetch()
  }

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>

  return <ValidateData data={data} schema={dataSchema}>
    <Container maxWidth="md">
      <Card>
        <CardHeader
          subheader={t("PAUSE_DESCRIPTION")}
          title={t("PAUSE")}
        />
        <Divider />
        <CardContent>
          <Info text={t('PAUSER_ROLE')} subtext={JSON.stringify(get(data,['PAUSER_ROLE', 0]), null)} />
          <Info text={t('PAUSED')} subtext={isPaused?t('TRUE'):t('FALSE')} />
        </CardContent>
      </Card>
      <Box p={1}/>
      { isPaused ? 
        <UnpauseComp tokenAddress={tokenAddress} networkId={networkId} handleSuccess={_handleSuccess}/>
        :
        <PauseComp tokenAddress={tokenAddress} networkId={networkId} handleSuccess={_handleSuccess}/>
      }

    </Container>
  </ValidateData>
}

const ValidateData = (props) => {
  const { data, schema, children } = {...props}
  const [value, error] = joiValidateV2(data,schema)
  if(error) return <pre>{JSON.stringify(error,0,2)}</pre>
  return children
}