import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

import axios from 'axios'

import { cancellableAsync } from 'saga/helpers'

import { 
  wwLoginUser, wwLogoutUser, wwGetServerRoles, wwManageRoles
} from './wrapped'

const async_map = {
  'AUTH_LOGIN': wwLoginUser,
  'AUTH_LOGOUT': wwLogoutUser,
  'AUTH_GET_SERVER_ROLES': wwGetServerRoles,
  'AUTH_MANAGE_ROLES':wwManageRoles
}

function* authWatchAsync() {
  while(true){
    const { type, payload, meta } = yield take(Object.keys(async_map))
    yield fork(cancellableAsync, async_map[type], type, payload, meta)
  }     
}

/**************
// ROOT
//
****************/
export function* AuthRootSaga(backend_url) {
  const backEnd = axios.create({
    baseURL: backend_url
  })
  yield setContext({ backEnd })

  yield all([
    authWatchAsync(),
  ])
}
