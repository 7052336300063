import { transformState, spreadState, pushState, omit } from "saga/transforms"

const init_state = {}

/*
R_ETH_POLYMATH_UPDATE_TM: () =>  {
  const  { tokenAddress, transferManagerAddress, permissionManagerAddress } = {...payload}
  return transformState(state, ['polymath', tokenAddress ], {
    transferManager: transferManagerAddress, permissionManager: permissionManagerAddress
  })
}
*/

export const wltokenReducers = (state = init_state, action) => {
  const { type, payload={} } = {...action}
  //payload should always be an object
  const reducers = {
    default: () => state,
    R_ETH_WLTOKEN_UPDATE_CONFIG: () =>  {
      const  { networkId, config } = {...payload}
      return transformState(state, ['config', networkId ], config)
    },
    R_ETH_WLTOKEN_STORE_ROLES: () =>  {
      const  { roles } = {...payload}
      return transformState(state, ['roles'], roles)
    }
  }
  const reducer = reducers[type] || reducers['default']
  return reducer()
}

