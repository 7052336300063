import { transformState, spreadState, pushState, omit } from "saga/transforms"
//import config from 'setup/appConfig'

//import get from 'lodash/get'

const init_state = {}

export const auth_reducers = (state = init_state, action) => {
  const { type, payload={} } = {...action}
  //payload should always be an object
  const reducers = {
    default: () => state,
    STORE_ACCESS_TOKEN: () =>  {
      const  { access_token } = {...payload}
      return transformState(state, ['access_token'], access_token)
    },
    STORE_ROLES: () =>  {
      const  { roles } = {...payload}
      return transformState(state, ['roles'], roles)
    },
    REMOVE_ACCESS_TOKEN: (state, payload) =>{
      return transformState(state, ['access_token'], null)
    }
  }
  const reducer = reducers[type] || reducers['default']
  return reducer()
}

const _auth_reducers = {
  ['CLEAR_AUTH']: (state, payload) =>
    Object.assign({}, state, {
      access_token: null,
      refresh_token: null,
      user_name: null
    }),
  ['STORE_TOKENS']: (state, payload) =>
    Object.assign({}, state, {
      access_token: payload.access_token,
      refresh_token: payload.refresh_token,
      user_name: payload.userName,
      id: payload.id
    }),
  ['STORE_ACCESS_TOKEN']: (state, payload) =>
    Object.assign({}, state, {
      access_token: payload.access_token,
    }),
  ['REMOVE_ACCESS_TOKEN']: (state, payload) =>
    Object.assign({}, state, {
      access_token: null,
    }),
  ['STORE_ROLES']: (state, payload) =>
    Object.assign({}, state, {
      roles: payload.roles,
    }),
  /*
  [STORE_TOKEN]: (state, payload) =>
    Object.assign({}, state, {
      token: payload.token,
      userName: payload.userName
    }),

  [LOGOUT_USER]: (state) =>
    Object.assign({}, state, {
      token: null,
      userName: null,
    }),
  */
}

export default auth_reducers

//const initialState = get(config, 'initial_state.auth', {})

//export default reducers['auth']
//export default createReducer({}, reducers['auth']);