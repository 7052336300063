import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Joi from "joi";
import Decimal from 'decimal.js';
import { useTable, useFilters, usePagination } from 'react-table'

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { 
  useGetInvestors
} from "./use"

import { ValidateData } from 'components/molecules/Validate'
import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
import { WalletList, EmailCell } from 'components/molecules/TableCells'
import { PKButton } from 'components/molecules/PKButton'
import { HandleErrorV3 } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { calcFetching, calcError } from "utilities/misc"

import { selectErrorSubtype } from "utilities/selectors"



const useInvestorColumn = () => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('INVESTOR_ID'),
        accessor: 'investor_id',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('FIRST_NAME'),
        accessor: 'first_name',
      },
      {
        Header:  t('LAST_NAME'),
        accessor: 'last_name',
      },

      {
        Header:  t('EMAIL'),
        accessor: 'email',
      },
      {
        Header:  t('WALLET_ADDRESS'),
        accessor: 'address',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('BALANCE'),
        accessor: 'balance',
         Cell: ({value})=>{ 
         const decimals = 18  
         const amount = Decimal(value).mul(Decimal(10).pow(-decimals)).toString()
         return amount
        }
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('RATIO'),
        accessor: 'ratio',
        Cell: ({value})=>{ 
          const ratio = value ? Decimal(value).mul(100).toFixed(2).toString() : 0
          return `${ratio}%`
        }
      },
      {
        Header:  t('IS_WHITELISTED'),
        accessor: 'isWhitelisted',
        Cell: item => item.value ? t('TRUE'):t('FALSE')
      }
    ]
  ,[i18n.language])
  return columns  
}


const dataSchema = {
  'investors': Joi.array().items(
    Joi.object({
      investor_id: Joi.number().required(),
      email: Joi.string().required(),
      address: Joi.string().required(),
      balance: Joi.number().unsafe().required(),
      isWhitelisted: Joi.boolean().required(),
    }).required()
  ).required(),
}


export const InvestorTable = props => {
  const { tokenAddress, handleGoToFund, networkId, handleGoToNewAddress } = {...props}
  const { t, i18n } = useTranslation();

  const [
    data, error, status, fetch, unmount
  ] = useFetchOnMountV3(useGetInvestors, networkId, tokenAddress)


  const columns = useInvestorColumn()

  useUnmount([ unmount ])

  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING')}</Fragment>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>

  const {SUPPLY} = {...data['stoInfo']}
  const parsed = data['investors'].map(investor=>{
    investor['ratio'] = investor['balance'] ? Decimal(investor['balance']).dividedBy(Decimal(SUPPLY)).toString() : '0'
    return investor
  })
  
  return <Fragment>
    <PaginationTable columns={columns} data={parsed} />
  </Fragment>
  return <ValidateData data={data} schema={dataSchema}>
    <PaginationTable columns={columns} data={data['investors']} />
  </ValidateData>

}

/*
const Toolbar = props => {
  const { t, i18n } = useTranslation();
  const { 
    handleGoToNewAddress=()=>console.warn('handleGoToNewAddress is null')
  } = {...props}
  return <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      m: -1
    }}
  >
    <Typography
      sx={{ m: 1 }}
      variant="h4"
    >
      {t('INVESTORS')}
    </Typography>
    <Box sx={{ m: 1 }}>
      
    </Box>
  </Box>
}
*/
/*
const _WalletList = props => {
            const { address, balance, isWhitelisted }= {...props}
          const _label = !isWhitelisted ? t('ADD') : t('REMOVE')
          const _reg = !isWhitelisted ? addReg : removeReg
          WalletLis
           return <Fragment>
            <Box sx={{ flexDirection: 'row', display: 'flex', mb:2}}>
              <Box> 
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                  <Box sx={{ marginRight: 1, mb:1}}>
                    <Typography color='gray'>{t('WALLET')}:</Typography> 
                  </Box>
                  <Box>
                    <Link target="_blank" href={`https://kovan.etherscan.io/address/${address}`} color="inherit">
                      {address}
                    </Link>
                  </Box>
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex'}}>
                  <Box>
                    <Typography sx={{ marginRight: 1}} color='gray'>{t('BALANCE')}:</Typography>
                  </Box>
                  <Box>
                    <Typography>{Decimal(balance).mul(Decimal(10).pow(-18)).toDecimalPlaces(4).toString()}</Typography>
                  </Box>
                </Box>
                <Box sx={{ flexDirection: 'row', display: 'flex' , alignItems: 'center'}}>
                  
                  <Typography sx={{ marginRight: 1}} color='gray'>{t('WHITELISTED')}:</Typography>
                  <Typography>{isWhitelisted? t('YES') : t('NO')}</Typography>
                  <FormControlLabel
                    control={<Checkbox size="small"/>}
                    value={address}
                    //key={item['id']}
                    label={<Typography fontSize={12} color='gray'>{_label}</Typography>}
                    sx={{ marginLeft: 3 }}
                    {..._reg}
                  /> 
                </Box>             
              </Box> 
              { row.row.original['investor_id']==='-' ?
                null
                :
                <Button 
                  variant="outlined" 
                  sx={{ marginLeft: 2 }}
                  onClick={()=>handleGoToFund(tokenAddress, address)}
                >{t('CREATE_FUND')}</Button>
              }
              
            </Box> 
        </Fragment>
}
*/

/*
function _WalletItem(props) {
  const { address='', balance, isWhitelisted } = {...props};

  return (
    <Stack direction="row" alignItems="center" spacing={2}>


      <Box sx={{ minWidth: 240 }}>
        <Typography color="textPrimary" variant="h6" noWrap>
          {Decimal(balance).mul(Decimal(10).pow(-18)).toDecimalPlaces(4).toString()}
        </Typography>
        
        <Typography color="textSecondary" variant="caption"  noWrap>
          {address}
        </Typography>
      </Box>
      <Chip size="small" label={isWhitelisted ? 'whitelisted' : 'not whitelist'} color={isWhitelisted ? 'primary' : 'red'} />

    </Stack>
  );
}
*/