import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import Joi from "joi";

import { joiValidateV2 } from "utilities/joi/sto"

export const ValidateData = (props) => {
  const { data, schema, children } = {...props}
  const [value, error] = joiValidateV2(data,schema)
  if(error) return <pre>{JSON.stringify(error,0,2)}</pre>
  return children
}