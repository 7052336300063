import React, { useState, useEffect, Fragment, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Controller } from "react-hook-form";
import { useSnackbar  } from 'notistack';

import get from "lodash/get"
import reduce from "lodash/reduce"

import Container from '@mui/material/Container';

import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import { useGetServerRoles, useManage, useManageForm, } from "./use"

//import { useGetUsers } from "components/organisms/ETH/sto/UserTable/use"

import { Toolbar } from 'components/molecules/Toolbar'
import { PaginationTable } from 'components/molecules/TableBase'
import { PKButton } from 'components/molecules/PKButton'
import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"
import { useErrorV2 } from 'components/useHooks/useStatus'

const switches = [
  {
    formName: "roles.STO_ROLE",
    label: "STO_ROLE"
  },
  {
    formName: "roles.USER_ROLE",
    label: "USER_ROLE"
  },
  {
    formName: "roles.EMAIL_ROLE",
    label: "EMAIL_ROLE"
  },

]

/*
  {
    formName: "roles.AUTH_ROLE",
    label: "AUTH_ROLE"
  },
  {
    formName: "roles.FUND_ROLE",
    label: "FUND_ROLE"
  },
  {
    formName: "roles.OPERATION_ROLE",
    label: "OPERATION_ROLE"
  }, 
*/
const RoleSwitches = props => {
  const { userId, roleData, onSuccess=()=>null} = {...props}
  const { t, i18n } = useTranslation();
  
  const [ regs, handleSumbit, { dirtyFields } , resetPK, control ] = useManageForm(roleData)
  const [ adminReg, pauserReg ] = regs
  const [ data, error, status, onSubmit, unmount ] = useManage(userId, dirtyFields)
  useRefreshOnSuccess(status, onSuccess)
  useUnmount([unmount])
  useErrorV2(status,error)
  return <Card>
    <HandleSendingAndPending sending={[status]} pending={[status]}/>
    {/*<HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>*/}
    <CardHeader
      subheader={t('ROLE_ASSIGNMENT_DESCRIPTION')}
      title={t('ROLE_ASSIGNMENT')}
    />
    <Divider />
    <CardContent>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{t('ASSIGN_ROLE')}</FormLabel>
        <FormGroup>

          {switches.map(item=>
            <FormControlLabel control={          
              <Controller
                name={item['formName']}
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={get(field,['value'], false)}
                  />
                )}
              />} 
              label={t(item['label'])} 
            />
          )}
    

        </FormGroup>

        {/*<FormHelperText>Be careful</FormHelperText>*/}
      </FormControl>
      <Divider />
      <CardActions sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        //p: 2
      }}>
        <Button 
          variant='contained' 
          onClick={handleSumbit(onSubmit)}
        >
          {t('UPDATE')}
        </Button>
      </CardActions>
    </CardContent>
  </Card>  
}

export function ManageServerRoles(props) {
  const { t, i18n } = useTranslation();
  const { userId } = {...props}
  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetServerRoles, 
    userId, 
  )
  const { enqueueSnackbar } = useSnackbar();

  useUnmount([unmount])
  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  const _roleData = reduce(get(data,['roles'],[]),(cur,nex)=> {
    cur[nex] = true
    return cur
  },{})
  const handleSuccess = () => {
    fetch()
    enqueueSnackbar(t('ROLES_UPDATED'), {variant:'success'})
  }
  
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return <Fragment>

    <RoleSwitches 
      roleData={_roleData} 
      userId={userId} 
      onSuccess={handleSuccess}
    />
  </Fragment>
}

/*
const useColumns = (routeManage) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('USER_ID'),
        accessor: 'id',
        Filter: SelectValueFilter,
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
        Filter: SelectValueFilter,
        Cell: item=><Button 
          variant='outlined'
          onClick={()=>routeManage(get(item,['row', 'original', 'id'], null))}
        >
          {item.value}
        </Button>
      },

    ]
  ,[i18n.language])
  return columns
}

const dataSchema = {
  'users': Joi.array().items(
    Joi.object({
      id: Joi.number().required(),
      email: Joi.string().required(),
      Wallet_list: Joi.array().items(ethAddress(false)).unique().required(),
    }).required()
  ).required(),
}

export const ServerRolesTable = props => {
  const { 
    routeManage=()=>console.warn('routeManage is null'),
    onSuccess=()=>null
  } = {...props}

  const { t, i18n } = useTranslation();
  const [data, error, status, fetch, unmount ] = useFetchOnMountV3(useGetUsers)

  useUnmount([unmount, unmount3])

  const columns = useColumns(routeManage)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return 
  <ValidateData data={data} schema={dataSchema}>
    <HandleSending sending={[status3]}/>
    <HandleErrorV2 status={status3} type={selectErrorSubtype(error)}/>
    <PaginationTable columns={columns} data={get(data, ['users'], [])} />
    
  </ValidateData>

}
*/