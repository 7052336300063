import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import { useSelector } from 'react-redux'

import Joi from "joi";
import Decimal from 'decimal.js';
import includes from 'lodash/includes';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import filter from 'lodash/filter';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditIcon from '@mui/icons-material/Edit';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InfoIcon from '@mui/icons-material/Info';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';

import { useTranslation } from 'react-i18next';


import { useGetUsers, useExportUserCsv } from "./use"
import { useNetworkV3 } from "components/organisms/ETH/metamask/network/use"
import { useUnlinkWallet } from "components/organisms/ETH/sto/UnlinkWallet/use"
import { ValidateData } from 'components/molecules/Validate'
import { Toolbar } from 'components/molecules/Toolbar'
import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
import { HandleErrorV3, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'

import { calcFetching, calcError } from "utilities/misc"
import { createTokenHref } from 'utilities/ETH'
import { ethAddress } from "utilities/joi/sto"
import { selectErrorSubtype, selectUserRoles } from "utilities/selectors"

import { RestrictUserRole } from 'components/organisms/Auth/Restrict' 

import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useErrorV2 } from 'components/useHooks/useStatus'

/*
function filterWallet(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    const hasAddress = reduce(rowValue, (cur,nex)=>{
      if(cur) return cur
        //console.warn(filterValue, )
      return includes(nex,filterValue)
    },false)
    return hasAddress
  })
}
*/

const filterColumnByRoles = (userRoles)=>(item)=>{
  const roles = item['requiresRole']
  if (!roles) return true
  else if (intersection(userRoles, roles).length > 0) {
    return true
  }  
  return false
 }


const useColumns = (routeUserInfo, routeRoles, routeUserWallet, routeBalances, routeTransfers) => {
  const { t, i18n } = useTranslation();
  const userRoles = useSelector(selectUserRoles)

  const columns = useMemo(
    () => filter([
      {
        Header: t('INVESTOR_ID'),
        accessor: 'id',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('FIRST_NAME'),
        accessor: 'first_name',
      },
      {
        Header:  t('LAST_NAME'),
        accessor: 'last_name',
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
      },
      {
        Header: t('MANAGE_ROLES'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeRoles(get(item,['row', 'original', 'id'], null))}
        >
          <SupervisorAccountIcon/>
        </IconButton>,
        requiresRole: ['AUTH_ROLE']
      },
      {
        Header: t('MANAGE_WALLETS'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeUserWallet(get(item,['row', 'original', 'id'], null))}
        >
          <AccountBalanceWalletIcon/>
        </IconButton>,
        requiresRole: ['USER_ROLE']
      },
      {
        Header: t('INFO'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeUserInfo(get(item,['row', 'original', 'id'], null))}
        >
          <InfoIcon/>
        </IconButton>
      },
      {
        Header: t('BALANCES'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeBalances(get(item,['row', 'original', 'id'], null))}
        >
          <AccountBalanceIcon/>
        </IconButton>
      },
      {
        Header: t('ISSUES'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeTransfers(get(item,['row', 'original', 'id'], null))}
        >
          <CompareArrowsIcon/>
        </IconButton>
      },

    ], filterColumnByRoles(userRoles))
  ,[i18n.language])
  return columns
}

const dataSchema = {
  'users': Joi.array().items(
    Joi.object({
      id: Joi.number().required(),
      email: Joi.string().required(),
      Wallet_list: Joi.array().items(ethAddress(false)).unique().required(),
    }).required()
  ).required(),
}

export const UserTable = props => {
  const { t, i18n } = useTranslation();
  const { 
    routeUserInfo=()=>console.warn('routeUserInfo is null'),
    routeUserWallet=()=>console.warn('routeUserWallet is null'),
    routeCreate=()=>console.warn('routeCreate is null'),
    routeImport=()=>null,
    routeRoles=()=>console.warn('routeRoles is null'), 
    routeBalances=()=>console.warn('routeBalances is null'), 
    routeTransfers=()=>console.warn('routeTransfers is null'),
    onSuccess=()=>null
  } = {...props}

  const [data, error, status, fetch, unmount ] = useFetchOnMountV3(useGetUsers)
  //const [data2, error2, status2, submit, unmount2] = useUnlinkWallet()
  const [data3, error3, status3, download, unmount3] = useExportUserCsv()

  useUnmount([unmount, unmount3])
  /*
  useRefreshOnSuccess(status2, ()=>{
    onSuccess()
    fetch()
  })
  */
  const columns = useColumns(
    routeUserInfo, routeRoles, routeUserWallet, routeBalances, routeTransfers
  )
  useErrorV2(status3, error3)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return <Container>
  {/*<ValidateData data={data} schema={dataSchema}>*/}
    <HandleSending sending={[status3]}/>
    {/*<HandleErrorV2 status={status3} type={selectErrorSubtype(error)}/>*/}
    {/*<RestrictUserRole roles={['USER_ROLE']}>*/}
      <Toolbar routes={[ 
        {
          routeFunc: download,
          text: t('EXPORT'),
          Icon: FileDownloadIcon,
          variant: 'contained'
        },
        {
          routeFunc: routeImport,
          text: t('IMPORT'),
          Icon: AddIcon,
          variant: 'contained'
        }
      ]}/>
    {/*</RestrictUserRole>*/}

    <Box p={1}/>
    <PaginationTable columns={columns} data={get(data, ['users'], [])} />
    
  {/*</ValidateData>*/}
  </Container>
}



const useColumns2 = (routeRoles, routeUserWallet, routeGeneratePassword) => {
  const { t, i18n } = useTranslation();
  const userRoles = useSelector(selectUserRoles)

  const columns = useMemo(
    () => filter([
      {
        Header: t('INVESTOR_ID'),
        accessor: 'id',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('FIRST_NAME'),
        accessor: 'first_name',
      },
      {
        Header:  t('LAST_NAME'),
        accessor: 'last_name',
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
      },
      {
        Header: t('MANAGE_ROLES'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeRoles(get(item,['row', 'original', 'id'], null))}
        >
          <SupervisorAccountIcon/>
        </IconButton>,
        requiresRole: ['USER_ROLE']
      },
      {
        Header: t('MANAGE_WALLETS'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeUserWallet(get(item,['row', 'original', 'id'], null))}
        >
          <AccountBalanceWalletIcon/>
        </IconButton>,
        requiresRole: ['USER_ROLE']
      },
      {
        Header: t('GENERATE_PASSWORD'),
        Cell: item=><IconButton 
          variant='outlined'
          onClick={()=>routeGeneratePassword(get(item,['row', 'original', 'email'], null))}
        >
          <AccountBalanceWalletIcon/>
        </IconButton>,
        requiresRole: ['USER_ROLE']
      }
    ], filterColumnByRoles(userRoles))
  ,[i18n.language])
  return columns
}


export const AdminTable = props => {
  const { t, i18n } = useTranslation();
  const { 
    routeUserWallet=()=>console.warn('routeUserWallet is null'),
    routeRoles=()=>console.warn('routeRoles is null'), 
    routeImport=()=>console.warn('routeRoles is null'),
    routeGeneratePassword=()=>console.warn('routeGeneratePassword is null'),
    onSuccess=()=>null
  } = {...props}

  const [data, error, status, fetch, unmount ] = useFetchOnMountV3(useGetUsers, true)

  useUnmount([unmount])

  const columns = useColumns2(routeRoles, routeUserWallet, routeGeneratePassword)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>
  return <Container>
    <Toolbar routes={[ 
      {
        routeFunc: routeImport,
        text: t('IMPORT'),
        Icon: AddIcon,
        variant: 'contained'
      }
    ]}/>
    <Box p={1}/>
    <PaginationTable columns={columns} data={get(data, ['users'], [])} />
  </Container>
}