import React, { 
  useState, useEffect, Fragment 
} from 'react';
import { useSelector } from 'react-redux'
import get from "lodash/get"
import { GoogleLogin } from 'react-google-login';
import { useTranslation } from 'react-i18next';

import { 
  Box, Button, Container, Grid, 
  Link, TextField, Typography,
  FormControl, FormControlLabel
} from '@mui/material';

//import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useLogout } from "./use"
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useUnmount } from 'components/useHooks/useUnmount'


export const Logout = props => {
  const { handleSuccess } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(useLogout)
  useRefreshOnSuccess(status,handleSuccess)
  useUnmount([unmount])
  return <Fragment>
    <Container maxWidth="sm">
      Loggin Out
    </Container>
  </Fragment>
};