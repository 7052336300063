import Web3 from "web3";
import get from "lodash/get"

export const writeERC20Name = (sym) => `${sym}Token`

export const createThunkName = (contractName, method, methodArgs=[]) => {
  const _string = methodArgs.reduce((acc,cur,ind)=>{
    return `${acc}.${cur['thunkName']}`
  },`${contractName}.${method}`)
  return _string
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWCommasWFixed(x, fixedPoint=0) {
  const _x = parseFloat(x).toFixed(fixedPoint)
  return _x.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export function parseFromWei (value, fromWei=null, withCommas=false, fixedPoint=0) {
  if(!value) return '0'
  let _value = parseInt(value)
  if(typeof _value === 'number') _value =  BigInt(_value).toString()
  if(fromWei) _value = Web3.utils.fromWei(_value, fromWei)
  //if(withCommas) _value = numberWithCommas(_value)  
  if(withCommas) _value = numberWCommasWFixed(_value, fixedPoint)
  return _value
}

export const parseToWei = (_value, wei) => {
  let value = parseFloat(_value)
  if(!value) return '0'
  value = value.toLocaleString('fullwide', {useGrouping:false})

  if(wei) value = Web3.utils.toWei(value, wei)
  return value
}

export const createUnmountDispatch = (dispatch, type) => {
  const unmount = () => {
    dispatch({
      type: `${type}_UNMOUNT`, 
      meta: {name: type, status:'CLEAN' }
    })
  }
  return unmount
}

export const createTokenHref = (networkId, tokenAddress) => {
  const dict = {
    1: 'https://etherscan.io/address/',
    42: 'https://kovan.etherscan.io/address/',
    3: 'https://ropsten.etherscan.io/address/',
    5777: 'https://ganache.has.no.explorer/address/',
    80001: 'https://mumbai.polygonscan.com/address/',
    137: 'https://polygonscan.com/address/'
  }
  const _href = get(dict, networkId, 'https://unknown.network.id/address/') + tokenAddress
  return _href
}

export const createTxHashHref = (networkId, txHash) => {
  const dict = {
    1: 'https://etherscan.io/tx/',
    42: 'https://kovan.etherscan.io/tx/',
    3: 'https://ropsten.etherscan.io/tx/',
    5777: 'https://ganache.has.no.explorer/tx/',
    80001: 'https://mumbai.polygonscan.com/tx/',
    137: 'https://polygonscan.com/tx/'
  }
  const _href = get(dict, networkId, 'https://unknown.network.id/tx/') + txHash
  return _href
}