import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Decimal from 'decimal.js';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { LinkWallet } from 'components/organisms/ETH/sto/LinkWallet'

import { 
  useGetUserInfo
} from "./use"
import { useUnlinkWallet } from "components/organisms/ETH/sto/UnlinkWallet/use"

import { Jdenticon } from 'components/molecules/Jdenticon'
import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess} from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"
import { useErrorV2 } from 'components/useHooks/useStatus'

const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-start' }}>
    <Typography>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}

const WalletList = props => {
  const { userId, wallets, unlink } = {...props}
  const { t, i18n } = useTranslation();
  return <List dense={true}>
              
    {wallets.map(address=><Fragment>
      <ListItem
        
        secondaryAction={
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon onClick={()=>unlink(userId, address)}/>
          </IconButton>
        }
        
        alignItems="center" 
      >
        {/*
        <ListItemAvatar>
          <Jdenticon value={address} size={40}/>
        </ListItemAvatar>
        */}
        <ListItemText
          primary={address}
          //secondary={secondary ? 'Secondary text' : null}
        />

      </ListItem>
    </Fragment>)} 
    {/*
    <ListItem>
      <Button 
        variant='outlined' 
        size='small' 
        sx={{ml:'auto'}} 
        onClick={()=>handleRouteToLinkWallet(userId)}
      >
        {t('LINK_WALLET')}
      </Button>
    </ListItem>
    */}
  </List>
}


export const UserWalletInfo = props => {
  const { userId } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetUserInfo, userId,
    ['ID', 'EMAIL', 'WALLETS']
  )
  const [data2, error2, status2, unlink, unmount2] = useUnlinkWallet()

  useUnmount([unmount, unmount2])
  useRefreshOnSuccess(status2, fetch)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  const { ID, WALLETS, EMAIL} = {...data}
  return <Fragment>
    <Container maxWidth={false}>
      <HandleSendingAndPending sending={[status2]}/>
      <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
      <HandleErrorV2 status={status2} type={selectErrorSubtype(error2)}/>
      <Card>
        <CardContent>
          <Info text={t("USER_ID")} subtext={ID}/>
          <Info text={t("EMAIL")} subtext={EMAIL}/>
        </CardContent>
        <Divider/>
        <CardContent>
          <WalletList userId={userId} wallets={WALLETS} unlink={unlink}/>
        </CardContent>
      </Card>
    </Container>
    <Box p={2}/>
    <LinkWallet userId={userId} onSuccess={fetch}/>
  </Fragment>
}




export const UserInfoV2 = props => {
  const { userId } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetUserInfo, userId,
    ['ID', 'EMAIL', 'INFO']
  )
  const [data2, error2, status2, unlink, unmount2] = useUnlinkWallet()

  useUnmount([unmount, unmount2])
  useRefreshOnSuccess(status2, fetch)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  const { ID, EMAIL, INFO } = {...data}
  const {   
    email, first_name, last_name, phone_number, birthday,
    gender, address, postal_code, prefecture, country, created_on  
  } = {...INFO}
  return <Fragment>
    <Container maxWidth="md">
      <HandleSendingAndPending sending={[status2]}/>
      <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
      <HandleErrorV2 status={status2} type={selectErrorSubtype(error2)}/>
      <Card>
        <CardContent>
          <Info text={t("USER_ID")} subtext={ID}/>
          <Info text={t("EMAIL")} subtext={EMAIL}/>
        </CardContent>
          <Divider/>
        <CardContent>
          <Info text={t("FIRST_NAME")} subtext={first_name}/>
          <Info text={t("LAST_NAME")} subtext={last_name}/>
          <Info text={t("PHONE_NUMBER")} subtext={phone_number}/>
          <Info text={t("BIRTHDAY")} subtext={birthday}/>
          <Info text={t("GENDER")} subtext={gender}/>
          <Info text={t("POSTAL_ADDRESS")} subtext={address}/>
          <Info text={t("POSTAL_CODE")} subtext={postal_code}/>
          <Info text={t("PREFECTURE")} subtext={prefecture}/>
          <Info text={t("COUNTRY")} subtext={country}/>
          <Info text={t("CREATE_ON")} subtext={created_on}/>
        </CardContent>
      </Card>
    </Container>
  </Fragment>
}
