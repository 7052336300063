import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import './i18n';
import { Provider } from 'react-redux';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/lib/integration/react';

import App from './App';
import store from 'setup/storeConfig'

require('dotenv').config()
//import * as serviceWorker from './serviceWorker';








//import Loading from "screens/Auth/components/Loading"



const persistor = persistStore(store);


ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store= {store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Suspense>
  , 
  document.getElementById('root')
);
/*
ReactDOM.render(
  <Suspense fallback="loading">
    <App />
  </Suspense>
  , 
  document.getElementById('root')
)
*/
//PersistGate has a loading screen function "loading={<LoadingView />}"
/*
ReactDOM.render(
  <Provider store= {store}>
    <PersistGate 
      persistor={persistor}
      //loading={<Loading />}
      onBeforeLift = {null}
    >
      <App />
    </PersistGate>
  </Provider>, 
  document.getElementById('root')
);
*/
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register();