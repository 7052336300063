
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import Joi from "joi";
import { joiResolver } from '@hookform/resolvers/joi';
import reduce from "lodash/reduce"
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { ethAddress, privateKey } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"

export const useGetBalances= (networkId, userId) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_ALL_USER_BALANCES'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: { networkId, userId }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]

}
