import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"




export const useGetUserInfo = (user_id, types) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_USER_INFO'
  const [data, error, status] = useThunkDataV3([action])

  const fetch = () => {
    dispatch({type: action, payload: { userId: user_id, types}})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]  
}
