import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Decimal from 'decimal.js';
import reduce from 'lodash/reduce'
import get from 'lodash/get'

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
//import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import { PKButtonV2 } from 'components/molecules/PKButton'
import { FormControlLabel_ } from 'components/molecules/Forms'
import { useDeployStoForm, useDeploySto } from "./use"

import { HandleError, HandleErrorV2, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"

export const DeploySto = props => {
  const { t, i18n } = useTranslation();
  const { networkId, handleSuccess=()=>null } = {...props}

  const [ regs, handleSumbit, { errors, touchedFields } , resetPK ] = useDeployStoForm()
  const [ pkReg, nameReg, symbolReg, divisibleReg ] = regs
  const [
    data, error, status, onSubmit, unmount
  ] = useDeploySto(networkId)
 
  useUnmount([unmount])
  useRefreshOnSuccess(status, handleSuccess)

  return <Fragment>

    <HandleSendingAndPending sending={[status]} pending={[status]}/> 
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>  
    <Box p={2}/>
    <Container maxWidth={false}>
      <Box p={1}/>
      {/*<Toolbar 
        //handleOpen={handleOpen} 
        //disableAddToWhitelist={Object.keys(map).length===0} 
      />*/}
      <Card>
        <CardHeader
          subheader={t('DEPLOY_STO_DESCRIPTION')}
          title={t('DEPLOY_STO')}
        />
        <Divider />
        <CardContent>
          {/*<pre>{JSON.stringify({data, error, status},0,2)}</pre>*/}
          <FormControlLabel_ 
            //name={"private_key"} 
            label={t("PRIVATE_KEY")} 
            reg={pkReg}
            touched={touchedFields[pkReg['name']]}
            errText={t(errors[pkReg['name']])}
          />
          <FormControlLabel_ 
            //name={"private_key"} 
            label={t("STO_NAME")} 
            reg={nameReg}
            touched={touchedFields[nameReg['name']]}
            errText={t(errors[nameReg['name']])}
          />
          <FormControlLabel_ 
            //name={"private_key"} 
            label={t("STO_SYMBOL")} 
            reg={symbolReg}
            touched={touchedFields[symbolReg['name']]}
            errText={t(errors[symbolReg['name']])}
          />
          {/*
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">{t("DIVISIBLE")}</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={true}
              name="radio-buttons-group"
              disabled={true}
            >
              <FormControlLabel value={true} control={<Radio />} label={t("TRUE")}  {...divisibleReg}/>
              <FormControlLabel disabled={true} value={false} control={<Radio />} label={t("FALSE")} {...divisibleReg}/>
            </RadioGroup>
          </FormControl>
          */}
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button 
            variant='contained' 
            sx={{ float: "right"}} 
            onClick={handleSumbit(onSubmit)}
          >{t('DEPLOY_STO')}</Button>
        </Box>
      </Card>
    </Container>
  </Fragment>
  
}
/*
const Toolbar = props => {
  const { t, i18n } = useTranslation();
  const { 
    handleOpen, disableAddToWhitelist
  } = {...props}
  return <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      m: -1
    }}
  >
    <Typography
      sx={{ m: 1 }}
      variant="h4"
    >
      {t('DEPLOY_INFO')}
    </Typography>
    <Box sx={{ m: 1 }}>
     
  </Box>
}
*/