import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Decimal from 'decimal.js';
import reduce from 'lodash/reduce'
import get from 'lodash/get'

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
//import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import { PKButton } from 'components/molecules/PKButton'
import { useRemoveFromWhitelist,  useRemoveFromWhitelistFormV2} from "./use"

import { 
  useGetInfo
} from "components/organisms/ETH/sto/StoInfo/use"

import { useGetWallets } from "components/organisms/ETH/sto/CreateFund/use"


import { PaginationTable } from 'components/molecules/TableBase'
import { HandleErrorV2, HandleErrorV3, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"


const useColumns = (reg) =>  {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('SELECT'),
        Cell: item => <FormControlLabel 
          value={item.row.original['wallet']} 
          control={<Checkbox 
            //disabled={!item.row.original['isWhitelisted']}
          />} 
          label={""}  
          {...reg}
        />
      },
      {
        Header: t('INVESTOR_ID'),
        accessor: 'id',
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
        //Cell: EmailCell
      },
      {
        Header: t('WALLET'),
        accessor: 'wallet',
      },
      {
        Header: t('IS_WHITELISTED'),
        accessor: 'isWhitelisted',
        Cell: item => item.value ? t('TRUE'):t('FALSE')
      }
    ]
  ,[i18n.language])
  return columns
} 

export const RemoveWhitelist = props => {
  const { t, i18n } = useTranslation();
  const { 
    handleAddressClick=()=>console.warn('handleAddressClick is null'),
    handleRouteToLinkWallet=()=>console.warn('handleRouteToLinkWallet is null'),
    handleRouteToCreateUser=()=>console.warn('handleRouteToCreateUser is null'),
    networkId, 
    tokenAddress
  } = {...props}

  const [
    [PKReg, removeReg], 
    handleSumbit,     
    {
      errors: { private_key: PKError, walletAddresses: walletError },
      isValid,
      touchedFields:{ private_key: PKTouched },
      fields: { walletAddresses }      
    }, 
    resetForm
  ] = useRemoveFromWhitelistFormV2()

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(useGetWallets)  
  const [data3, error3, status3, fetch3, unmount3] = useFetchOnMountV3(
    useGetInfo, networkId, tokenAddress, ['WHITELIST_ROLE']
  )

  const col_data = data && data3 ? 
    reduce(data['wallets'], (cur,nex)=>{
      const wallet = nex['wallet']
      const whiteListDict = data3['WHITELIST_ROLE'][1]
      nex['isWhitelisted'] = get(whiteListDict, [wallet], false)
      if(nex['isWhitelisted']){
        cur.push(nex)
      }
      
      return cur
    }, []) 
    :
    []

  const [
    data2, error2, status2, onSubmit, unmount2
  ] = useRemoveFromWhitelist(networkId, tokenAddress)

  const columns = useColumns(removeReg)

  useUnmount([unmount, unmount2, unmount3])
  useRefreshOnSuccess(status2, ()=>{
    resetForm()
    fetch()
    fetch3()
  })
  useRefreshOnSuccess(status2, fetch3) 
  
  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING')}</Fragment>
  if(calcFetching(status3)) return <Fragment><LinearProgress/>{t('LOADING')}</Fragment>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>
  if(calcError(status3)) return <HandleErrorV3 type={selectErrorSubtype(error3)}/>

  return <Fragment>
    <PKButton 
      PKReg={PKReg} 
      handleSend={handleSumbit(onSubmit)}
      disabled={walletAddresses.length==0}
      title={'REMOVE_FROM_WHITELIST'}
      subheader={'REMOVE_FROM_WHITELIST_DESCRIPTION'}
      touched={PKTouched}
      errText={t(PKError)}
    >
      { walletAddresses.length>0 ?

        walletAddresses.map(address=>    <Grid
          item
          //xs={12}
          //md={6}
        ><Typography>{address}</Typography></Grid>)
       :
       <Grid
          item
          //xs={12}
          //md={6}
        >{t('PLEASE_SELECT_WALLET_ADDRESS')}</Grid>
      }
    </PKButton>
    <Box p={2}/>
    <PaginationTable columns={columns} data={col_data} />
    <HandleSendingAndPending sending={[status2]} pending={[status2]}/>
    <HandleErrorV2 status={status2} type={selectErrorSubtype(error2)}/>
  </Fragment>
  
}
