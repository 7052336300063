import React from 'react';
import { useDispatch } from 'react-redux'

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { createUnmountDispatch } from "utilities/ETH"




export const useLogout= () => {
  const dispatch = useDispatch()
  const action = 'AUTH_LOGOUT'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: {  }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]

}
