import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Decimal from 'decimal.js';
import get from "lodash/get";

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import Container from '@mui/material/Container';

import { useTranslation } from 'react-i18next';

import { FormControlLabel_ } from 'components/molecules/Forms'

import { 
  useLinkWallet, useLinkWalletForm
} from "./use"

import { 
  HandleError, HandleSending, HandlePending, HandleRefresh, 
  HandleSendingAndPending ,HandleErrorV2
} from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useErrorV2 } from 'components/useHooks/useStatus'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"


export const LinkWallet = props => {
  const { t, i18n } = useTranslation();
  const { userId, onSuccess=()=>null } = {...props}

  const [data, error, status, onSubmit, unmount] = useLinkWallet(userId)
  const [[walletReg], handleSubmit, {errors, touchedFields}] = useLinkWalletForm()

  useUnmount([unmount])
  useRefreshOnSuccess(status, onSuccess)
  useErrorV2(status, error)
  //if(calcFetching(status)) return <div>Linking wallet</div>
  return <Container maxWidth={false}>
    <HandleSendingAndPending sending={[status]}/>
    {/*<HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>*/}

    <Card>
      <CardHeader
        subheader={t('LINK_WALLET_ADDRESS_DESCRIPTION')}
        title={t('LINK_WALLET_ADDRESS')}
      />
      <Divider />
      <CardContent>
        <Box sx={{mb:2}}>
          <FormControlLabel_ 
            label={t("WALLET_ADDRESS")} 
            reg={walletReg}
            touched={touchedFields[walletReg.name]}
            errText={
              !get(error, ['payload', 'isLinkOk'], true) ? 
              t('ADDRESS_UNAVAILABLE')
              : 
              t(errors[walletReg.name]) 
            }
          />
        </Box>
      </CardContent>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button variant='contained' onClick={handleSubmit(onSubmit)}>
          {t('SEND')}
        </Button>
      </Box>
    </Card>


  </Container>
}

export const _LinkWallet = props => {
  const { t, i18n } = useTranslation();
  const { userId } = {...props}
  const [data, error, status, onSubmit, unmount] = useLinkWallet(userId)
  const [walletReg, handleSubmit, errors] = useLinkWalletForm()
  useUnmount([unmount])


  return <Fragment>
    <FormControlLabel
      control={<TextField 
        //error={error.token_amount} 
        label={t('WALLET')} 
        //helperText={error.token_amount.message} 
      />}
      label={''}
      {...walletReg}
    /> 
    <HandleError status={status} message={JSON.stringify(error,0,2)}/>
    <Button variant='contained' onClick={handleSubmit(onSubmit)}>{t('SEND')}</Button>

  </Fragment> 
}


