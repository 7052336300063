import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Joi from "joi";
import get from "lodash/get";
import { useForm } from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { ethAddress } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"

const selectFormErrorType = (errorObj, name) => get(errorObj,[name, 'message'], null)

export const useLinkWallet = (userId)=> {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_LINK_WALLET'
  const [data, error, status] = useThunkDataV3([action])

  const submit = (formData) => {
    const { wallet_address } = {...formData}
    dispatch({type: action, payload: { 
      userId,  walletAddress: wallet_address,  
    }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, submit, unmount]
}

export const useLinkWalletForm = () => {
  const { register, handleSubmit, formState:{ errors, touchedFields } } = useForm({
    resolver: joiResolver(Joi.object({
      wallet_address: ethAddress()
    })),
  });
  return [
    [register('wallet_address')], 
    handleSubmit, 
    {
      errors: {
        wallet_address: selectFormErrorType('wallet_address')
      },
      touchedFields
    }
  ]
}