import logger from 'redux-logger'
import { persistReducer } from 'redux-persist';
import { createStore, applyMiddleware } from 'redux';

//import { middleware as thunkMiddleware } from 'redux-saga-thunk'

import createSagaMiddleware from 'redux-saga'
import { rootSaga, rootReducer } from 'saga/index.js';

import persistConfig from 'setup/persistConfig'

const pReducer = persistReducer(persistConfig, rootReducer);
const sagaMiddleware = createSagaMiddleware()

export default createStore(
  pReducer,
  //rootReducer,
  applyMiddleware(sagaMiddleware,logger)
);

sagaMiddleware.run(rootSaga)