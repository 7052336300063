import React, { 
  useState, useEffect, Fragment 
} from 'react';
import { useSelector } from 'react-redux'
import get from "lodash/get"
import { GoogleLogin } from 'react-google-login';
import { useTranslation } from 'react-i18next';

import { 
  Box, Button, Container, Grid, Card, CardContent,
  Link, TextField, Typography,
  FormControl, FormControlLabel
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useLogin, useLoginForm,  useLoginV2} from "./use"
import { useUnmount } from 'components/useHooks/useUnmount'
import { useError, useErrorV2, useErrorV3 } from 'components/useHooks/useStatus'
import { 
  HandleError, HandleErrorV2, HandleSending, HandlePending, 
  HandleRefresh, HandleSendingAndPending 
} from 'components/molecules/HandleStatus'

import { selectErrorSubtype } from "utilities/selectors"

const clientId = "1057648163572-j7kiklsqsjomtkrum12pm179hi0lk4is.apps.googleusercontent.com"

export const Login = props => {
  const { handleSending } = {...props}
  const { t, i18n } = useTranslation();
  const [googleStatus, setStatus] = useState(null)
  const [googleMess, setMessage] = useState(null)
  //const accessToken = useSelector(store=> get(store, ['auth', 'access_token'], null))
  const [
    data, error, status, submit, unmount
  ] = useLoginV2()

  const handleSuccess = resp => {
    submit(resp.code)
  }
  const handleFailure = resp => {
    setStatus('ERROR')
    setMessage(JSON.stringify(resp,0,2))
  }
  useUnmount([unmount])
  useErrorV2(status, error)
  useErrorV3(googleStatus, googleMess)
  //useRefreshOnSuccess(status2, fetch)
  //useFetchOnChange(networkId, fetch)
  //handleSending(status)
  return <Container maxWidth="sm">
  <Card
    sx={{ py:7 }}
  >
    <CardContent >
    {/*<HandleErrorV2 status={status} type={selectErrorSubtype(error)} persist={true}/>*/}
    {/*<HandleError status={googleStatus} message={googleMess}/>*/}
    <HandleSendingAndPending sending={[status]} />

    
      <Box 
        sx={{ my: 1 }}            
        display="flex"
        justifyContent="center"
        alignItems="center" 
      >
        <Typography
          color="textPrimary"
          variant="h4"
        >
          {t('SIGN_IN_TITLE')}
        </Typography>
      </Box>
      <Box 
        sx={{ my: 3, mt:1 }}            
        display="flex"
        justifyContent="center"
        alignItems="center" 
      >
        <Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          {t('SIGN_IN_SUBTEXT')}
        </Typography>
        
      </Box>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        sx={{ mt: 2 }} 
      >
        <Grid
          item
          xs={12}
          md={6}
        >
          <GoogleLogin
            clientId={clientId}
            buttonText={t("LOGIN_WITH_GOOGLE")}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
            cookiePolicy={'single_host_origin'}
            //redirectUri={'https://localhost:3000'}
            responseType='code'
            //hostedDomain={'https://localhost:3000'}
          />
        </Grid>
      </Grid>
      
    </CardContent>
  </Card>
  </Container>  
};

Login.propTypes = {  
  //tokenAddress: PropTypes.string.isRequired,  
  //networkId: PropTypes.number.isRequired,  
}  

export const _Login = props => {
  const { t, i18n } = useTranslation();

  const [
    data, error, status, onSubmit, unmount
  ] = useLogin()
  const [
    emailReg, passwordReg, handleSubmit, errors
  ] = useLoginForm()

  return <Fragment>
    

      <Container maxWidth="sm" >

      
          <Box 
            sx={{ my: 3 }}   
          >
            <Typography
              color="textPrimary"
              variant="h4"
            >
              {t('SIGN_IN')}
            </Typography>
            {/*
            <Typography
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              {t('SIGN_IN_ON_INTERNAL_PLATFORM')}
              Sign in on the internal platform
            </Typography>
            */}
          </Box>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={6}
            >
              <Button
                color="info"
                fullWidth
                //startIcon={<FacebookIcon />}
                //onClick={formik.handleSubmit}
                size="large"
                variant="contained"
                disabled={true}
              >
                {t('LOGIN_WITH_FACEBOOK')}
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
            >
              <Button
                fullWidth
                color="error"
                //startIcon={<GoogleIcon />}
                //onClick={formik.handleSubmit}
                size="large"
                variant="contained"
                disabled={true}
              >
                {t('LOGIN_WITH_GOOGLE')}
              </Button>
            </Grid>
          </Grid>
          <Box
            sx={{
              pb: 1,
              pt: 3
            }}
          >
            <Typography
              align="center"
              color="textSecondary"
              variant="body1"
            >
              {t('OR_LOGIN_WITH_EMAIL')}
            </Typography>
          </Box>
          <FormControlLabel
            sx={{width:1}}
            control={<TextField 
              fullWidth
              //helperText={formik.touched.email && formik.errors.email}
              label={t("EMAIL_ADDRESS")}
              margin="normal"
              name="email"
              //onBlur={formik.handleBlur}
              //onChange={formik.handleChange}
              type="email"
              //value={formik.values.email}
              variant="outlined"
            />}
            label={''}
            {...emailReg}
          /> 
          
          <FormControlLabel
            sx={{width:1}}
            control={<TextField 
              //error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              //helperText={formik.touched.password && formik.errors.password}
              label={t("PASSWORD")}
              margin="normal"
              name="password"
              //onBlur={formik.handleBlur}
              //onChange={formik.handleChange}
              type="password"
              //value={formik.values.password}
              variant="outlined"
            />}
            label={''}
            {...passwordReg}
          /> 
          
          <Box sx={{ py: 2 }}>
            <Button
              color="primary"
              //disabled={formik.isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >
              {t('SIGN_IN')}
            </Button>
          </Box>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {t('DONT_HAVE_AN_ACCOUNT')}
            {' '}
      
              <Link
                to="/register"
                variant="subtitle2"
                underline="hover"
                sx={{
                  cursor: 'pointer'
                }}
              >
                {t('SIGN_UP')}
              </Link>

          </Typography>
        
      </Container>
 
  </Fragment>
};