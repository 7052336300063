import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

//import { } from './workers'

import { 
  wwLoadConfig,  wwGetPause, wwPause, wwUnpause, wwGetInfo,
  wwAddToWhitelistSign, wwRemoveFromWhitelistSign, 
  wwGetEmail, wwSetEmail, wwGetUsers, wwGetUsersV2, wwGetInvestors,
  wwLinkWallet, wwUnlinkWallet, wwGetTxList,
  wwExportUserCsv, wwImportUserCsv, 
  //wwCreateUser, 
  wwBurnAll, wwDeploySto,
  wwImportSto, wwRemoveSto, wwBurnAndIssue, wwUsersInfo, wwLoadUserRoles, 
  wwManageRoles, wwCheckUserRoles, wwGetWallets, wwGetInvestorWallets,
  wwAllUserBalances, wwAllUserTransfers, wwIssueSto,
  wwGeneratePassword
  //wwMultiIssueFundSign,wwCreateFund,wwGetFunds,wwCancelFund,

} from './wrapped'

import { createUnmount, createFailed, cancellableAsync} from 'saga/helpers'


const async_map = {
  'ETH_WLTOKEN_ADD_TO_WHITELIST_SIGN': wwAddToWhitelistSign,
  'ETH_WLTOKEN_REMOVE_FROM_WHITELIST_SIGN': wwRemoveFromWhitelistSign,
  //'ETH_WLTOKEN_MULTI_ISSUE_FUND_SIGN': wwMultiIssueFundSign,
  //'ETH_WLTOKEN_GET_FUNDS': wwGetFunds,
  'ETH_WLTOKEN_GET_EMAIL': wwGetEmail,
  'ETH_WLTOKEN_GET_USERS': wwGetUsers,
  'ETH_WLTOKEN_GET_USERS_V2': wwGetUsersV2,
  'ETH_WLTOKEN_GET_WALLETS': wwGetWallets,
  'ETH_WLTOKEN_GET_INVESTOR_WALLETS': wwGetInvestorWallets,
  'ETH_WLTOKEN_GET_INVESTORS': wwGetInvestors,
  'ETH_WLTOKEN_SET_EMAIL': wwSetEmail,
  //'ETH_WLTOKEN_CREATE_FUND': wwCreateFund,
  //'ETH_WLTOKEN_CANCEL_FUND': wwCancelFund,
  'ETH_WLTOKEN_LOAD_CONFIG': wwLoadConfig,
  'ETH_WLTOKEN_GET_PAUSE': wwGetPause,
  'ETH_WLTOKEN_PAUSE': wwPause,
  'ETH_WLTOKEN_UNPAUSE': wwUnpause,
  'ETH_WLTOKEN_GET_INFO': wwGetInfo,
  'ETH_WLTOKEN_LINK_WALLET': wwLinkWallet,
  'ETH_WLTOKEN_UNLINK_WALLET': wwUnlinkWallet,
  'ETH_WLTOKEN_GET_TX_LIST': wwGetTxList,
  'ETH_WLTOKEN_EXPORT_USER_CSV': wwExportUserCsv,
  'ETH_WLTOKEN_IMPORT_USER_CSV': wwImportUserCsv,
  //'ETH_WLTOKEN_CREATE_USER': wwCreateUser,
  'ETH_WLTOKEN_BURN_ALL': wwBurnAll,
  'ETH_WLTOKEN_DEPLOY_STO': wwDeploySto,
  'ETH_WLTOKEN_IMPORT_STO': wwImportSto,
  'ETH_WLTOKEN_REMOVE_STO': wwRemoveSto,
  'ETH_WLTOKEN_BURN_AND_ISSUE': wwBurnAndIssue,
  'ETH_WLTOKEN_CHECK_USER_ROLES': wwCheckUserRoles,
  'ETH_WLTOKEN_USER_INFO': wwUsersInfo,
  'ETH_WLTOKEN_LOAD_USER_ROLES':wwLoadUserRoles,
  'ETH_WLTOKEN_MANAGE_ROLES': wwManageRoles,
  'ETH_WLTOKEN_ALL_USER_BALANCES': wwAllUserBalances,
  'ETH_WLTOKEN_ALL_USER_TRANSFERS': wwAllUserTransfers,
  'ETH_WLTOKEN_ISSUE_STO': wwIssueSto,
  'ETH_WLTOKEN_GENERATE_PASSWORD': wwGeneratePassword
}

export function* wltokenWatchAsync() {
  while(true){
    const { type, payload, meta } = yield take(Object.keys(async_map))
    yield fork(cancellableAsync, async_map[type], type, payload, meta)
  }     
}


/*
[
  {
    'address': '0xD285b41a2905d548cFA20Ad207d172AE2d8754b4', 
    'balance': 807462322642148389793, 
    'email': 'johndoe@noexist.com', 
    'investor_id': 1, 
    'isWhitelisted': True
  }
]
[
  'investor_id': 1,
  'email': 'johndoe@noexist.com', 
  'isWhitelisted': True,
  wallet: [
    {
      'address': '0xD285b41a2905d548cFA20Ad207d172AE2d8754b4', 
      'balance': 807462322642148389793,       
    }
  ]
]
*/