import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import qs from 'qs'
import { useLocation } from 'react-router-dom'
import { selectNetworkId } from "utilities/selectors"

export const useNetworkV3 = () => {
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  const {networkId} = {...query}
  return [networkId]
}

export const useTokenAddress = () => {
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  const { tokenAddress } = {...query}
  return [tokenAddress]
}

export const useWalletAddress = () => {
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  const { walletAddress } = {...query}
  return [walletAddress]
}

export const useNetworkV2 = () => {
  const dispatch = useDispatch() 
  const network_id = useSelector(selectNetworkId)

  const updateNetwork = (chain_id) => {
    dispatch({type: 'R_ETH_UPDATE_NETWORK', payload: {chain_id}})
  }

  return [network_id, updateNetwork]
}

/*
const useNetwork = () => {
  const dispatch = useDispatch() 
  const [data, error, loading] = useThunkData('ETH_LOAD_NETWORK')
  const network_id = useSelector(selectNetworkId)
  //const network_id = useSelector(selectNetworkId)

  const handleLoad = () => {
    dispatch({type:'ETH_LOAD_NETWORK'})
  }
  const unmount = () => {
    dispatch({type:'ETH_LOAD_NETWORK_UNMOUNT', meta: {name: 'ETH_LOAD_NETWORK', status: 'CLEAN'}})
  }
  return [data, error, loading, handleLoad, network_id, unmount]
}

export const LoadNetwork = ({children})=> {
  const dispatch = useDispatch() 
  const [data, error, detectingNetwork] = useFetchOnMountV2(useNetwork)
  if(detectingNetwork) return <FetchText text='Loading Network' char='.'/>
  return children
}
*/