import React, { useEffect, Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { useSnackbar  } from 'notistack';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useStyles } from 'components/useHooks/useStyles'
import { calcError } from "utilities/misc"




export const useErrorV2 = (status, errorResp) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {type: errType, message, payload} = {...errorResp}
  const {subtype: errSubtype} = {...payload}
  useEffect(()=>{
    if(calcError(status) && (errType === 'INTERNAL_ERROR' || errType === 'GENERIC_ERROR' || errType === 'NETWORK_ERROR')) {
      enqueueSnackbar(errSubtype, {
        content:(key, _type) => (
          <Alert id={key} severity="error" variant='filled'>
            <AlertTitle><strong>{t('ERROR')}: </strong>{t(_type)}</AlertTitle>
            {t(`${_type}_DESCRIPTION`)} - {t(`${_type}_MESSAGE`)}
          </Alert>
        )
      })
    }
  },[status])
}

export const useError = (status, errType) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(()=>{
    if(calcError(status)) {
      enqueueSnackbar(errType, {
        content:(key, _type) => (
          <Alert id={key} severity="error" variant='filled'>
            <AlertTitle><strong>{t('ERROR')}: </strong>{t(_type)}</AlertTitle>
            {t(`${_type}_DESCRIPTION`)} - {t(`${_type}_MESSAGE`)}
          </Alert>
        )
      })
    }
  },[status])
}

export const useErrorV3 = (status, errMessage) => {
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(()=>{
    if(calcError(status)) {
      enqueueSnackbar(errMessage, {
        content:(key, _message) => (
          <Alert id={key} severity="error" variant='filled'>
            <AlertTitle><strong>{t('ERROR')}</strong></AlertTitle>
            {_message}
          </Alert>
        )
      })
    }
  },[status])
}