//import Joi from 'joi'
/*
const configSchema = Joi.object({
    backend_url: Joi.alternatives().try([
      Joi.string(),
      Joi.empty(Joi.any())
    ]).default('localhost'),
    aws_region: Joi.alternatives().try([
      Joi.string(),
      Joi.empty(Joi.any())
    ]).default('ap-northeast-1'),
    aws_bucket: Joi.alternatives().try([
      Joi.string(),
      Joi.empty(Joi.any())
    ]).default('proj-lamesauce-images'),
});
*/
const dev_local = {
  backend_url: 'https://localhost:5000',
  backend_loc: 'local'
};

const dev_ubuntu = {
  backend_url: 'http://localhost',
  //backend_url: 'https://localhost:5000',
};

const dev_aws = {
  //backend_url: 'https://d2zy1gjwgph1h9.cloudfront.net/',
  backend_url: 'https://api.kakuseifunding.com/',
  backend_loc: 'webapp'
};

const dev_local_app_aws_server = {
  //backend_url: 'http://ec2-18-181-227-36.ap-northeast-1.compute.amazonaws.com',
  //backend_url: 'https://d2zy1gjwgph1h9.cloudfront.net/',
  backend_url: 'https://api.kakuseifunding.com/',
  backend_loc: 'local'
};

const development = {
  //backend_url: 'https://d36jrr192if6yz.cloudfront.net',
  backend_url: 'https://devapi.kakuseifunding.com/',
  backend_loc: 'devwebapp'
};

const production = {
  //backend_url: 'https://d36jrr192if6yz.cloudfront.net',
  backend_url: 'https://api.kakuseifunding.com/',
  backend_loc: 'webapp'
};

const getAppConfig = type => {
  const config_list = {
    dev_local,
    dev_ubuntu,
    dev_aws,
    dev_local_app_aws_server,
    production,
    development,
    default: dev_local
  };
  const config = config_list[type] || config_list['default']
  return config
  //const { error, value } = configSchema.validate(config)
  //return value
}

const config = getAppConfig(process.env.REACT_APP_ENV)

export default config;

export function useConfig(type) {
  const config_list = {
    dev_local,
    dev_ubuntu,
    dev_aws,
    production,
    development,
    default: dev_local
  };
  const config = config_list[type] || config_list['default']
  return config
}
