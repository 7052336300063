import React, { Fragment, useMemo } from 'react';

import Box from '@mui/material/Box';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

import { useTranslation } from 'react-i18next';

import { useGetEmail, useSetEmail, useSetEmailForm } from "./use"

import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { HandleErrorV2 } from 'components/molecules/HandleStatus'
import { calcFetching, calcError } from "utilities/misc"

import { useUnmount } from 'components/useHooks/useUnmount'
import { selectErrorSubtype } from "utilities/selectors"

export const SetEmailForm = props => {
  const { t, i18n } = useTranslation();
  const {defaultSubject, defaultMessage, onSuccess, emailType} = {...props}
  const [ subjectReg, messageReg, handleSubmit] = useSetEmailForm(defaultSubject, defaultMessage)
  const [data, error, status,  updateEmailFormat, unmount] = useSetEmail(emailType)

  useRefreshOnSuccess(status, onSuccess)
  useUnmount([unmount])

  if(calcFetching(status)) return <div>Sending email format...</div>
  return <Container maxWidth={false}>
    <Card>
      <CardHeader
        subheader={t(`${emailType}_EMAIL_DESCRIPTION`)}
        title={t(`${emailType}_EMAIL`)}
      />
      <Divider />
      <CardContent>

        <Box sx={{mb:2}}>

          <FormControlLabel
            control={<TextField fullWidth label={t('SUBJECT')}/>}
            label={''}
            sx={{width:1}}
            fullWidth
            {...subjectReg}
          />
        </Box>

        <Box>
          
          <FormControlLabel
            control={<TextField fullWidth multiline label={t('BODY')}/>}
            sx={{width:1}}
            label={''}
            {...messageReg}
          />
        </Box>
  
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button variant='contained' onClick={handleSubmit(updateEmailFormat)}>
          {t('UPDATE')}
        </Button>
      </Box>
    </Card>
  </Container>
  
}


export const EmailFormat = props => {
  const { onSuccess, emailType=null } = {...props}
  const [data, error, status, fetch, unmount] = useFetchOnMountV3(useGetEmail, emailType)
  const {subject, message} = {...data}

  //useRefreshOnSuccess([status], fetch)
  useUnmount([unmount])

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  return <Fragment>
    <SetEmailForm 
      defaultSubject={subject} 
      defaultMessage={message} 
      onSuccess={()=>{
        //fetch()
        onSuccess()
      }}
      emailType={emailType}
      />
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
  </Fragment>
}




const useColumns = (handleRoute) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('MAIL_TITLE'),
        accessor: 'title',
      },
      {
        Header:  t('EDIT'),
        accessor: 'pathname',
        Cell: item => <IconButton 
          variant='contained' 
          onClick={()=>handleRoute(item.value)}
        >
          <EditIcon/>
        </IconButton>
      }
    ]
  ,[i18n.language])
  return columns  
}


export const EmailList = props => {
  const {handleRoute=()=>null, routes} = {...props}
  const { t, i18n } = useTranslation();
  const columns = useColumns(handleRoute)
  return <Fragment>
    <PaginationTable columns={columns} data={routes} />
  </Fragment>

}

