import get from 'lodash/get'

export function transformState(state, path, data){
  state = state || {}
  if(path instanceof Array){
    const current = path[0]
    if(path[1]){
      const substate = state[current] || {}
      return ({
        ...state,
        [current]: transformState(substate, path.slice(1),data)
      }) 
    }
    return ({
      ...state,
      [current]: data
    })    
  }
  return ({
    ...state,
    [path]: data
  })
}

export function spreadState(state, path, data){
  state = state || {}
  if(path instanceof Array){
    const current = path[0]
    if(!current){
      return ({...state,...data})        
    }
    if(path[1]){
      const substate = state[current] || {}
      return ({
        ...state,
        [current]: spreadState(substate, path.slice(1),data)
      }) 
    }
    return ({
      ...state,
      [current]: {...state[current], ...data}
    })    
  }
  return ({
    ...state,
    [path]: {...state[path], ...data}
  })
}

//not working correctly
export function setState(state, path, data){
  console.log('setting state')
  state = state || {}
  if(path instanceof Array){
    const current = path[0]
    if(path[1]){
      const substate = state[current] || {}
      return ({
        ...state,
        [current]: transformState(substate, path.slice(1),data)
      }) 
    }
    console.log(state[current])
    return ({
      ...state,
      [current]: {...state[current], ...data}
    })    
  }
  console.log(state[path])
  return ({
    ...state,
    [path]: {...state[path], ...data}
  })
}


export function pushState(state, path, data){
  const old_data = get(state, path, [])
  const new_data = [...old_data, data]
  return transformState(state,path,new_data)
}

export function popState(state, path){
  const old_data = get(state, path, [])
  old_data.pop()
  return transformState(state,path,old_data)
}

/*
export function cleanState(state, path) {
  if(path instanceof Array){
    const current = path[0]
    if(path[1]){
      const substate = state[current] || {}
      return ({
        ...state,
        [current]: cleanState(substate, path.slice(1))
      }) 
    }
    return omit(state, current)
  }
  return omit(state, path)
}
*/
export function omit(state={}, path) {
  const newState = { ...state }
  if(path instanceof Array){
    const current = path[0]
    if(path[1]){
      const substate = state[current] || {}
      return ({
        ...state,
        [current]: omit(substate, path.slice(1))
      }) 
    }
    return omit(state, current)
  }
  delete newState[path]
  return newState
}
