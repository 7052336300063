import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';

export const FormControlLabel_ = props => {
  const { label, reg, touched, errText} = {...props}

  return <FormControlLabel
    sx={{width:1}}
    
    control={<TextField 
      fullWidth
      helperText={touched && errText}
      label={label}
      margin="normal"
      error={errText}
      //name={name}
      //onBlur={formik.handleBlur}
      //onChange={formik.handleChange}
      type="text"
      //value={formik.values.email}
      variant="outlined"
    />}
    label={''}
    {...reg}
  /> 
}