import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Decimal from 'decimal.js';
import reduce from 'lodash/reduce'
import get from 'lodash/get'

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
//import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import { FormControlLabel_ } from 'components/molecules/Forms'
import { PKButtonV2 } from 'components/molecules/PKButton'
import { useImportStoForm, useImportSto } from "./use"
//import { useGetUsers} from "components/organisms/ETH/sto/UserTable/use"


import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"

export const ImportSto = props => {
  const { t, i18n } = useTranslation();
  const { networkId, handleSuccess=()=>null } = {...props}

  const [ regs, handleSumbit, resetPK, formData ] = useImportStoForm()
  const [ addressReg ] = regs
  const { touchedFields, errors } = {...formData}
  const [
    data, error, status, onSubmit, unmount
  ] = useImportSto(networkId)
  
  useUnmount([unmount])
  useRefreshOnSuccess(status, handleSuccess)

  return <Fragment>
    <HandleSendingAndPending sending={[status]}/>
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>  
    <Box p={4}/>
    <Container maxWidth={false}>

      {/*<Toolbar 
        //handleOpen={handleOpen} 
        //disableAddToWhitelist={Object.keys(map).length===0} 
      />*/}
      <Card>
        <CardHeader
          subheader={t('STO_IMPORT_DESCRIPTION')}
          title={t('STO_IMPORT')}
        />
        <Divider />
        <CardContent>
          {/*<pre>{JSON.stringify({data, error, status },0,2)}</pre>*/}

          <FormControlLabel_ 
            label={t("STO_ADDRESS")} 
            reg={addressReg}
            touched={touchedFields[addressReg['name']]}
            errText={              
              //get(error, ['payload', 'subtype'], false) ? 
              //t(get(error, ['payload', 'subtype'], null))
              //: 
              t(errors[addressReg['name']]) 
            }
          />

        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button 
            variant='contained' 
            sx={{ float: "right"}} 
            onClick={handleSumbit(onSubmit)}
          >{t('IMPORT')}</Button>
        </Box>
      </Card>
    </Container>
  </Fragment>
}


/*
const Toolbar = props => {
  const { t, i18n } = useTranslation();
  const { 
    handleOpen, disableAddToWhitelist
  } = {...props}
  return <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      m: -1
    }}
  >
    <Typography
      sx={{ m: 1 }}
      variant="h4"
    >
      {t('USERS')}
    </Typography>
    <Box sx={{ m: 1 }}>
    </Box>
  </Box>
}
*/