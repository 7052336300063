import React, { useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Decimal from 'decimal.js';
import Joi from "joi";

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import { useTranslation } from 'react-i18next';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { 
  useGetInfo
} from "./use"

import { ValidateData } from 'components/molecules/Validate'
import { 
  HandleErrorV3
} from 'components/molecules/HandleStatus'
import { useFetchOnMountV3 } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { ethAddress } from "utilities/joi/sto"
import { selectErrorSubtype, useStoDetails, selectStoDetails } from "utilities/selectors"
import { createTokenHref } from 'utilities/ETH'

const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
    <Typography>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}

const dataSchema = {
  DECIMALS: Joi.number().required(),
  SYMBOL: Joi.string().required(),
  SUPPLY: Joi.number().unsafe().required(),
  ADMIN_ROLE: Joi.array().items(Joi.array().items(ethAddress(false)), Joi.object()).required(),
  PAUSER_ROLE: Joi.array().items(Joi.array().items(ethAddress(false)), Joi.object()).required(),
  BURNER_ROLE: Joi.array().items(Joi.array().items(ethAddress(false)), Joi.object()).required(),
  MINTER_ROLE: Joi.array().items(Joi.array().items(ethAddress(false)), Joi.object()).required(),
  WHITELIST_ADMIN_ROLE: Joi.array().items(Joi.array().items(ethAddress(false)), Joi.object()).required(),
}


export const StoInfo = props => {
  const { tokenAddress, networkId } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetInfo, networkId, tokenAddress, 
    [
      'DECIMALS', 'SYMBOL', 'SUPPLY', 'ADMIN_ROLE', 
      'PAUSER_ROLE', 'BURNER_ROLE', 'MINTER_ROLE', 
      'WHITELIST_ADMIN_ROLE'
    ]
  )

  useUnmount([unmount])

  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING')}</Fragment>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>
  const { 
    DECIMALS, SYMBOL, SUPPLY, ADMIN_ROLE, PAUSER_ROLE, BURNER_ROLE, 
    MINTER_ROLE, WHITELIST_ADMIN_ROLE
  } = {...data}

  return <ValidateData data={data} schema={dataSchema}>
    {/*<HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>*/}
    <Card>
      <CardContent>
        <Info text={t("ADDRESS")} subtext={tokenAddress}/>
        <Info text={t("DECIMALS")} subtext={DECIMALS}/>
        <Info text={t("SYMBOL")} subtext={SYMBOL}/>
        <Info 
          text={t("TOTAL_SUPPLY")} 
          subtext={Decimal(SUPPLY).mul(Decimal(10).pow(-DECIMALS)).toDecimalPlaces(4).toString()}
        />
      </CardContent>
      <Divider/>
      <CardContent>
        <Info text={t("ADMIN_ROLE")} subtext={JSON.stringify(ADMIN_ROLE[0])}/>
        <Info text={t("PAUSER_ROLE")} subtext={JSON.stringify(PAUSER_ROLE[0])}/>
        <Info text={t("BURNER_ROLE")} subtext={JSON.stringify(BURNER_ROLE[0])}/>
        <Info text={t("WHITELIST_ADMIN_ROLE")} subtext={JSON.stringify(WHITELIST_ADMIN_ROLE[0])}/>
      </CardContent>
    </Card>
  </ValidateData>

}


export const StoDetails = props => {
  const { tokenAddress, networkId } = {...props}
  const { t, i18n } = useTranslation();

  const [title, symbol, decimals, totalSupply] = useSelector(
    selectStoDetails(networkId,tokenAddress)
  )

  return <Fragment>
    <Card>
      <CardContent>
        <Info text={t("STO_NAME")} subtext={title}/>
        <Info text={t("SYMBOL")} subtext={symbol}/> 
        <Info text={t("ADDRESS")} subtext={
          <Link 
            href={createTokenHref(networkId,tokenAddress)}
            target='blank'
          >
            {tokenAddress}
          </Link>          
        }/>

      </CardContent>
    </Card>
  </Fragment>

}