import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import Decimal from 'decimal.js';
import reduce from 'lodash/reduce'
import get from 'lodash/get'

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
//import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Divider from '@mui/material/Divider';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
import { PKButtonV2 } from 'components/molecules/PKButton'
import { useRemoveStoForm, useRemoveSto } from "./use"
import { useStos } from 'components/organisms/ETH/sto/STOList/use'
//import { useGetUsers} from "components/organisms/ETH/sto/UserTable/use"


import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"

const AddressRadio = props => {
  const {address, reg} = {...props}
  return <FormControlLabel 
    value={address} 
    control={<Radio />} 
    label={""}  
    {...reg}
  />
}

const useColumn = (addressReg) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('SELECT'),
        Cell: item => <AddressRadio 
          address={get(item, ['row','original','address'], '')} 
          reg={addressReg}
        />
      },
      {
        Header: t('ADDRESS'),
        accessor: 'address',
        Filter: SelectValueFilter
      },
      {
        Header: t('TITLE'),
        accessor: 'NAME',
        Filter: SelectValueFilter
      },
      {
        Header: t('SYMBOL'),
        accessor: 'SYMBOL',
        Filter: SelectValueFilter
      }
    ]
  ,[i18n.language])
  return columns
}


export const RemoveSto = props => {
  const { networkId, handleSuccess=()=>null } = {...props}
  const { t, i18n } = useTranslation();
  //const  { handleRoute=()=>console.warn('requires handleRoute prop'), networkId } = {...props}

  const [ 
    [ addressReg ], 
    handleSumbit, 
    { errors, fields: {stoAddress}, isValid }, 
    resetPK
  ] = useRemoveStoForm()

  const [ data, error, status, onSubmit, unmount ] = useRemoveSto(networkId)
  const [STOaddresses, STOmap] = useStos(networkId)

  const column_data = useMemo( ()=>
    STOaddresses.map(
      address=>({address:address, ...STOmap[address]})
    )
    ,[]
  )

  const columns = useColumn(addressReg)

  useUnmount([unmount])
  useRefreshOnSuccess(status, handleSuccess)

  return <Fragment>
    <HandleSendingAndPending sending={[status]}/>
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
    <Container maxWidth={false}>

      <Card>
        <CardHeader
          subheader={t('STO_REMOVE_DESCRIPTION')}
          title={t('STO_REMOVE')}
        />
        <Divider />
        <CardContent>
          {stoAddress ? 
            <Fragment> 
              <pre>{JSON.stringify(stoAddress,0,2)}</pre>
            </Fragment>
            :
            <Fragment>{t('PLEASE_SELECT_WALLET_ADDRESS')}</Fragment>
          }
        </CardContent>
        <Divider />
        <CardActions sx={{ float: "right"}} >
          <Button 
            variant='contained' 
            onClick={handleSumbit(onSubmit)}
            disabled={!stoAddress}
          >{t('REMOVE')}</Button>
        </CardActions>
      </Card>
    </Container>

    <Box p={4}/>

    <Container maxWidth={false}>
      <FormControl sx={{width:'100%'}}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          //defaultValue={true}
          name="radio-buttons-group"
        >
          <PaginationTable 
            data={column_data} 
            columns={columns} 
            //networkId ={networkId} 
            //handleRoute={handleRoute}
          />
        </RadioGroup>
      </FormControl>
    </Container>

  </Fragment>
}


/*
const Toolbar = props => {
  const { t, i18n } = useTranslation();
  const { 
    handleOpen, disableAddToWhitelist
  } = {...props}
  return <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      m: -1
    }}
  >
    <Typography
      sx={{ m: 1 }}
      variant="h4"
    >
      {t('USERS')}
    </Typography>
    <Box sx={{ m: 1 }}>
  
    </Box>
  </Box>
}
*/