import React, { useState, useContext, useEffect, useRef, Fragment, useMemo, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm } from "react-hook-form"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { joiResolver } from '@hookform/resolvers/joi';
import { createUnmountDispatch } from "utilities/ETH"


export const useGetEmail = (type) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_EMAIL'
  const [data, error, status] = useThunkDataV3([action])

  const fetch = () => {
    dispatch({type: action, payload: { type }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]
}

export const useSetEmail = (type) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_SET_EMAIL'
  const [data, error, status] = useThunkDataV3([action])

  const updateEmailFormat = data => {
    const { subject, message } = {...data}
    dispatch({type: action, payload: { type, subject, message }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, updateEmailFormat, unmount]
}

export const useSetEmailForm = (subject, message) => {
  const { register, handleSubmit } = useForm({defaultValues: { subject, message }})
  return [register('subject'), register('message'), handleSubmit]
}