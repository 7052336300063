import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { Decimal } from 'decimal.js'
import { useForm, useFormState} from "react-hook-form"
import Joi from "joi";
import { joiResolver } from '@hookform/resolvers/joi';
import reduce from "lodash/reduce"
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { ethAddress, privateKey } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"



export const useIssueStoForm = () => {
  const { register, handleSubmit, formState:{ errors, touchedFields  }, resetField } = useForm({
    resolver: joiResolver(Joi.object({
      private_key: privateKey,
      file: Joi.any()
    })),
  });
  return [
    [register('private_key'), register('file')], 
    handleSubmit, 
    {
      errors:{
        private_key: get(errors,['private_key','message'], null), 
      }, 
      touchedFields
    }, 
    ()=>resetField('private_key')
  ]
}

export const useIssueSto = (networkId, tokenAddress, onSend)=> {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_ISSUE_STO'
  const [data, error, status] = useThunkDataV3([action])

  const submit = (formData) => {
    const {file, private_key} = {...formData}
    dispatch({type: action, payload: { 
      privateKey: private_key,
      file: file,
      tokenAddress, 
      networkId
    }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, submit, unmount]
}



export const _useIssueStoForm = () => {
  const { register, handleSubmit, watch, formState: {errors, touchedFields, isValid}, resetField} = useForm({
    resolver: joiResolver(Joi.object({
      walletAddress: ethAddress(),
      private_key: privateKey,
      token_amount: Joi.number().greater(0).required().messages({
        "number.greater": "MUST_BE_GREATER_THAN_ZERO",
        "number.base":  "MUST_BE_NUMBER"
      }),
    }))
  });
  const walletAddress = watch("walletAddress", null)

  return [ 
    [register('private_key'), register(`walletAddress`), register('token_amount')], 
    handleSubmit,
    {
      errors: {
        private_key: get(errors,['private_key','type'], null),
        walletAddress: get(errors,['walletAddress','type'], null),
        token_amount: get(errors,['token_amount','type'], null),
      },
      isValid,
      touchedFields,
      fields : {
        walletAddress
      }
    }, 
    ()=> { 
      resetField('private_key')
      resetField('walletAddress')
      resetField('token_amount')
    }
  ]
}

export const _useIssueSto = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_ISSUE_STO'
  const [data, error, status] = useThunkDataV3([action])

  const onSubmit = formdata => {
    const { private_key, walletAddress, token_amount } = {...formdata}
    const decimals = 18
    const _tokenAmount = Decimal(token_amount).mul(Decimal(10).pow(decimals)).toFixed()
    dispatch({
      type: action, 
      payload: { 
        tokenAddress, 
        investorAddress: walletAddress, 
        tokenAmount: _tokenAmount,
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [ data, error, status, onSubmit, unmount]
}
