import React from 'react';
import { useSelector } from 'react-redux'

import intersection from "lodash/intersection"

import { selectTokenRoles } from "utilities/selectors"


export const RestrictTokenRole = ({roles, children}) => {
  const userRoles = useSelector(selectTokenRoles)
  if (!roles) return children
  if (intersection(userRoles, roles).length > 0) {
    return children
  }  
  return <div>Role required: {JSON.stringify(roles)}</div>
}