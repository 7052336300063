import React, { useState, useContext, useEffect, useRef, Fragment } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux'

import LinearProgress from '@mui/material/LinearProgress';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

import { useFetchOnMountV3 } from 'components/useHooks/useFetch'

import { useMetaMask, useProvider } from './use'
import { calcFetching, calcError } from "utilities/misc"



export const LoadMetaMask = ({children})=> {
  const dispatch = useDispatch() 
  const [data, error, status] = useFetchOnMountV3(useMetaMask)
  if(calcFetching(status)) return <Fragment> 
    <LinearProgress/>
    <Typography>Loading MetaMask</Typography>
  </Fragment>
  if(calcError(status)) return <Alert>{JSON.stringify(error,0,2)}</Alert>

  return children
}

export const LoadProvider = ({children})=> {
  const dispatch = useDispatch() 
  
  const [data, error, status] = useFetchOnMountV3(useProvider)
  if(calcFetching(status)) return <Fragment> 
    <LinearProgress/>
    <Typography>Loading Provider</Typography>
  </Fragment>
  if(calcError(status)) return <Alert>{JSON.stringify(error,0,2)}</Alert>

  return children
}




/*
const GetBlock = props => {
  const { children, blockNumber } = {...props}
  return  <Web3DataF 
    method={'getBlock'} 
    methodArgs={[{type: 'inject', value:blockNumber , thunkName: blockNumber}]}
  >{(data, error, loading)=>
    children(data, error, loading)
  }</Web3DataF>  
}

const GetBlocks = props => {
  const { children, blockNumbers } = {...props}
  const nestList = blockNumbers.map((blockNumber)=>{
    return {
      type: 'web3',
      method: 'getBlock',
      methodArgs:[{type:'inject', value:blockNumber , thunkName: blockNumber}]
    }
  })
  return  <NestedFetch nestList={nestList}>{(datas, errors, loadings)=>children(datas, errors, loadings)}</NestedFetch>  
}

export const _LoadMetaMask = ({children})=> {
  const dispatch = useDispatch() 
  const [data, error, detectingMetaMask] = useThunkDataV3('ETH_LOAD_METAMASK')
  const [first, setFirst] = useState(true)
  const hasMetaMask = useSelector(store=>store['ETH']['hasMetaMask'] || false)
  const loading = first || detectingMetaMask
  useEffect(()=>{
    dispatch({type:'ETH_LOAD_METAMASK'})
    first && setFirst(false)
  },[])
  return children(loading, hasMetaMask)
}





const useNetwork = () => {
  const dispatch = useDispatch() 
  const [data, error, loading] = useThunkDataV3('ETH_LOAD_NETWORK')
  const network_id = useSelector(getNetworkId)

  const handleLoad = () => {
    dispatch({type:'ETH_LOAD_NETWORK'})
  }
  return [data, error, loading, handleLoad]
}

export const LoadNetworkV2 = ({children})=> {
  const dispatch = useDispatch() 
  const [ data, error, detectingNetwork, loadNetwork] = useNetwork()
  const [first, setFirst] = useState(true)
  const loading = first || detectingNetwork
  useEffect(()=>{
    loadNetwork()
    first && setFirst(false)
  },[])
  if(loading) return <FetchText text='Loading Network' char='.'/>
  return children
}
*/


/*
export const LoadMetaMask = ({children})=> {
  const dispatch = useDispatch() 
  const [data, error, detectingMetaMask] = useThunkDataV3('ETH_LOAD_METAMASK')
  const [first, setFirst] = useState(true)
  const hasMetaMask = useSelector(store=>store['ETH']['hasMetaMask'] || false)
  const loading = first || detectingMetaMask
  useEffect(()=>{
    dispatch({type:'ETH_LOAD_METAMASK'})
    first && setFirst(false)
  },[])
  return <LoadNetwork>{(networkId, error, loading)=>   
    <Fragment>
      <LoadAccount>{(selectedAccount, error2, loading2)=>
        <Fragment>   
          <WatchRPC>{(connected, error3, loading3)=>
            children({
              thunk:[data, error, detectingMetaMask], 
              hasMetaMask, 
              GetBlock, 
              GetBlocks, 
              network: [networkId, error, loading], 
              account:[selectedAccount, error2, loading2],
              rpc:[connected, error3, loading3]
            })
          }</WatchRPC>
          <WatchAccount/>
        </Fragment>
      }</LoadAccount>
      <WatchNetwork/>
    </Fragment>
  }</LoadNetwork>
}

export const _RequireMetaMask = ({children})=> {
  return <Fragment>
    <LoadMetaMask>{(loading, hasMetaMask)=>{
      if(loading) return <div>detecting meta mask...</div>
      if(!hasMetaMask) return <div>Please install Metamask</div>
      return children
    }}</LoadMetaMask>
  </Fragment>
}

export const RequireMetaMask = ({children})=> {
  return <Fragment>
    <LoadMetaMask>{({thunk, hasMetaMask})=>{
      const [data, error, loading] = [...thunk]
      if(loading) return <div>detecting meta mask...</div>
      if(!hasMetaMask) return <div>Please install Metamask</div>
      return children
    }}</LoadMetaMask>
  </Fragment>
}
*/