import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext
} from 'redux-saga/effects'

import { 
  createSuccess, createFailed, createPending,
  createFetching, createMeta, createClean
} from 'saga/helpers'

const createTokenHeader = (token) => ({
  'Authorization': `Bearer ${token}`
})

export function* handleGet(path, params={}, accessToken=null) { 
  const backend = yield getContext('backEnd')
  const resp = yield backend.get(path,{ ...params, headers: accessToken && createTokenHeader(accessToken) })
  return resp
}
  

export function* handlePost(path, params={}, accessToken=null) { 
  const backend = yield getContext('backEnd')
  const resp = yield backend.post(
    path,
    params,
    accessToken && {
      headers: createTokenHeader(accessToken)
    }
  )
  //const { status, data, statusText } = {...resp}
  return resp
}

export function* handlePostFile(path, formData) { 
  const backend = yield getContext('backEnd')
  console.warn(formData)
  const resp = yield backend.post(
    path,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  )
  const { status, data, statusText } = {...resp}
  return data
}

export function* waitForTask (name, task_id) {
  let payload
  while(true) {
    yield delay(3000)
    const resp = yield handleGet(`v1/task/${task_id}`)
    const { data } = {...resp}
    const { status } = {...data}
    if(status==='SUCCESS') {
      payload = data['data']
      break
    }
    if(status==='FAILURE') {
      const { status } = {...data}
      console.warn(data)
      const error_message = payload = data['error']['result']['exc_message']
      throw Error(error_message)
      //break
    }
  }
  return payload
}


