import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"




export const useGetInfo = (networkId, tokenAddress, types) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_INFO'
  const [data, error, status] = useThunkDataV3([action])

  const fetch = () => {
    dispatch({type: action, payload: { networkId, tokenAddress, types}})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]  
}
