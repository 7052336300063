import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

import { handleAsync, handleWeb3, handleES, 
  handleContract, putSuccess, putFetching, putFailed, putClean, putPending,
  handleFetch, getSelectedAddress, loadDeployedContracts, handleContractCallV2, handleContractSendV2,
  waitForTransactionReceiptV2, handleContractSign
} from '../helpers'

import { handlePost, waitForTask } from '../helpersV2'

import {
  createSecurityTokenName, createSTGetterName, createTransferManagerName, 
  createCappedSTOName, createDividendName
} from 'utilities/ETH/polymath'

import SecurityToken from "contracts/polymath/SecurityToken.json";
import STGetter from "contracts/polymath/STGetter.json";
import GeneralTransferManager from "contracts/polymath/GeneralTransferManager.json";
//import GeneralPermissonManager from "contracts/polymath/GeneralPermissionManager.json";
//import CappedSTO from "contracts/polymath/CappedSTO.json";
//import DividendCheckpoint from "contracts/polymath/ERC20DividendCheckpoint.json";
import { selectGtmAddress } from "utilities/selectors"

import get from "lodash/get"

/*
import { 
  handleContractSend, handleContractCall, handleWatchEvent, 
  handleWeb3Call, handleGetEvent, getSelectedAdddress
} from 'saga/ETH/truffle/helpers'
*/
//import { Polymath, browserUtils } from '@polymathnetwork/sdk';

//const POLYCLIENT = 'polyClient'
const W3 = 'w3'
const DELAY = 500
const ETHEREUM = 'ethereum'
const CONTRACTS = 'contracts'



const networkConfigs = {
  1: {
    polymathRegistryAddress: '0xdfabf3e4793cd30affb47ab6fa4cf4eef26bbc27',
    //providerUrl: 'https://mainnet.infura.io/v3/[INFURA_PRODUCT_ID]',
    //privateKey
  },
  42: {
    polymathRegistryAddress: '0x5b215a7d39ee305ad28da29bf2f0425c6c2a00b3',
    //providerUrl: 'https://kovan.infura.io/v3/[INFURA_PRODUCT_ID]',
    //privateKey
  },
};

const getTransferAddress = (tokenAddress) => (store) =>  get(store, ['ETH','polymath', tokenAddress, 'transferManager'], null)





export function* wLoadPolymathToken(type, payload, meta) {
  const { tokenSymbol, tokenAddress, transferManagerAddress } = {...payload}

  try {
    yield putFetching(type)

    const tokenContract = {
      type: 'other',
      contractName: tokenAddress,//`${tokenSymbol}Token`
      abi: [...SecurityToken['abi'],...STGetter['abi']],
      address: tokenAddress
    }
  
    yield loadDeployedContracts([tokenContract])
    console.warn('tokenAddress: ', tokenAddress)
    const resp = yield handleContractCallV2(tokenAddress, 'getModulesByType', [2])
    const transferManagerAddress = resp[0]

    const resp2 = yield handleContractCallV2(tokenAddress, 'getModulesByType', [1])
    const permissionManagerAddress = resp2[0] //this null. pm not added?

    const transferManagerContract = {
      type: 'other',
      contractName: transferManagerAddress,//`${tokenSymbol}Token`
      abi: GeneralTransferManager['abi'],
      address: transferManagerAddress
    }
    console.warn('transferManagerContract: ', transferManagerAddress)
    /*
    const permissionoManagerContract = {
      type: 'other',
      contractName: permissionManagerAddress,//`${tokenSymbol}Token`
      abi: GeneralPermissonManager['abi'],
      address: permissionManagerAddress
    }
    */
    yield loadDeployedContracts([transferManagerContract])
    //yield loadDeployedContracts([transferManagerContract, permissionoManagerContract])
    yield put({type: 'R_ETH_POLYMATH_UPDATE_TM', payload: {tokenAddress, transferManagerAddress, permissionManagerAddress}})

    yield putSuccess(type, [tokenAddress, transferManagerAddress])
  } 
  catch(err) {
    yield putFailed(type,err.message)
  }
} 

//const getGTMAddress = (tokenAddress) => (store) =>  get(store, ['polymath', 'STOmap', tokenAddress, 'gtmAddress'], null)

//will no longer rely on metamask
export function* wLoadPolymathTokenV2(type, payload, meta) {
  const { tokenSymbol, tokenAddress } = {...payload}
  const networkId = 42
  try {
    yield putFetching(type)

    const tokenContract = {
      type: 'other',
      contractName: tokenAddress,//`${tokenSymbol}Token`
      abi: [...SecurityToken['abi'],...STGetter['abi']],
      address: tokenAddress
    }
  
    yield loadDeployedContracts([tokenContract])
    //console.warn(networkId, tokenAddress)
    const gtmAddress = yield select(selectGtmAddress(networkId, tokenAddress))
    const transferManagerContract = {
      type: 'other',
      contractName: gtmAddress,//`${tokenSymbol}Token`
      abi: GeneralTransferManager['abi'],
      address: gtmAddress
    }
    console.warn('transferManagerContract: ', gtmAddress)
    yield loadDeployedContracts([transferManagerContract])

    yield put({type: 'R_ETH_POLYMATH_UPDATE_TM', payload: {tokenAddress, gtmAddress }})
    yield putSuccess(type, [tokenAddress, gtmAddress])
  } 
  catch(err) {
    yield putFailed(type,err.message)
  }
}  

export function* wAddToWhitelist(type, payload, meta) {
  const { tokenAddress, investorAddress } = {...payload}
  const generalTransferAddress = yield select(getTransferAddress(tokenAddress))
  try {
    yield putFetching(type, {subPath: [tokenAddress]})
    const txHash = yield handleContractSendV2(generalTransferAddress, 'modifyKYCData', [investorAddress, 1609426800, 1609426800, 1704034800])

    yield putPending(type, {subPath: [tokenAddress]})
    const txReceipt = yield waitForTransactionReceiptV2(type, tokenAddress, txHash)
    yield putSuccess(type, txReceipt, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailed(type,err.message, {subPath: [tokenAddress]})
  }
}  


export function* wInvestors(type, payload, meta) {
  const { tokenAddress } = {...payload}

  const generalTransferAddress = yield select(getTransferAddress(tokenAddress))

  try {
    yield putFetching(type, {subPath: [tokenAddress]})
    const data = yield handleContractCallV2(generalTransferAddress, 'getAllKYCData')
    const curr_time = Math.round((new Date()).getTime() / 1000)
    const validateWhitelisted = (curr_time, _canSendAfter,_canReceiveAfter,_expiryTime) => {
      return (curr_time > _canSendAfter && curr_time > _canReceiveAfter && curr_time < _expiryTime)
    }
    const parseData = (data) => {
      if(!data) return []
      //should i validate the data structure?
      const investors = data[0]
      const canSendAfters = data[1]
      const canReceiveAfters = data[2]
      const expiryTimes = data[3]

      const parsed = investors.map((address,index)=> {
        const canSendAfter = canSendAfters[index]
        const canReceiveAfter = canReceiveAfters[index]
        const expiryTime = expiryTimes[index]
        const isWhitelisted = validateWhitelisted(curr_time, canSendAfter,canReceiveAfter,expiryTime)
        return { address, canSendAfter, canReceiveAfter, expiryTime, isWhitelisted }
      })
      return parsed
    }
    const parsed = parseData(data)

    yield put({type: 'R_ETH_POLYMATH_UPDATE_INVESTORS', payload: {tokenAddress, investors: parsed} })
    
    yield putSuccess(type, data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailed(type,err.message, {subPath: [tokenAddress]})
  }
}  

export function* wDetails(type, payload, meta) {
  const { tokenAddress } = {...payload}

  try {
    yield putFetching(type, {subPath: [tokenAddress]})
    const symbol = yield handleContractCallV2(tokenAddress, 'symbol')
    const decimals = yield handleContractCallV2(tokenAddress, 'decimals')
    //const tokenDetails = yield handleContractCallV2(tokenAddress, 'tokenDetails')
    const totalSupply = yield handleContractCallV2(tokenAddress, 'totalSupply')

    const details = {symbol, decimals, totalSupply}

    yield put({type: 'R_ETH_POLYMATH_UPDATE_DETAILS', payload: {tokenAddress, details} })
    
    yield putSuccess(type, details, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailed(type,err.message, {subPath: [tokenAddress]})
  }
}  


export function* wLoadConfig(type, payload, meta) {
  const { network_id } = {...payload}

  try {
    yield putFetching(type, {subPath: [network_id]})

    const mock_config = {
      STOaddresses: ['0xDc5559860beE73B261904737E4fA2D10a46e9878', '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E'], //SPRT, PRJA
      STOmap: {
        '0xDc5559860beE73B261904737E4fA2D10a46e9878': { //SPRT
          balancerAddress: '0x88ACc4dB1B930354caa369f1bC8E35736f1BB2AC',
          title: 'Project SPRT',
          coord: [35.66636, 139.75737]
        },
        '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E': { //PRJA
          balancerAddress: '0x4bbd0c836Ba92BDcb58B5af3242C3Dc77c8FD8b0',
          title: 'Project A',
          coord: [35.65889, 139.74541]
        }
      },
      balancerMap: {
        '0x88ACc4dB1B930354caa369f1bC8E35736f1BB2AC': {
          STO: '0xDc5559860beE73B261904737E4fA2D10a46e9878',  //SPRT
          stablecoin: '0x8186e1d916774ab8004cB84d7aDC945953ee966D'  //FDAI 0x8186e1d916774ab8004cB84d7aDC945953ee966D
        },
        '0x4bbd0c836Ba92BDcb58B5af3242C3Dc77c8FD8b0': {
          STO: '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E',  //PRJA
          stablecoin: '0x8186e1d916774ab8004cB84d7aDC945953ee966D'  //FDAI 0x8186e1d916774ab8004cB84d7aDC945953ee966D
        }
      }
        //wrapped ether 0xdFCeA9088c8A88A76FF74892C1457C17dfeef9C1
    }

    yield put({type: 'R_ETH_POLYMATH_UPDATE_CONFIG', payload: {network_id, config: mock_config} })
    
    yield putSuccess(type, mock_config, {subPath: [network_id]})
  } 
  catch(err) {
    yield putFailed(type,err.message, {subPath: [network_id]})
  }
}  




/*
export function* wLoadPolymathTokens(type, payload, meta) {
  const {Tokens} = {...payload}

  try {
    yield putFetching(type)
    let tokenContracts = [] 
    Tokens.forEach((item,index)=> {
      const { tokenSymbol, tokenAddress, stoAddress, dividendAddress, transferAddress, STGetterAddress } = {...item}
      const _list = [
        {
          type: 'other',
          contractName: createSecurityTokenName(tokenSymbol),//`${tokenSymbol}Token`
          abi: SecurityToken['abi'],
          address: tokenAddress
        },
        {
          type: 'other',
          contractName: createSTGetterName(tokenSymbol),
          abi: STGetter['abi'],
          address: STGetterAddress
        },
        {
          type: 'other',
          contractName: createTransferManagerName(tokenSymbol),
          abi: GeneralTransferManager['abi'],
          address: transferAddress
        },
        {
          type: 'other',
          contractName: createCappedSTOName(tokenSymbol),
          abi: CappedSTO['abi'],
          address: stoAddress
        },
        {
          type: 'other',
          contractName: createDividendName(tokenSymbol),
          abi: DividendCheckpoint['abi'],
          address: dividendAddress
        }
      ]
      _list.forEach(item=>tokenContracts.push(item))
    })
    yield loadDeployedContracts(tokenContracts)
    yield putSuccess(type)
  } 
  catch(err) {
    yield putFailed(type,err.message)
  }
}  
*/
