import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

import { 
  wAddToWhitelistSign, wMultiIssueFundSign, wGetFunds, wGetEmail, wSetEmail,
  wCreateFund, wGetUsers, wGetInvestors, wRemoveFromWhitelistSign, wLoadConfig,
  wGetPause, wPause, wUnpause, wGetInfo, wLinkWallet, wUnlinkWallet, wGetTxList,
  wGetUnwhitelisted, wUploadCsv, wExportCsv, wExportUserCsv, wImportUserCsv,
  wCancelFund, wCreateUser, wControllerTransfer, wControllerRedeem
} from './workers'

import { createUnmount, createFailed, cancellableAsync} from 'saga/helpers'


const async_map = {
  'ETH_STO_ADD_TO_WHITELIST_SIGN': wAddToWhitelistSign,
  'ETH_STO_REMOVE_FROM_WHITELIST_SIGN': wRemoveFromWhitelistSign,
  'ETH_STO_MULTI_ISSUE_FUND_SIGN': wMultiIssueFundSign,
  'ETH_STO_GET_FUNDS': wGetFunds,
  'ETH_STO_GET_EMAIL': wGetEmail,
  'ETH_STO_GET_USERS': wGetUsers,
  'ETH_STO_GET_INVESTORS': wGetInvestors,
  'ETH_STO_SET_EMAIL': wSetEmail,
  'ETH_STO_CREATE_FUND': wCreateFund,
  'ETH_STO_CANCEL_FUND': wCancelFund,
  'ETH_STO_LOAD_CONFIG': wLoadConfig,
  'ETH_STO_GET_PAUSE': wGetPause,
  'ETH_STO_PAUSE': wPause,
  'ETH_STO_UNPAUSE': wUnpause,
  'ETH_STO_GET_INFO': wGetInfo,
  'ETH_STO_LINK_WALLET': wLinkWallet,
  'ETH_STO_UNLINK_WALLET': wUnlinkWallet,
  'ETH_STO_GET_TX_LIST': wGetTxList,
  'ETH_STO_GET_UNWHITELISTED': wGetUnwhitelisted,
  'ETH_STO_UPLOAD_CSV': wUploadCsv,
  'ETH_STO_EXPORT_CSV': wExportCsv,
  'ETH_STO_EXPORT_USER_CSV': wExportUserCsv,
  'ETH_STO_IMPORT_USER_CSV': wImportUserCsv,
  'ETH_STO_CREATE_USER': wCreateUser,
  'ETH_STO_CONTROLLER_TRANSFER': wControllerTransfer,
  'ETH_STO_CONTROLLER_REDEEM':wControllerRedeem
}

export function* stoWatchAsync() {
  while(true){
    const { type, payload, meta } = yield take(Object.keys(async_map))
    yield fork(cancellableAsync, async_map[type], type, payload, meta)
  }     
}


/*
[
  {
    'address': '0xD285b41a2905d548cFA20Ad207d172AE2d8754b4', 
    'balance': 807462322642148389793, 
    'email': 'johndoe@noexist.com', 
    'investor_id': 1, 
    'isWhitelisted': True
  }
]
[
  'investor_id': 1,
  'email': 'johndoe@noexist.com', 
  'isWhitelisted': True,
  wallet: [
    {
      'address': '0xD285b41a2905d548cFA20Ad207d172AE2d8754b4', 
      'balance': 807462322642148389793,       
    }
  ]
]
*/