import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
//import { DashboardNavbar } from './dashboard-navbar';
//import { DashboardSidebar } from './dashboard-sidebar';

//import { makeStyles } from '@mui/styles';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  //display: 'flex',
  //flex: '1 1 auto',
  //flexDirection: 'column'
  width: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

export const DashboardLayout = (props) => {
  const { children } = props;
  //const [isSidebarOpen, setSidebarOpen] = useState(true);

  return (
    <>
      <DashboardLayoutRoot>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
            px:3,
            py:2,
          }}
        >
          {children}
        </Box>
      </DashboardLayoutRoot>
    </>
  );
};