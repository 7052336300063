import React, { 
  useEffect, Fragment, useMemo 
} from 'react';

import { 
  useRouteMatch,
  useParams,
  useLocation,
  useHistory
} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
//import NextLink from 'next/link';
//import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { 
  Box, Button, Divider, Drawer, Typography, useMediaQuery 
} from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
/*
import { ChartBar as ChartBarIcon } from '../icons/chart-bar';
import { Cog as CogIcon } from '../icons/cog';
import { Lock as LockIcon } from '../icons/lock';
import { Selector as SelectorIcon } from '../icons/selector';
import { ShoppingBag as ShoppingBagIcon } from '../icons/shopping-bag';
import { User as UserIcon } from '../icons/user';
import { UserAdd as UserAddIcon } from '../icons/user-add';
import { Users as UsersIcon } from '../icons/users';
import { XCircle as XCircleIcon } from '../icons/x-circle';
import { Logo } from './logo';
import { NavItem } from './nav-item';
*/

import MailIcon from '@mui/icons-material/Mail';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import FaceIcon from '@mui/icons-material/Face';
import LogoutIcon from '@mui/icons-material/Logout';

import { RestrictUserRole } from 'components/organisms/Auth/Restrict' 

const NavItem = props => {
  const { route, Icon, title, routes, ...others } = props;
  const location = useLocation()
  const pathname = location['pathname']
  const active = route ? (pathname === route) : false;
  return (
    <Button
      component="a"
      startIcon={<Icon/>}
      disableRipple
      sx={{
        backgroundColor: active && 'rgba(255,255,255, 0.08)',
        borderRadius: 1,
        color: active ? 'secondary.main' : 'neutral.300',
        fontWeight: active && 'fontWeightBold',
        justifyContent: 'flex-start',
        //px: 3,
        textAlign: 'left',
        textTransform: 'none',
        width: '100%',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, 0.08)'
        }
      }}
    > 
      {title} 
    </Button>
  );  
}

const Contents = props => {
  const {routes, handleRoute, handleLogout} = {...props}
  const { t, i18n } = useTranslation();
  const location = useLocation()
  const pathname = location['pathname']
  return <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}
  >

    <Box sx={{ pt: 3, pl: 3 }}>
      <IconButton>

        <FaceIcon sx={{ fontSize: 50 }}  />
      </IconButton>
      {/*
      <SvgIcon>
        <path class="cls-1" d="M145.14,131.18a14.34,14.34,0,0,0-4.85-9.28v-11l-2.56,6.41v2.81a14.37,14.37,0,0,0-18.95,4.78A14.09,14.09,0,0,1,137.73,122v16.1l2.56-6.41v-7.45a14.09,14.09,0,0,1-2.48,21.06A14.4,14.4,0,0,0,145.14,131.18Z" transform="translate(-118.36 -108.29)"/>
        <polygon class="cls-1" points="18.09 30.62 15.53 37.03 15.53 23.83 18.09 17.42 18.09 30.62"/>
      </SvgIcon>
    */}
    </Box>

    <Divider
      sx={{
        borderColor: '#2D3748',
        my: 3
      }}
    />
    <Box sx={{ flexGrow: 1 }}>
      {routes.map((item,idx) => {
        const { title, Icon, route, restrictedRoles} = {...item} 
        return <RestrictUserRole key={idx} roles={restrictedRoles}>
          <ListItem 
            //button 
            //key={text} 
            onClick={()=>handleRoute(route)}
            disableGutters
            sx={{
              display: 'flex',
              mb: 0.5,
              py: 0,
              px: 2
            }}
          > 
            <NavItem title={t(title)} Icon={Icon} route={route} />
          </ListItem>
        </RestrictUserRole>
      })}
    </Box>
    <Divider sx={{ borderColor: '#2D3748' }} />
    <ListItem 
      //button 
      //key={text} 
      onClick={handleLogout}
      disableGutters
      sx={{
        display: 'flex',
        mb: 0.5,
        py: 0,
        px: 2
      }}
    > 
      <NavItem title={t("LOGOUT")} Icon={LogoutIcon} />
    </ListItem>
  </Box>
}

export const DashboardSidebar = (props) => {
  const { open, onClose, handleRoute, routes, handleLogout  } = props;
  const { t, i18n } = useTranslation();
  //const router = useRouter();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    defaultMatches: true,
    noSsr: false
  });
  const _handleRoute = route => {
    onClose?.()
    handleRoute(route)
  }
  useEffect(
    () => {
      /*
      if (!router.isReady) {
        return;
      }
      */
      if (open) {
        onClose?.();
      }
    },
    []
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //[router.asPath]
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'neutral.900',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        <Contents routes={routes} handleRoute={_handleRoute} handleLogout={handleLogout}/>
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      <Contents routes={routes} handleRoute={_handleRoute} handleLogout={handleLogout}/>
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};


export const DashboardSidebarV2 = (props) => {
  const { 
    open=true, 
    onClose=()=>null, 
    handleRoute=()=>null, 
    routes=[], 
    handleLogout=()=>null, 
    lgUp=true,
    t=text=>text,
    pathname
  } = props;

  return (
    <Drawer
      anchor="left"
      onClose={lgUp? ()=>null : onClose}
      open={lgUp ? true : open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: lgUp ? 0 : (theme) => theme.zIndex.appBar + 100 }}
      variant={lgUp ? "permanent": "temporary"} 
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box sx={{ pt: 3, pl: 3 }}>
          <IconButton>
            <FaceIcon sx={{ fontSize: 50 }}  />
          </IconButton>
        </Box>
        <Divider
          sx={{
            borderColor: '#2D3748',
            my: 3
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          {routes.map((item,idx) => {
            const { title, Icon, route, restrictedRoles} = {...item} 
            return <>
              {/*<RestrictUserRole key={idx} roles={restrictedRoles}>*/}
                <ListItem 
                  //button 
                  //key={text} 
                  onClick={()=>handleRoute(route)}
                  disableGutters
                  sx={{
                    display: 'flex',
                    mb: 0.5,
                    py: 0,
                    px: 2
                  }}
                > 
                  <NavItemV2 
                    title={t(title)} 
                    Icon={Icon} 
                    route={route}
                    t={t}
                    pathname={pathname}
                  />
                </ListItem>
              {/*</RestrictUserRole>*/}
            </>
          })}
        </Box>
        <Divider sx={{ borderColor: '#2D3748' }} />
        <ListItem 
          //button 
          //key={text} 
          onClick={handleLogout}
          disableGutters
          sx={{
            display: 'flex',
            mb: 0.5,
            py: 0,
            px: 2
          }}
        > 
          <NavItemV2 title={t("LOGOUT")} Icon={LogoutIcon} />
        </ListItem>
      </Box>
    </Drawer>
  );
};

export const NavItemV2 = props => {
  const { 
    route=null, 
    Icon=FaceIcon, 
    title='placeholder', 
    //active=false,
    pathname=null
  } = props;
  const active = useMemo(()=> (
    route ? (pathname === route) : false
  ),[pathname])
  return (
    <Button
      component="a"
      startIcon={<Icon/>}
      disableRipple
      sx={{
        backgroundColor: active && 'rgba(255,255,255, 0.08)',
        borderRadius: 1,
        color: active ? 'secondary.main' : 'neutral.300',
        fontWeight: active && 'fontWeightBold',
        justifyContent: 'flex-start',
        //px: 3,
        textAlign: 'left',
        textTransform: 'none',
        width: '100%',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255, 0.08)'
        }
      }}
    > 
      {title} 
    </Button>
  );  
}
