import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import PropTypes from 'prop-types';  

import Joi from "joi";
import Decimal from 'decimal.js';
import { useTable, useFilters, usePagination } from 'react-table'

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { 
  useGetInvestors, useBurnAllForm, useBurnAll,
} from "./use"


import { WalletList, EmailCell } from 'components/molecules/TableCells'
import { ValidateData } from 'components/molecules/Validate'
import { Toolbar } from 'components/molecules/Toolbar'
import { Jdenticon } from 'components/molecules/Jdenticon'
import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
import { PKButton } from 'components/molecules/PKButton'
import { HandleErrorV2, HandleErrorV3, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"

const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
    <Typography>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}


const useInvestorColumn = (reg) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('SELECT'),
        Cell: item => <FormControlLabel 
          value={item.row.original['address']} 
          control={<Radio />} 
          label={""}  
          {...reg}
        />
      },
      {
        Header: t('INVESTOR_ID'),
        accessor: 'investor_id',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('WALLET_ADDRESS'),
        accessor: 'address',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('BALANCE'),
        accessor: 'balance',
        Cell: ({value})=>{ 
          const decimals = 18  
          const amount = Decimal(value).mul(Decimal(10).pow(-decimals)).toString()
          return amount
        }
      },
      {
        Header:  t('IS_WHITELISTED'),
        accessor: 'isWhitelisted',
        Cell: item => item.value ? t('TRUE'):t('FALSE')
      },

    ]
  ,[i18n.language])
  return columns  
}

const dataSchema = {
  'investors': Joi.array().items(
    Joi.object({
      investor_id: Joi.number().required(),
      email: Joi.string().required(),
      address: Joi.string().required(),
      balance: Joi.number().unsafe().required(),
      isWhitelisted: Joi.boolean().required(),
    }).required()
  ).required(),
}


export const BurnAll = props => {
  const { tokenAddress, networkId } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(useGetInvestors, networkId, tokenAddress)

  //const [data, error, loading] = mock_data
  const [regs, handleSumbit, {
      errors: { private_key: PKError }, 
      touchedFields:{ private_key: PKTouched },
      fields: { walletAddress }
    }, resetForm
  ] = useBurnAllForm()
  const [PKReg, burnReg] = regs

  const columns = useInvestorColumn(burnReg)

  //const [ open, handleOpen, handleClose ]  = useMemoizedOpen(resetPK)

  const [ data2, error2, status2, onSubmit, unmount2 ] = useBurnAll(
    networkId,  
    tokenAddress, 
    //onSend=>{
      //handleClose()
    //}
  )

  useUnmount([ unmount, unmount2 ])
  useRefreshOnSuccess(status2, ()=>{
    resetForm()
    fetch()
  })
  //useFetchOnChange(networkId, fetch)

  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING')}</Fragment>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return <Fragment>
    <PKButton 
      PKReg={PKReg} 
      handleSend={handleSumbit(onSubmit)}
      touched={PKTouched}
      errText={t(PKError)}
      disabled={!walletAddress}
      title={t('BURN_ALL_TITLE')}
      subheader={t('BURN_ALL_DESCRIPTION')}
    >
      {walletAddress ?
        <Info text={t('BURN_WALLET')} subtext={walletAddress}/>
        :
        <div>{t("PLEASE_SELECT_WALLET_ADDRESS")}</div>
      }
    </PKButton>
    <Box p={1}/>
    <RadioGroup name="radio-buttons-group">
      <PaginationTable columns={columns} data={data['investors']} />
    </RadioGroup>
    <HandleSendingAndPending sending={[status2]} pending={[status2]}/>
    <HandleErrorV2 status={status2} type={selectErrorSubtype(error)}/>
  </Fragment>

}


BurnAll.propTypes = {  
  tokenAddress: PropTypes.string.isRequired,  
  networkId: PropTypes.number.isRequired,  
}  
