import { transformState, spreadState, pushState, omit } from "saga/transforms"

import get from 'lodash/get'

//maybe just use a getter?
const getMeta = action => {
  return action.meta || {}
}

const getMetaName = action => {
  const meta = getMeta(action)
  const name = meta.name
  if(!name) return action.type
  return name
}

const getMetaStatus = action => {
  const meta = getMeta(action)
  return meta.status
}
const getMetaAddress = action => {
  const meta = getMeta(action)
  return meta.address
}

const getMetaHoldData = action => {
  const meta = getMeta(action)
  return meta.holdData
}


const handleMetamask = (state, path) => ({
  ...state,
  ['METAMASK']: transformState(state['METAMASK'], path, true),
  ['FETCHING']: transformState(state['FETCHING'], path, false),
  ['QUEUED']: transformState(state['QUEUED'], path, false),
  ['ERROR']: transformState(state['ERROR'], path, null),
  ['DATA']: transformState(state['DATA'], path, null)
})

const handleFetching = (state, path) => ({
  ...state,
  ['FETCHING']: transformState(state['FETCHING'], path, true),
  ['QUEUED']: transformState(state['QUEUED'], path, false),
  ['ERROR']: transformState(state['ERROR'], path, null),
  ['DATA']: transformState(state['DATA'], path, null),
  ['STATUS']: transformState(state['STATUS'], path, 'FETCHING')
})
const handleFetchingHoldData = (state, path) => ({
  ...state,
  ['FETCHING']: transformState(state['FETCHING'], path, true),
  ['QUEUED']: transformState(state['QUEUED'], path, false),
  ['ERROR']: transformState(state['ERROR'], path, null),
})
const handleQueued = (state, path) => ({
  ...state,
  ['FETCHING']: transformState(state['FETCHING'], path, false),
  ['QUEUED']: transformState(state['QUEUED'], path, true),
  ['ERROR']: transformState(state['ERROR'], path, null),
  ['DATA']: transformState(state['DATA'], path, null)
})
const handleClean = (state, path) => ({
  ...state,
  ['FETCHING']: omit(state['FETCHING'], path),
  ['QUEUED']: omit(state['QUEUED'], path),
  ['ERROR']: omit(state['ERROR'], path),
  ['DATA']: omit(state['DATA'], path),
  ['META']: omit(state['META'], path),
  ['STATUS']: omit(state['STATUS'], path)
})
const handleError = (state, path, payload) => ({
  ...state,
  ['METAMASK']: transformState(state['METAMASK'], path, false),
  ['FETCHING']: transformState(state['FETCHING'], path, false),
  ['QUEUED']: transformState(state['QUEUED'], path, false),
  ['ERROR']: transformState(state['ERROR'], path, payload),
  ['DATA']: transformState(state['DATA'], path, null),
  ['PENDING']: transformState(state['PENDING'], path, false),
  ['STATUS']: transformState(state['STATUS'], path, 'ERROR')
})
const handleData = (state, path, payload) => ({
  ...state,
  ['METAMASK']: transformState(state['METAMASK'], path, false),
  ['FETCHING']: transformState(state['FETCHING'], path, false),
  ['QUEUED']: transformState(state['QUEUED'], path, false),
  ['ERROR']: transformState(state['ERROR'], path, null),
  ['DATA']: transformState(state['DATA'], path, payload),
  ['PENDING']: transformState(state['PENDING'], path, false),
  ['STATUS']: transformState(state['STATUS'], path, 'SUCCESS')
})
const handlePending = (state, path, payload) => {
  const {data, meta} = {...payload}
  const current_meta = get(state, ['META', path], {})
  return({
    ...state,
    ['METAMASK']: transformState(state['METAMASK'], path, false),
    ['FETCHING']: transformState(state['FETCHING'], path, false),
    ['QUEUED']: transformState(state['QUEUED'], path, false),
    ['ERROR']: transformState(state['ERROR'], path, null),
    ['PENDING']: transformState(state['PENDING'], path, true),
    ['META']: transformState(state['META'], path, {...current_meta,...meta}),
    ['STATUS']: transformState(state['STATUS'], path, 'PENDING')
  })
}
const handleMeta = (state, path, payload) => {
  const current_meta = get(state, ['META', path], {})
  return ({
    ...state,
    ['FETCHING']: transformState(state['FETCHING'], path, false),
    ['META']: transformState(state['META'], path, {...current_meta,...payload}),
  })
}
const handleDataMeta = (state, path, payload) => {
  const {data, meta} = payload
  const current_meta = get(state, ['META', path], {})
  return({
    ...state,
    ['FETCHING']: transformState(state['FETCHING'], path, false),
    ['QUEUED']: transformState(state['QUEUED'], path, false),
    ['ERROR']: transformState(state['ERROR'], path, null),
    ['DATA']: transformState(state['DATA'], path, data),
    ['PENDING']: transformState(state['PENDING'], path, false),
    ['META']: transformState(state['META'], path, {...current_meta,...meta}),
  })
}
const handleSet = (state, path, payload) => {
  const current_data = get(state, ['DATA', path], {})
  return ({
    ...state,
    ['FETCHING']: transformState(state['FETCHING'], path, false),
    ['QUEUED']: transformState(state['QUEUED'], path, false),
    ['ERROR']: transformState(state['ERROR'], path, null),
    ['DATA']: transformState(state['DATA'], path, {...current_data,...payload}),
  })
}

export default function thunkReducer(state = {}, action) {
  const { meta, payload } = {...action}
  if(!meta ) return state

  const name = getMetaName(action)
  const status = getMetaStatus(action)
  //const address = getMetaAddress(action)
  const holdData = getMetaHoldData(action)
  const subPath = meta['subPath']

  let path = name
  if(subPath) path = [name, ...subPath]
  //if(address) path = ['by_address', name, address]

  switch(status) {
    case 'FETCHING':
      if(holdData) return handleFetchingHoldData(state, path)
      return handleFetching(state, path)
    case 'QUEUED':
      return handleQueued(state, path)    
    case 'CLEAN':
      return handleClean(state, path)
    case 'ERROR':
      return handleError(state, path, payload)  
    case 'DATA':
      return handleData(state, path, payload) 
    case 'PENDING':
      return handlePending(state, path, payload) 
    case 'META':
      return handleMeta(state, path, payload) 
    case 'DATA_META':
      return handleDataMeta(state, path, payload) 
    case 'SET':
      return handleSet(state, path, payload)
    case 'METAMASK':
      return handleMetamask(state, path) 
    default:
      return state
  } 
}