import get from 'lodash/get'
import pullAt from 'lodash/pullAt'


import { transformState, spreadState, pushState, omit } from "saga/transforms"

const init_state = {
  tx_status: 'STANDBY',
  metamask: 'NOT_DETECTED',
  hasMetaMask: false,
  MetaMaskReady: false
}

const ETHReducers = (state = init_state, action) => {
  const { type } = {...action}
  const payload = action.payload || {} //payload should always be an object
  //const user_id = payload.user_id || 'temp'
  const reducers = {
    default: () => state,
    ETH_TX_PENDING_ON: () => transformState(state, ['pending'], true),
    ETH_TX_PENDING_OFF: () => transformState(state, ['pending'], false),

    R_ETH_TX_STATUS_SENDING: () => ({...state, tx_status:'SENDING', tx_inprogress: true}),
    R_ETH_TX_STATUS_PENDING: () => ({...state, tx_status:'PENDING', tx_inprogress: true}),
    R_ETH_TX_STATUS_UPDATING: () => ({...state, tx_status:'UPDATING', tx_inprogress: true}),
    R_ETH_TX_STATUS_STANDBY: () => ({...state, tx_status:'STANDBY', tx_inprogress: false}),

    R_ETH_TX_METAMASK_READY_TRUE: () => transformState(state, ['MetaMaskReady'], true),
    R_ETH_TX_METAMASK_READY_FALSE: () => transformState(state, ['MetaMaskReady'], false),

    R_ETH_TX_HAS_METAMASK_TRUE: () => transformState(state, ['hasMetaMask'], true),
    R_ETH_TX_HAS_METAMASK_FALSE: () => transformState(state, ['hasMetaMask'], false),

    R_ETH_TX_IS_ROPSTEN_TRUE: () => transformState(state, ['isRopsten'], true),
    R_ETH_TX_IS_ROPSTEN_FALSE: () => transformState(state, ['isRopsten'], false),

    R_ETH_UPDATE_NETWORK: () =>  {
      const {chain_id} = {...payload}
      return transformState(state, ['network'], chain_id)
    },
    R_ETH_UPDATE_ACCOUNT: () =>  {
      const {account} = {...payload}
      if(!account) return ({...state, metamask:'NO_ACCOUNT', account: null})
      return ({...state, metamask:'READY', account: account})
    },
    R_ETH_UPDATE_ETH_BALANCE: () =>  {
      const {eth_balance} = {...payload}
      return ({...state, eth_balance: eth_balance})
    },
    R_ETH_UPDATE_ETH_EXCHANGE_RATE: () =>  {
      const {eth_exchange_rate} = {...payload}
      return ({...state, eth_exchange_rate: eth_exchange_rate})
    },
    R_ETH_UPDATE_RPC: () =>  {
      const {connected} = {...payload}
      return transformState(state, ['rpc'], connected)
    },
    ETH_SHOP_UPDATE_PRODUCTS: () => {
      const { product_map, product_list } = {...payload}
      return {...state, product_map, product_list}
    },
    R_ETH_SHOP_UPDATE_ORDERS: () => {
      const { order_map, order_list } = {...payload}
      return {...state, order_map, order_list}
    },
    R_ETH_SHOP_UPDATE_PROCESSED: () => {
      return transformState(state, ['processed_orders'], payload)
    },
    R_ETH_SHOP_UPDATE_CANCELED: () => {
      return transformState(state, ['canceled_orders'], payload)
    },
    R_ETH_ADD_TO_CART: () => pushState(state, ['cart'], payload),
    R_ETH_REMOVE_FROM_CART: () => {
      const curr_cart = [...state['cart']]
      const {remove_index} = {...payload}
      const removed = pullAt(curr_cart, remove_index)
      return transformState(state, ['cart'], curr_cart)
    },
    R_ETH_EMPTY_CART: () => transformState(state, ['cart'], []),

    R_ETH_ESTATE_UPDATE_PROJECTS: () => {
      const { project_map, project_list } = {...payload}
      return {...state, project_map, project_list}
    },
    R_ETH_ESTATE_UPDATE_TOKENS: () => {
      const { token_map, token_list } = {...payload}
      return {...state, token_map, token_list}
    },
    R_ETH_ESTATE_UPDATE_RENTS: () => {
      const { rent_map } = {...payload}
      return {...state, rent_map}
    },
    R_ETH_ESTATE_UPDATE_TRANFERS: () => {
      const { transfer_map } = {...payload}
      return {...state, transfer_map}
    },
    R_ETH_ESTATE_UPDATE_HOLDERS: () => {
      const { holder_map } = {...payload}
      return {...state, holder_map}
    },
    R_ETH_ESTATE_UPDATE_PROJ_IMAGES: () => {
      const { image_map } = {...payload}
      return {...state, image_map}
    },
    R_ETH_ESTATE_UPDATE_PROJ_LIKE: () => {
      const { project_id, isLike } = {...payload}
      const curr_project = state['project_map'][project_id]
      const new_total = isLike ? curr_project['total']+1 :curr_project['total']-1 
      const new_proj = {...curr_project, like: isLike, total: new_total}
      //console.info(project_id, isLike)
      //console.info(new_proj)
      //console.info(project_id.toString())
      return transformState(state, ['project_map', project_id.toString()], new_proj)
    },
    R_ETH_INIT: () => {
      const { about, address } = {...payload}
      return {...state, about, address}
    },
    R_ETH_MOCK: () => {
      const { path, mock_data } = {...payload}
      return spreadState(state, path, mock_data)
    },
    R_ETH_POLYMATH_UPDATE_TM: () =>  {
      const  { tokenAddress, transferManagerAddress, permissionManagerAddress } = {...payload}
      return transformState(state, ['polymath', tokenAddress ], {
        transferManager: transferManagerAddress, permissionManager: permissionManagerAddress
      })
    },
    R_ETH_POLYMATH_UPDATE_INVESTORS: () =>  {
      const  { tokenAddress, investors } = {...payload}
      return transformState(state, ['polymath', tokenAddress, 'investors' ], investors)
    },
    R_ETH_POLYMATH_UPDATE_DETAILS: () =>  {
      const  { tokenAddress, details } = {...payload}
      return transformState(state, ['polymath', tokenAddress, 'details' ], details)
    },
    R_ETH_BALANCER_UPDATE_POOL: () =>  {
      const  { poolAddress, tokens, tokenMap, swapFee} = {...payload}
      return transformState(state, ['balancer', poolAddress], {tokens, tokenMap, swapFee})
    },
  }
  const reducer = reducers[type] || reducers['default']
  return reducer()
}

export default ETHReducers

