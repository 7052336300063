import React, { useState, useEffect, useMemo} from 'react';

import reduce from "lodash/reduce"
import { calcSuccess, calcFetching, calcError } from "utilities/misc"

export const useFetchOnMountV2 = (useHook, ...args) => {
  const [data, error, loading, fetch, resp, unmount=()=>console.warn('unmount prop is null') ] = useHook(...args)
  const [first,setFirst] = useState(true)
  useEffect(()=>{
    fetch()
    setFirst(false)
    return ()=>unmount()
  },[])
  const _loading = first || loading || (!data && !error)
  return [data, error, _loading, fetch, resp]
}

//what if failed at beginning?
export const useFetchOnMountV3 = (useHook, ...args) => {
  const [data, error, status, fetch, unmount=()=>console.warn('unmount prop is null') ] = useHook(...args)
  useEffect(()=>{
    fetch()
  },[])

  const _status = (status==null) || calcFetching(status)  ? 'FETCHING' : status
  return [data, error, _status, fetch, unmount]
}

export const useFetchOnChange = (data, fetch) => {
  const [mounted, setMounted] = useState(false)
  useEffect(()=>{
    mounted && fetch()
    setMounted(true)
  },[data])
}


export const useRefreshOnSuccess = (status, fetch) => {

  /*
  const rSuccess = useMemo(()=>reduce(status,(acc,cur)=>{
    if(calcSuccess(cur)) acc=true
    return acc
  },false),[status])
  */
  useEffect(()=>{
    if(calcSuccess(status)) fetch()
  },[status])
}

export const useOnError = (status, func) => {
  useEffect(()=>{
    if(calcError(status)) func()
  },[status])
}


/*
const useFetch = ({    
  dispatch,
  action,
  args,
  defaultData,
  refreshOn, 
  onSuccess=null,
  onError=null
}) => {
  const [ isFetching, setIsFetching ] = useState(true) 
  const [ data, setData ] = useState(defaultData || null) 
  const [ error, setError ] = useState(null)

  useEffect(() => {
    setIsFetching(true)
    setData(defaultData || null)
    setError(null)
    dispatch(action.promise(...args))
      .then(res => {
        setData(res)
        onSuccess && onSuccess(res)
      })
      .catch(err => {
        setError(err)
        onError && onError(err)
      })
      .finally(()=>{
        setIsFetching(false)
      })
  },[...refreshOn])

  useEffect(() => {
    return(()=>dispatch(action.unmount))
  },[])

  return ({ data, error, isFetching })
}

export default useFetch
*/