import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';

import fs from 'fs'

import Decimal from 'decimal.js';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import Container from '@mui/material/Container';
import Input from '@mui/material/Input';

import { useTranslation } from 'react-i18next';

import { 
  useImportUserCsvForm, useImportUserCsv
} from "./use"

import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess, useOnError } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { selectErrorSubtype } from "utilities/selectors"


export const ImportUsers = props => {
  const { import_admins=false} = {...props}
  const { t, i18n } = useTranslation();
  const { userId,  onSuccess=()=>null } = {...props}

  //const [ open, handleOpen, handleClose ]  = useMemoizedOpen()
  const [ regs, handleSubmit, errors, getValues ]  = useImportUserCsvForm();
  const [ fileReg ] = regs
  const [ data, error, status, submit, unmount ] = useImportUserCsv(import_admins)

  useRefreshOnSuccess(status, onSuccess)
  useUnmount([unmount])

  if(calcFetching(status)) return <LinearProgress/>

  return <Container maxWidth="md">
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
    <Card>
      <CardHeader
        subheader={
          import_admins ? 
          t('IMPORT_ADMINS_DESCRIPTION')
          :
          t('IMPORT_USERSDESCRIPTION')}
        title={
          import_admins ? 
          t('IMPORT_ADMINS')
          :
          t('IMPORT_USERS')
        }
      />
      <Divider />
      <CardContent>
        <Box sx={{mb:2}}>
          <FormControlLabel
            control={<Input type="file"/>}
            label={''}
            //sx={{width:1}}
            //fullWidth
            {...fileReg}
          />
        </Box>
      </CardContent>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button variant='contained' onClick={handleSubmit(submit)}>
          {t('SUBMIT')}
        </Button> 
      {/*
        <Button variant='contained' onClick={handleOpen}>
          {t('CHECK')}
        </Button>      
      */}
      </Box>
    </Card>
  </Container>
}


/*
function csvJSON(csv){

  var lines=csv.split("\n");
  var result = [];
  var headers=lines[0].split(",");
  for(var i=1;i<lines.length;i++){
    var obj = {};
    var currentline=lines[i].split(",");
    for(var j=0;j<headers.length;j++){
        obj[headers[j]] = currentline[j];
    }
    result.push(obj);
  }
  return JSON.stringify(result); //JSON
}
*/