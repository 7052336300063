import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"

import { joiValidate,  getTxListSchema}  from 'utilities/joi/sto'

export const useGetTxList = (networkId, tokenAddress) => {
  const action = 'ETH_WLTOKEN_GET_TX_LIST'
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3([action])

  const fetch = () => {
    dispatch({type: action, payload: { networkId, tokenAddress }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]  
}
