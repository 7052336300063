import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import PropTypes from 'prop-types';  

import Decimal from 'decimal.js';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import { useTranslation } from 'react-i18next';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import reverse from "lodash/reverse"
import get from "lodash/get"
import Joi from "joi";

import { 
  useGetTxList
} from "./use"

import { ValidateData } from 'components/molecules/Validate'
import { PaginationTable } from 'components/molecules/TableBase'
import { HandleErrorV3 } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"


import { createTokenHref, createTxHashHref } from 'utilities/ETH'


const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
    <Typography noWrap={true}>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}

const useTxColumn = (networkId,tokenAddress) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header:  t('BLOCK_NUMBER'),
        accessor: 'blockNumber',
      },
      {
        Header:  t('EVENT'),
        accessor: 'event',
      },
      {
        Header: t('DATA'),
        Cell: item=> {
          const event = get(item, ['row','original','event'])
          const decimals = 18
          if (event == 'RoleGranted' || event == 'RoleRevoked'){
            return <Fragment>
              <Info text={t('SENDER')} subtext={get(item, ['row','original','meta','sender'])}/>
              <Info text={t('WALLET_ADDRESS')} subtext={get(item, ['row','original','args','account'])}/> 
              <Info text={t('ROLE')} subtext={get(item, ['row','original','meta','role'])}/>
            </Fragment>        
          }
          if (event == 'Paused' || event == 'Unpaused'){
            return <Fragment>
              <Info text={t('SENDER')} subtext={get(item, ['row','original','meta','sender'])}/>
              <Info text={t('WALLET_ADDRESS')} subtext={get(item, ['row','original','args','account'])}/>
            </Fragment>        
          }
          //could be reduced
          if (event == 'Transfer'){
            if(get(item, ['row','original','meta','subtype']) == 'ISSUE') {
              return <Fragment>
                <Info text={t('SUBTYPE')} subtext={t('ISSUE')}/>
                <Info text={t('USER')} subtext={get(item, ['row','original','meta','to'])}/> 
                <Info text={t('WALLET_ADDRESS')} subtext={get(item, ['row','original','args','to'])}/> 
                <Info text={t('VALUE')} subtext={Decimal(get(item, ['row','original','args','value'])).mul(Decimal(10).pow(-decimals)).toDecimalPlaces(4).toString()}/>
              </Fragment>                 
            }
            if(get(item, ['row','original','meta','subtype']) == 'BURN') {
              return <Fragment>
                <Info text={t('SUBTYPE')} subtext={t('BURN')}/>
                <Info text={t('USER')} subtext={get(item, ['row','original','meta','from'])}/> 
                <Info text={t('WALLET_ADDRESS')} subtext={get(item, ['row','original','args','from'])}/> 
                <Info text={t('VALUE')} subtext={Decimal(get(item, ['row','original','args','value'])).mul(Decimal(10).pow(-decimals)).toDecimalPlaces(4).toString()}/>
              </Fragment>                 
            }
            return <Fragment>
              <Info text={t('FROM')} subtext={get(item, ['row','original','meta','from'])}/>
              <Info text={t('FROM_WALLET')} subtext={get(item, ['row','original','args','from'])}/>
              <Info text={t('TO')} subtext={get(item, ['row','original','meta','to'])}/> 
              <Info text={t('TO_WALLET')} subtext={get(item, ['row','original','args','to'])}/> 
              <Info text={t('VALUE')} subtext={Decimal(get(item, ['row','original','args','value'])).mul(Decimal(10).pow(-decimals)).toDecimalPlaces(4).toString()}/>
            </Fragment>        
          }
          return <Fragment>
            {JSON.stringify({ args: get(item, ['row','original','args']), meta: get(item, ['row','original','meta'])},0,2)}
          </Fragment>
        }
      },
      {
        Header: t('TX_HASH'),
        accessor: 'transactionHash',
        Cell: item=>          <Link 
          href={createTxHashHref(networkId,item.value)}
          target='blank'
        >
          {item.value}
        </Link>
      },
    ]
  ,[i18n.language])
  return columns  
}



const dataSchema = {
  'events': Joi.array().items(
    Joi.object()
  ).required(),
}


export const TxTable = props => {
  const { tokenAddress, networkId } = {...props}
  const { t, i18n } = useTranslation();
  const [data, error, status, fetch, unmount] = useFetchOnMountV3(useGetTxList, networkId, tokenAddress)
  const columns = useTxColumn(networkId, tokenAddress)

  useUnmount([ unmount ])
  //useRefreshOnSuccess([status2, status3], fetch)
  useFetchOnChange(networkId, fetch)

  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>
  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING')}</Fragment>
  
  //return <pre>{JSON.stringify(data,0,2)}</pre>

  return <ValidateData data={data} schema={dataSchema}>
    <PaginationTable columns={columns} data={reverse(data['events'])} />
  </ValidateData>
}

TxTable.propTypes = {  
  tokenAddress: PropTypes.string.isRequired,  
  networkId: PropTypes.number.isRequired,  
}  