import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';


import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { investorTableSchema} from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"

export const useGetInvestors = (networkId, tokenAddress) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_GET_INVESTORS'
  const [data, error, status] = useThunkDataV3([action])
  const fetch = () => {
    dispatch({type: action, payload: { tokenAddress, networkId }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]
}
/*
export const useAddToWhitelist = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3(['ETH_WLTOKEN_ADD_TO_WHITELIST_SIGN', tokenAddress])

  const add = data => {
    const { private_key, list } = {...data}
    const _list = Array.isArray(list) ? list : [list]
    const investorAddresses = _list.map(item=>item)
    dispatch({
      type: 'ETH_WLTOKEN_ADD_TO_WHITELIST_SIGN', 
      payload: { 
        tokenAddress, 
        investorAddresses: investorAddresses, 
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, 'ETH_WLTOKEN_ADD_TO_WHITELIST_SIGN')
  return [ data, error, status, add, unmount]
}

export const useRemoveFromWhitelist = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const [data, error, status] = useThunkDataV3(['ETH_WLTOKEN_REMOVE_FROM_WHITELIST_SIGN', tokenAddress])

  const remove = data => {

    const { private_key, list } = {...data}
    const _list = Array.isArray(list) ? list : [list]
    const investorAddresses = _list.map(item=>item)
    dispatch({
      type: 'ETH_WLTOKEN_REMOVE_FROM_WHITELIST_SIGN', 
      payload: { 
        tokenAddress, 
        investorAddresses: investorAddresses, 
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, 'ETH_WLTOKEN_REMOVE_FROM_WHITELIST_SIGN')
  return [ data, error, status, remove, unmount]
}

export const useAddToWhitelistForm = () => {
  const { register, handleSubmit, watch, formState: {errors, isValid}, resetField  } = useForm({
    resolver: joiResolver(investorTableSchema)
  });
  const watchAdd = watch("list", [])
  const addLength = watchAdd ? Array.isArray(watchAdd) ? watchAdd.length : 1: 0
  return [register('list'), register('private_key'), handleSubmit, errors, addLength,isValid, ()=>resetField('private_key')]
}

export const useRemoveFromWhitelistForm = () => {
  const { register, handleSubmit, watch, formState: {errors, isValid}, resetField} = useForm({
    resolver: joiResolver(investorTableSchema)
  });

  const watchRemove = watch("list", [])
  const removeLength = watchRemove ? Array.isArray(watchRemove) ? watchRemove.length : 1: 0
  return [register('list'), register('private_key'), handleSubmit, errors, removeLength,isValid, ()=>resetField('private_key')]
}

*/