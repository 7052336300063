import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

//import * as actions from './actions'
//import * as asyncs from './asyncs'
/*
import { handleAsync, handleWeb3, handleES, 
  handleContract, putSuccess, putFetching, putFailed,
  handleFetch
} from './helpers'
*/

import axios from 'axios'
import Web3 from "web3";
import qs from 'qs'



/*
import { 
  wLoadGanache, wLoadUniswap, wRestrictSupply, wStorageSet, wWatchStorage, 
  wContractCall, wWatchContractEvent, wContractSend, wWeb3Call,
  wV2Trade, wUniswapCreateExchange, wUniswapLoadExchange, wDeployContract, wLoadTruffle,
  wLoadRopsten, wLoadKovan, wLoadMainnet, wGetContractEvent, wContractSendwApprove
} from './truffle/state'

import { wLoadMetaMask, wLoadAccount, wWatchAccount, wLoadNetwork, wWatchNetwork, wWatchRPC, wLoadNetworkV2 } from './metamask/sagas'

import { 
  wLoadPolymathTokens, wLoadPolymathToken, 
  wInvestors, wDetails, wLoadConfig
} from './polymath'
*/
import { stoWatchAsync } from './sto'
import { polymathWatchAsync } from './polymath'
import { metamaskWatchAsync } from './metamask'
import { wltokenWatchAsync } from './wltoken'
import { swapcatWatchAsync } from './swapcat'
//import { wLoadUniswapExchanges } from './uniswap'
//import { wLoadBalancerPools, wLoadBalancerPool, wLoadPoolData } from './balancer'
//import { wLoadERC20Tokens, wLoadERC20Token, wFetchBalance, wERC20Approve } from './ERC20'
//import { wGetEthBalance, wGetEthExchangeRate } from './portfolio'
//import { Polymath, browserUtils } from '@polymathnetwork/sdk';


const etherscan_url = 'https://api-ropsten.etherscan.io/api'
const w3 = new Web3()

/**************
// ROOT
//
****************/
export default function* ETHRoot(backend_url) {
  
  const apiES = axios.create({
    baseURL: etherscan_url,
    //headers: {'User-Agent': 'Anything Else'} 
  })
  const backEnd = axios.create({
    baseURL: backend_url
  })
  const requestChannel = yield call(channel)

  const ethereum  = window.ethereum

  yield setContext({ 
    apiES, 
    backEnd, 
    w3, 
    ethereum,
    contracts: {}
  })

  yield all([
    //watchAsync(),
    stoWatchAsync(),
    polymathWatchAsync(),
    metamaskWatchAsync(),
    wltokenWatchAsync(),
    swapcatWatchAsync()
  ])
}
/*
function* wConnectAccount(type, payload, meta) {
  const ethereum  = yield getContext('ethereum')
  yield putFetching(type)
  yield delay(500)
  try {
    const res = yield ethereum.request({ method: 'eth_requestAccounts' })
    yield putSuccess(type)
  }
  catch(err){
    const {message} = {...err}
    yield putFailed(type,message)
    return false
  }
  
  return true
}
*/
/*
function* handleComposite(names, endpoints, nParams, callbackChans) {
  yield delay(1000)
  //console.log(names, endpoints, nParams)
  const backEnd = yield getContext('backEnd')
  const resp = yield backEnd.post(
    '/eth/composite', 
    {endpoints, ...nParams}, 
    //token ? createTokenHeader(token) : null
  )

  const payloads = resp['data']['payload'].map(item=>item['data']['payload'])
  function* handleCallback(chan,index) {
    const thisPayload = payloads[index] || {}
    yield put(chan, thisPayload)
  }
  
  yield all(callbackChans.map(handleCallback))
}


function* handleRequestChannel(chan) {
  let task
  const names = []
  const endpoints = []
  let nParams = {}
  const callbackChans = []
  while (true) {
    const { name, endpoint, params, callbackChannel } = yield take(chan)
    //console.log( name, endpoint, params, callbackChannel)
    names.push(name)
    endpoints.push(endpoint)
    callbackChans.push(callbackChannel)
    nParams = {...nParams, ...params}
    if (task) {
      yield cancel(task)
    }
    task = yield fork(handleComposite, names, endpoints, nParams, callbackChans)
  }  
}
*/
/*
const ss_abi = [{'constant': false, 'inputs': [{'name': 'num', 'type': 'uint256'}], 'name': 'set', 'outputs': [], 'payable': false, 'stateMutability': 'nonpayable', 'type': 'function'}, {'constant': true, 'inputs': [], 'name': 'get', 'outputs': [{'name': '', 'type': 'uint256'}], 'payable': false, 'stateMutability': 'view', 'type': 'function'}, {'anonymous': false, 'inputs': [{'indexed': true, 'name': '_from', 'type': 'address'}, {'indexed': false, 'name': '_num', 'type': 'uint256'}], 'name': 'Set', 'type': 'event'}]
const ss_contract_address = '0x22F8c7406910fee9Fb110C59CcCb053A06068B9d'
const sp_contract_address = '0xedd76DC81abf515B5B80a24e9941a7c6E017F8B6'
const shop_contract_address = '0xa8B9B5874B3D4F873ADF404c7Dd43b35b73D8Ad3'
const estate_contract_address = '0x866fEBd9B909caf3Eb652f48B41Dc0CcbB26309B'
//const ss_contract = new w3.eth.Contract(ss_abi, ss_contract_address);

//const w3 = new Web3(window.ethereum) // dont place within saga. this will create multiple instances
const w3 = new Web3()

const ss_contract = new w3.eth.Contract(ss_abi, ss_contract_address);
const sp_contract = new w3.eth.Contract(sp_abi, sp_contract_address);
const shop_contract = new w3.eth.Contract(shop_abi, shop_contract_address);
const estate_contract = new w3.eth.Contract(estate_abi, estate_contract_address);
*/
//const ERC20_contract= new w3.eth.Contract(ERC20_abi, '0xaD6D458402F60fD3Bd25163575031ACDce07538D');

/*
function* wGetTxHistory(type, payload, meta) {
  const { address } = {...payload}
  yield call(handleES, 
    type,
    {  
      'module': 'account',
      'action': 'txlist',
      'address': address,
      //'startblock': 0,
      //'endblock': 99999999,
      'sort': 'asc', 
    }
  )   
}
*/


/*
function* wWatchOrders() {  
  const contract_address = shop_contract_address
  const _abi = shop_abi
  const event = 'NewOrder'
  const topics = ['address']
  const w3 = yield getContext('w3')

  const ethereum = yield getContext('ethereum')
  
  const addresses = yield ethereum.request({ method: 'eth_accounts' })
  const selectedAddress = addresses[0] || '0x0'
  const channel = eventChannel(emitter => {

    const set_event = find(_abi, {name: event, type: 'event'})
    const set_event_inputs = set_event['inputs']
    const encoded_event = w3.eth.abi.encodeEventSignature(set_event); 
    function createTopics(topics){
      const topic_list = []
      topics.forEach(topic=>{
        if(topic === 'address') {
          topic_list.push(w3.eth.abi.encodeParameter('address', selectedAddress))
        }
      })
      return topic_list
    }
    w3.eth.subscribe('logs', {
      fromBlock: 'latest',
      address: contract_address, //Smart contract address
      topics: [encoded_event,...createTopics(topics)] //topics for events
    }, function(error, result){
      if (error) console.log(error);
    }).on("data", function (trxData){
      console.log("Event received", trxData);
      //emitter([event.rName, trxData])          
    });
  
    // Return an unsubscribe method        
    return () => {            
      // Perform any cleanup you need here                 
    };    
  });    
  try {  
    while (true) {        
      const [type, payload] = yield take(channel);    
      yield putFetching(type)
      yield wShopGetOrders()
      yield putSuccess(type)
    }
  } finally {
    if (yield cancelled()) {
      channel.close()
    }    
  }

}
*/

/*
function* watchEvents(contract_address, _abi, events) {  
  const w3 = yield getContext('w3')
  const ethereum = yield getContext('ethereum')

  const addresses = yield ethereum.request({ method: 'eth_accounts' })
  const selectedAddress = addresses[0] || '0x0'
  const channel = eventChannel(emitter => {

    //updates  on any estate log
    w3.eth.subscribe('logs', {
      fromBlock: 'latest',
      address: estate_contract_address, //Smart contract address
      topics: [] //topics for events
    }, function(error, result){
      if (error) console.log(error);
    }).on("data", function (trxData){
      console.log("Event received", trxData);
      emitter(['ETH_ESTATE_REFRESH', trxData])          
    }); 

    events.forEach(event=> {
      const set_event = find(_abi, {name: event.name, type: 'event'})
      const set_event_inputs = set_event['inputs']
      const encoded_event = w3.eth.abi.encodeEventSignature(set_event); 
      function createTopics(topics){
        const topic_list = []
        topics.forEach(topic=>{
          if(topic === 'address') {
            topic_list.push(w3.eth.abi.encodeParameter('address', selectedAddress))
          }
        })
        return topic_list
      }
      w3.eth.subscribe('logs', {
        fromBlock: 'latest',
        address: contract_address, //Smart contract address
        topics: [encoded_event,...createTopics(event.topics)] //topics for events
      }, function(error, result){
        if (error) console.log(error);
      }).on("data", function (trxData){
        console.log("Event received", trxData);
        emitter([event.rName, trxData])          
      });

    
    })
 
    // Return an unsubscribe method        
    return () => {            
      // Perform any cleanup you need here                 
    };    
  });    
  // Process events until operation completes    
  while (true) {     
    const [type, payload] = yield take(channel); 
         
    // Handle the data...
    yield call(async_map[type], type, payload)
    yield put({type: 'R_ETH_TX_STATUS_STANDBY'})
    //yield putSuccess(name, data)  
  }
}
*/