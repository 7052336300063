import React, { useState, useEffect, Fragment, useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Controller } from "react-hook-form";

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import Container from '@mui/material/Container';

import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import { useManage, useManageForm, useGetUserRoles } from "./use"

import { 
  useGetUsersV2, useGetWallets 
} from "components/organisms/ETH/sto/CreateFund/use"

import { WalletList, EmailCell } from 'components/molecules/TableCells'
import { Toolbar } from 'components/molecules/Toolbar'
import { PaginationTable } from 'components/molecules/TableBase'
import { PKButton } from 'components/molecules/PKButton'
import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"


const switches = [
  {
    formName: "roles.MINTER_ROLE",
    label: "MINTER_ROLE"
  },
  {
    formName: "roles.PAUSER_ROLE",
    label: "PAUSER_ROLE"
  },
  {
    formName: "roles.BURNER_ROLE",
    label: "BURNER_ROLE"
  },
  {
    formName: "roles.WHITELIST_ADMIN_ROLE",
    label: "WHITELIST_ADMIN_ROLE"
  },  
]


const RoleSwitches = props => {
  const { t, i18n } = useTranslation();
  const { networkId, tokenAddress, walletAddress, roleData } = {...props}
  const [ regs, handleSumbit, { dirtyFields } , resetPK, control ] = useManageForm(roleData)
  const [ adminReg, pauserReg ] = regs
  const [ data, error, status, onSubmit, unmount ] = useManage(    
    networkId,
    tokenAddress,
    walletAddress,
    dirtyFields
  )

  return <Card>
    <HandleSendingAndPending sending={[status]} pending={[status]}/>
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
    <CardHeader
      subheader={t('ROLE_ASSIGNMENT_DESCRIPTION')}
      title={t('ROLE_ASSIGNMENT')}
    />
    <Divider />
    <CardContent>
      <FormControl component="fieldset" variant="standard">
        <FormLabel component="legend">{t('ASSIGN_ROLE')}</FormLabel>
        <FormGroup>
          {switches.map(item=>
            <FormControlLabel control={          
              <Controller
                name={item['formName']}
                control={control}
                render={({ field }) => (
                  <Switch
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                  />
                )}
              />} 
              label={t(item['label'])} 
            />
          )}
          {t("PRIVATE_KEY")} 
          <Controller
            name="private_key"
            control={control}
            render={({ field }) => (
              <TextField
                onChange={(e) => field.onChange(e.target.value)}
                //checked={field.value}
              />
            )}
          />

          <Button 
            variant='contained' 
            onClick={handleSumbit(onSubmit)}
          >{t('UPDATE')}</Button>
        </FormGroup>

        {/*<FormHelperText>Be careful</FormHelperText>*/}
      </FormControl>
    </CardContent>
  </Card>  
}

export function ManageRoles(props) {
  const { t, i18n } = useTranslation();
  const { networkId, tokenAddress, walletAddress } = {...props}
  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useGetUserRoles, 
    networkId, 
    tokenAddress,
    walletAddress,
    ['PAUSER_ROLE', 'MINTER_ROLE', 'BURNER_ROLE', 'WHITELIST_ADMIN_ROLE']
  )
  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>

  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return <Fragment>

    <RoleSwitches 
      roleData={get(data,['roles'],{})} 
      networkId={networkId} 
      tokenAddress={tokenAddress} 
      walletAddress={walletAddress}
    />
  </Fragment>
}



const useColumns = (handleAddress) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('INVESTOR_ID'),
        accessor: 'user_id',
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
        //Cell: EmailCell
      },
      {
        Header:  t('WALLET'),
        accessor: 'wallet',
        Cell: item=>  <Button variant='outlined' onClick={()=>handleAddress(item.value)}>
          {item.value}
        </Button>
      },

    ]
  ,[i18n.language])
  return columns
}

export const MRUserTable = props => {
  const { t, i18n } = useTranslation();
  const { networkId, tokenAddress, handleSuccess, handleAddress } = {...props}

  const [
    data, error, status, fetch, unmount 
  ] = useFetchOnMountV3(useGetWallets)
  
  const columns = useColumns(handleAddress)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return <Fragment>
    <PaginationTable columns={columns} data={data['wallets']} />
  </Fragment>
  
}

export const UsersTableV3 = props => {
  const { tokenAddress, handleAddress, networkId } = {...props}
  const { t, i18n } = useTranslation();

  const [
    data, error, status, fetch, unmount 
  ] = useFetchOnMountV3(useGetUsersV2, networkId, tokenAddress, ['BALANCE', 'IS_WHITELISTED'])

  const columns = useColumns(handleAddress)

  useUnmount([ unmount])
  //useRefreshOnSuccess(status2, fetch)
  //useFetchOnChange(networkId, fetch)

  if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  //return <pre>{JSON.stringify(data,0,2)}</pre>
  return <Fragment>
    {/*
    <Toolbar routes={[  
      {
        routeFunc: handleOpen,
        text: "BURN_ALL",
        icon: AddIcon,
        variant: 'contained'
      }
    ]}/>
    */}
    <Container maxWidth={true}>
      <PaginationTable columns={columns} data={data} />
    </Container>
    

  </Fragment>

}
