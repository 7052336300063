import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { useLoadUserRoles } from "./use"

import { HandleErrorV3 } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3 } from 'components/useHooks/useFetch'
//import { useStyles } from 'components/useHooks/useStyles'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { useUnmount } from 'components/useHooks/useUnmount'
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"



export const LoadUserRoles = props => {
  const { children, tokenAddress, networkId } = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(
    useLoadUserRoles, tokenAddress, networkId
  )
  useFetchOnChange(networkId, fetch)
  useUnmount([unmount])
  
  if(calcFetching(status)) return <Fragment><LinearProgress/>{t('LOADING_USER_ROLES')}</Fragment>
  if(calcError(status)) return <HandleErrorV3 type={selectErrorSubtype(error)}/>

  return <>
    {/*<pre>{JSON.stringify({data, error, status},0,2)}</pre>*/}
    {children}
  </>
}