import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"
import { pauseSchema } from "utilities/joi/sto"


export const useGetPause = (networkId, tokenAddress) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_PAUSE'
  const [data, error, status] = useThunkDataV3([action])

  const fetch = () => {
    dispatch({type: action, payload: { networkId }})
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [data, error, status, fetch, unmount]  
}



export const usePause = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_PAUSE'
  const [data, error, status] = useThunkDataV3([action])

  const pause = data => {
    const { private_key } = {...data}
    dispatch({
      type: action, 
      payload: { 
        tokenAddress, 
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  
  return [ data, error, status, pause, unmount]
}

export const usePauseForm = () => {
  const { register, handleSubmit, watch, formState: {errors, isValid, touchedFields}, resetField  } = useForm({
    resolver: joiResolver(pauseSchema)
  });
  return [
    [register('private_key')], 
    handleSubmit, 
    {
      errors: {
        private_key: get(errors,['private_key','type'], null),
      },
      isValid,
      touchedFields
    }, 
    ()=>resetField('private_key')
  ]
}

export const useUnpause = (networkId, tokenAddress, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_UNPAUSE'
  const [data, error, status] = useThunkDataV3([action])

  const pause = data => {
    const { private_key } = {...data}
    dispatch({
      type: action, 
      payload: { 
        tokenAddress, 
        privateKey: private_key,
        networkId
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  
  return [ data, error, status, pause, unmount]
}

export const useUnpauseForm = () => {
  const { register, handleSubmit, watch, formState: {errors, isValid, touchedFields}, resetField  } = useForm({
    resolver: joiResolver(pauseSchema)
  });
  return [
    [register('private_key')], 
    handleSubmit,
    {       
      errors: {
        private_key: get(errors,['private_key','type'], null),
      },
      isValid,
      touchedFields
    },
    ()=>resetField('private_key')]
}