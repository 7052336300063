import React, { useState, useEffect, Fragment, useMemo, createRef, forwardRef} from 'react';
import PropTypes from 'prop-types';  

import Joi from "joi";
import Decimal from 'decimal.js';
import { useTable, useFilters, usePagination } from 'react-table'

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Input from '@mui/material/Input';
import CardContent from '@mui/material/CardContent';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';

import reduce from "lodash/reduce"
import isEmpty from "lodash/isEmpty"
import get from "lodash/get"

import { useIssueStoForm, useIssueSto } from "./use"
//import { useGetWallets } from "components/organisms/ETH/sto/CreateFund/use"

import { FormControlLabel_ } from 'components/molecules/Forms'
import { ValidateData } from 'components/molecules/Validate'
import { Toolbar } from 'components/molecules/Toolbar'
import { PaginationTable, SelectValueFilter } from 'components/molecules/TableBase'
import { PKButton } from 'components/molecules/PKButton'
import { HandleErrorV2, HandleSending, HandlePending, HandleRefresh, HandleSendingAndPending } from 'components/molecules/HandleStatus'
import { useFetchOnMountV3, useRefreshOnSuccess } from 'components/useHooks/useFetch'
import { useStyles } from 'components/useHooks/useStyles'
import { useMemoizedOpen } from 'components/useHooks/useOpen'
import { useUnmount } from 'components/useHooks/useUnmount'
import { useFetchOnChange } from "components/useHooks/useFetch"
import { calcFetching, calcError } from "utilities/misc"
import { selectErrorSubtype } from "utilities/selectors"

const Info = props => {
  const { text, subtext } = {...props}
  return <Box sx={{ flexDirection: 'row', display: 'flex', alignItems: 'flex-end' }}>
    <Typography>
      {text}:
    </Typography>
    <Typography sx={{ ml: 1.5 }} color="text.secondary">
      {subtext}
    </Typography>
  </Box>
}


const useColumns = (reg) => {
  const { t, i18n } = useTranslation();
  const columns = useMemo(
    () => [
      {
        Header: t('SELECT'),
        Cell: item => <FormControlLabel 
          value={item.row.original['wallet']} 
          control={<Radio />} 
          label={""}  
          {...reg}
        />
      },
      {
        Header: t('USER_ID'),
        accessor: 'user_id',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('FIRST_NAME'),
        accessor: 'first_name',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('LAST_NAME'),
        accessor: 'last_name',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('EMAIL'),
        accessor: 'email',
        //Filter: SelectValueFilter,
      },
      {
        Header:  t('WALLET_ADDRESS'),
        accessor: 'wallet',
        //Filter: SelectValueFilter,
      }
    ]
  ,[i18n.language])
  return columns  
}

const dataSchema = {
  'investors': Joi.array().items(
    Joi.object({
      investor_id: Joi.number().required(),
      email: Joi.string().required(),
      address: Joi.string().required(),
      balance: Joi.number().unsafe().required(),
      isWhitelisted: Joi.boolean().required(),
    }).required()
  ).required(),
}

/*
export const _IssueSto = props => {
  const { tokenAddress, networkId, onSuccess=()=>null} = {...props}
  const { t, i18n } = useTranslation();

  const [data, error, status, fetch, unmount] = useFetchOnMountV3(useGetWallets)

  const [[pkReg, addressReg, amountReg], handleSumbit, {
      errors: { private_key: pkError, token_amount: amountError }, 
      touchedFields:{ private_key: pkTouched, token_amount: amountTouched },
      fields: { walletAddress }
    }, reset
  ] = useIssueStoForm()

  
  const columns = useColumns(addressReg)
  
  
  const [ data2, error2, status2, onSubmit, unmount2 ] = useIssueSto(
    networkId,  
    tokenAddress, 
  )
  
  useUnmount([ unmount, unmount2 ])
  useRefreshOnSuccess(status2, ()=> {
    reset()
    onSuccess()
  })
  //useFetchOnChange(networkId, fetch)

  //if(calcFetching(status)) return <LinearProgress/>
  if(calcError(status)) return <Alert>error</Alert>
  //return <PaginationTable columns={columns} data={data['wallets']} />
  //return <pre>{JSON.stringify(data,0,2)}</pre>

  return <Fragment>
    <PKButton 
      PKReg={pkReg} 
      handleSend={handleSumbit(onSubmit)}
      touched={pkTouched}
      errText={t(pkError)}
      disabled={!walletAddress}
      title={t('ISSUE')}
      subheader={t('ISSUE_STO_DESCRIPTION')}
    >
      {walletAddress ? <Fragment>
        <Info text={t('ISSUE_STO')} subtext={walletAddress}/>
          <FormControlLabel_ 
            label={t("TOKEN_AMOUNT")} 
            reg={amountReg} 
            touched={amountTouched}
            errText={amountError}
          />
        </Fragment>
        :
        <div>{t("PLEASE_SELECT_WALLET_ADDRESS")}</div>
      }
    </PKButton>
    <Box p={1}/>
    <RadioGroup name="radio-buttons-group">
      <PaginationTable columns={columns} data={data['wallets']} />
    </RadioGroup>
    <HandleSendingAndPending sending={[status2]} pending={[status2]}/>
    <HandleErrorV2 status={status2} type={selectErrorSubtype(error)}/>
  </Fragment>

}
*/
/*
IssueSto.propTypes = {  
  tokenAddress: PropTypes.string.isRequired,  
  networkId: PropTypes.number.isRequired,  
}  

*/

export const IssueSto = props => {
  const { t, i18n } = useTranslation();
  const { tokenAddress, networkId, onSuccess=()=>null} = {...props}

  const [ 
    [pkReg,fileReg], handleSubmit, { errors, touchedFields  }, resetField 
  ]  = useIssueStoForm()

  const [ data, error, status, submit, unmount ] = useIssueSto(
    networkId,  
    tokenAddress, 
  )

  useRefreshOnSuccess(status, () => {
    resetField()
    onSuccess()
  })
  useUnmount([unmount])

  //if(calcFetching(status)) return <LinearProgress/>
  //if(calcError(status)) return <Alert>error</Alert>

  return <Container maxWidth="md">
    <HandleSendingAndPending sending={[status]} pending={[status]}/> 
    <HandleErrorV2 status={status} type={selectErrorSubtype(error)}/>
    <Card>
      <CardHeader
        subheader={t('ISSUE_STO_DESCRIPTION')}
        title={t('ISSUE_STO')}
      />
      <Divider />
      <CardContent>
        <Box sx={{mb:2}}>
          <FormControlLabel
            control={<Input type="file"/>}
            label={''}
            {...fileReg}
          />
          <FormControlLabel_ 
            //name={"private_key"} 
            label={t("PRIVATE_KEY")} 
            reg={pkReg}
            touched={touchedFields[pkReg['name']]}
            errText={t(errors[pkReg['name']])}
          />
        </Box>
      </CardContent>

      <Divider />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button variant='contained' onClick={handleSubmit(submit)}>
          {t('SUBMIT')}
        </Button> 
      {/*
        <Button variant='contained' onClick={handleOpen}>
          {t('CHECK')}
        </Button>      
      */}
      </Box>
    </Card>
  </Container>
}
