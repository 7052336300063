import React, {Fragment} from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';

import UploadIcon from '@mui/icons-material/Upload';


export const Toolbar = props => {
  const { 
    routes  
  } = { ...props }
  const { t, i18n } = useTranslation();
  return <Fragment>
    <Stack 
      spacing={1} 
      direction="row" 
      justifyContent="flex-end"
      alignItems="center"
    >
      {routes.map( (item, idx) => {
        const { routeFunc, text='DEFAULT', Icon, variant='outlined' } = {...item}
        const Icon_ = Icon ? Icon : UploadIcon
        return <>
          <Button
            startIcon={(<Icon_ fontSize="small" />)}
            //sx={{ mr: 1 }}
            variant={variant}
            onClick={()=>routeFunc()}
            key={idx}
          >
            {t(text)}
          </Button> 
        </>
      })}
    </Stack>
  </Fragment>
}

Toolbar.propTypes = {
  routes: PropTypes.array.isRequired,
};
