import React, {Fragment, useMemo}  from 'react';

import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import SvgIcon from '@mui/material/SvgIcon';
import SearchIcon from '@mui/icons-material/Search';

import { useTable, useFilters, usePagination } from 'react-table'


export function SelectValueFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length
  return <TextField
    size='small'
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SvgIcon
            fontSize="small"
            color="action"
          >
            <SearchIcon />
          </SvgIcon>
        </InputAdornment>
      )
    }}
    placeholder={`Search ${count} items...`}
    onChange={e => {
      setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
    }}
    size="small"
    variant="standard" 
    color="success"
  />
}

const SearchField = ({handleChange=()=>null, ...rest}) => <TextField
  fullWidth
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <SvgIcon
          fontSize="small"
          color="action"
        >
          <SearchIcon />
        </SvgIcon>
      </InputAdornment>
    )
  }}
  //placeholder={placeholder}
  onChange={e => {
    handleChange(e.target.value || undefined) // Set undefined to remove the filter entirely
  }}
  variant="standard"
  size="small"
  {...rest}
/>


export const inputValueFilter = (props) => ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length
  return <SearchField handleChange={setFilter} {...props} />
}


export const TableBase2 = props => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: NullColumnFilter,
    }),
    []
  )

  const { columns, data, onRowClick=()=>null } = {...props}
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn
  }, useFilters)

  return (
    <Table 
      {...getTableProps()} 
      style={{
        //borderCollapse: 'separate'
        borderCollapse: 'collapse'
      }}
      width={1}
      p={2}
    >
      <TableHead>
        {headerGroups.map(headerGroup => (
          
          <TableRow {...headerGroup.getHeaderGroupProps()} style={{'borderBottom': '1px solid #000'}}>

            {headerGroup.headers.map(column => (

              <TableCell
                {...column.getHeaderProps()} 
                py={1} 
                px={[1,2,3]}
                borderBottom='thick'
                borderColor='#D3D3D3'
                width = {1}
              > 
                <Stack spacing={0}>
                  <Box>{column.render('Header')}</Box>
                  <Box>{column.canFilter ? column.render('Filter') : null}</Box>
                </Stack>
              </TableCell>

            ))}
          </TableRow>
        
        ))}
      </TableHead>
      <TableBody>
        {rows.map(
          (row, i) =>
            prepareRow(row) || (

              <TableRow 
                {...row.getRowProps()} 
                //bg={row.index % 2 === 0 && '#D3D3D3'}
                //border='normal'
                borderBottom='normal'
                borderColor='#D3D3D3'
                p={2}
                onClick={()=>onRowClick(row.original)}
              >

                {row.cells.map(cell => {
                  //if(cell.column.id === 'time_stamp' ){ return <td {...cell.getCellProps()}>{cell.render(model=><Moment unix format="YYYY/MM/DD HH:mm">{model.cell.value}</Moment>)}</td> }
                  return <TableCell  {...cell.getCellProps()} py={1} px={[1,2,3]}>{cell.render('Cell')}</TableCell>
                })}

              </TableRow>
            )
        )}
      </TableBody>
    </Table>
  )
}

//needed to avoid crash due to some kind of rerender
export const NullColumnFilter = () => null

export const PaginationTable = props => {
  const defaultColumn = useMemo(
    () => ({Filter: NullColumnFilter}),[]
  )
  const { t, i18n } = useTranslation();
  const { columns, data, onRowClick=()=>null } = {...props}
  const { 
    getTableProps, headerGroups, rows, prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    page,
    state: { pageIndex, pageSize },
  } = useTable({
    columns,
    data,
    defaultColumn
  }, useFilters, usePagination)

  return <Fragment>

    <Card>
      {/*<PerfectScrollbar>*/}
        
          <Table {...getTableProps()} >
            <TableHead>
              {headerGroups.map(headerGroup => (              
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <TableCell{...column.getHeaderProps()}>                     
                      <Stack spacing={0} >
                        {column.render('Header')}
                        {column.canFilter ? column.render('Filter') : null}
                      </Stack>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {page.map((row, i) =>{
                prepareRow(row)
                return <TableRow 
                  {...row.getRowProps()} 
                  onClick={()=>onRowClick(row.original)}
                >
                  {row.cells.map(cell => <TableCell  {...cell.getCellProps()} >
                    {cell.render('Cell')}
                  </TableCell>)}
                </TableRow>
              })}
            </TableBody>
          </Table>
        
      {/*</PerfectScrollbar>*/}
      <TablePagination
        component="div"
        count={data.length}
        onPageChange={(e,pageNum)=>gotoPage(pageNum)}
        onRowsPerPageChange={(e)=>setPageSize(e.target.value)}
        page={pageIndex}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage={t('ROWS_PER_PAGE')} 
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${t('OF')} ${count}`}
      />
    </Card>
  </Fragment>
}
/*
export const TableCards = props => {
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: NullColumnFilter,
    }),
    []
  )
  const { columns, data, onRowClick=()=>null } = {...props}
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    defaultColumn
  }, useFilters)

  return rows.map((row, i) => {
    if (prepareRow(row)) return <div>preparing row...</div>
    return <pre>{JSON.stringify(row.original,0,2)}</pre>
  })
*/