import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import Joi from "joi";
import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';
import reduce from "lodash/reduce"
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

import { ethAddress } from "utilities/joi/sto"

import { createUnmountDispatch } from "utilities/ETH"


export const useImportSto = (networkId, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_IMPORT_STO'
  const [data, error, status] = useThunkDataV3([action])

  const submit = formdata => {
    const { stoAddress } = {...formdata}
    dispatch({
      type: action, 
      payload: { 
        networkId,
        stoAddress
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [ data, error, status, submit, unmount]
}


export const useImportStoForm = () => {
  const { register, handleSubmit, formState: {errors, touchedFields}, resetField} = useForm({
    resolver: joiResolver(Joi.object({
      stoAddress: ethAddress()
    }))
  });

  return [ 
    [register('stoAddress')],
    handleSubmit, 
    ()=>resetField('privateKey'),
    { touchedFields, errors: { stoAddress: get(errors,['stoAddress', 'message'], null)}}
  ]
}