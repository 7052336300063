import { useSelector } from 'react-redux'
import { 
  selectPending,
  selectData,
  selectFetching,
  selectError,
  selectMeta,
  selectDataV2,
  selectErrorV2,
  selectFetchingV2,
  selectPendingV2,
  selectStatusV2
} from 'utilities/selectors'



export const useThunkData = (type, defaults = []) => {
  const data = useSelector(selectData(type, defaults[0]))
  const error = useSelector(selectError(type, defaults[1]))
  const pending = useSelector(selectPending(type))
  const fetching = useSelector(selectFetching(type, defaults[2]))
  return [
    data, error, fetching, pending
  ]
}

export const useThunkDataV2 = (path, defaults = []) => {
  const data = useSelector(selectDataV2(path, defaults[0]))
  const error = useSelector(selectErrorV2(path, defaults[1]))
  const pending = useSelector(selectPendingV2(path))
  const fetching = useSelector(selectFetchingV2(path, defaults[2]))
  return [
    data, error, fetching, pending
  ]
}

export const useThunkDataV3 = (path, defaults = []) => {
  const data = useSelector(selectDataV2(path, defaults[0]))
  const error = useSelector(selectErrorV2(path, defaults[1]))
  //const pending = useSelector(selectPendingV2(path))
  //const fetching = useSelector(selectFetchingV2(path, defaults[2]))
  const status = useSelector(selectStatusV2(path, defaults[2]))
  return [
    data, error, status
  ]
}
