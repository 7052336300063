import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Badge, Box, IconButton, Toolbar, Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

import { LanguageSelect, LanguageSelectV2, LanguageSelectV3} from 'components/organisms/LanguageSelect'
import { NetworkSelectV4 } from "components/organisms/ETH/metamask/network"

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = (props) => {
  const { onSidebarOpen, handleLang, lang, handleNetwork, ...other } = props;

  return <>
    <DashboardNavbarRoot
      sx={{
        left: {
          lg: 280
        },
        width: {
          lg: 'calc(100% - 280px)'
        }
      }}
      {...other}>
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2
        }}
      >
        <IconButton
          onClick={onSidebarOpen}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <NetworkSelectV4 handleChange={handleNetwork}/>
        <LanguageSelectV3 handleLang={handleLang} lang={lang} />
        {/*
        <Avatar
          sx={{
            height: 40,
            width: 40,
            ml: 1
          }}
          src="/static/images/avatars/avatar_1.png"
        >
          <div>UserCircle</div>
        </Avatar>
      */}
      </Toolbar>
    </DashboardNavbarRoot>
  </>

};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};




export const DashboardNavbarV2 = (props) => {
  const { onSidebarOpen, handleLang, lang, handleNetwork, ...other } = props;

  return <>
    <DashboardNavbarRoot
      sx={{
        left: {
          lg: 280
        },
        width: {
          lg: 'calc(100% - 280px)'
        }
      }}
      {...other}>
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2
        }}
      >
        <IconButton
          onClick={onSidebarOpen}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>

        <Box sx={{ flexGrow: 1 }} />
        <NetworkSelectV4 handleChange={handleNetwork}/>
        <LanguageSelectV3 handleLang={handleLang} lang={lang} />
        {/*
        <Avatar
          sx={{
            height: 40,
            width: 40,
            ml: 1
          }}
          src="/static/images/avatars/avatar_1.png"
        >
          <div>UserCircle</div>
        </Avatar>
      */}
      </Toolbar>
    </DashboardNavbarRoot>
  </>

};

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};
