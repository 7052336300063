import Joi from "joi";

export const joiValidate = (data={},schema) => {
  const validated = schema.validate(data)
  const { value, error } = {...validated}
  return [value, error]
}

export const joiValidateV2 = (data={},schema) => {
  const validated = Joi.object(schema).validate(data)
  const { value, error } = {...validated}
  return [value, error]
}

export const tokenAddress = Joi.string().required()
export const emailAddress = Joi.string().email(
  { minDomainSegments: 2, tlds: { allow: ['com', 'net'] } }
).required().messages({
    "string.empty": "REQUIRED",
    "string.email": "INVALID_EMAIL_ADDRESS",
  })

export const privateKey = Joi.string().alphanum().length(64).required().messages({
  "string.empty": "REQUIRED",
  "string.length": "INVALID_PRIVATE_KEY",
  "string.alphanum": "INVALID_PRIVATE_KEY"
})
export const ethAddress = (required = true) => {
  const func = Joi.string().alphanum().length(42)
  if(required) func.required()
  return func.messages({
    "string.empty": "REQUIRED",
    "string.length": "INVALID_ETH_ADDRESS",
    "string.alphanum": "INVALID_ETH_ADDRESS"
  })
}


export const networkId = Joi.number().required()
export const userId = Joi.number().required()

export const ethAddresses = Joi.array().items(ethAddress()).unique().required()

export const fundIds = Joi.array().items(Joi.number().required()).unique().required()
export const types = Joi.array().items(Joi.string().required()).unique().required()

export const getTxListSchema = Joi.object({
  networkId,
  tokenAddress 
});


/*
const _ethAddress =Joi.string().alphanum().length(42).required().messages({
  "string.empty":  "can not be empty",
  "string.length": "must be 42 characters",
  "string.alphanum": "must ony contain alphanumeric characters"
})
*/

export const investorTableSchema = Joi.object({
  private_key: privateKey,
  list: types 
});

export const pauseSchema = Joi.object({
  private_key: privateKey
});

export const createFundSchema = Joi.object({
  token_amount: Joi.number().required(),
  yen: Joi.number().required(),
});

export const fundTableSchema = Joi.object({
  private_key: privateKey,
  map: Joi.object().pattern(/^/, Joi.alternatives().try(Joi.string(),Joi.boolean())).required().messages({
    //"array.pattern.base" : 'pattern error',
    "any.required":  "can not be empty"
  }) 
});

export const linkWalletSchema = Joi.object({
  wallet_address: ethAddress()
});

export const createUserSchema = Joi.object({
  email: emailAddress,
  addresses: Joi.array().items(Joi.object({
    address: ethAddress(false)
  })).unique('address').messages({
    //"array.pattern.base" : 'pattern error',
    "array.unique":  "MUST_BE_UNIQUE"
  })
});

export const createLoginSchema = Joi.object({
  email: Joi.string().required().messages({
    "string.empty":  "can not be empty"
  }),
  password: Joi.string().required().messages({
    "string.empty":  "can not be empty"
  })
});

//need to update for list
export const controllerTransferSchema = Joi.object({
  private_key: Joi.string().required().messages({
    "string.empty":  "can not be empty"
  }),
  transfer: Joi.array().items(Joi.string()).required().messages({
    //"array.pattern.base" : 'pattern error',
    "any.required":  "can not be empty"
  }),
  tokenAmount: Joi.number().required(),
});

export const importStoSchema = Joi.object({
  stoAddress: ethAddress()
});

export const ethAddressSchema = Joi.object({
  address: ethAddress()
});

