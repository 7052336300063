import React, { useState, useContext, useEffect, useRef, Fragment } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux'

import get from "lodash/get"


import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUnmountDispatch } from "utilities/ETH"

import { selectPolyConfig } from 'utilities/selectors'

export const usePolyConfig = (networkId) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_LOAD_CONFIG'
  const [data, error, status] = useThunkDataV3([action])
  const config = useSelector(selectPolyConfig(networkId))
  const fetch = () => {
    dispatch({ 
      type: action, 
      payload: {networkId}
    })
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [config, error, status, fetch, unmount]
}