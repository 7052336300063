import React, { useState, useEffect, Fragment, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { 
  selectSTOaddresses, selectSTOmap, selectPolyTitle, selectPolySymbol,
  selectMultiSTODetails
} from 'utilities/selectors'



export const useStoDetails = (networkId, tokenAddress) => {
  const title = useSelector(selectPolyTitle(networkId, tokenAddress))
  const symbol = useSelector(selectPolySymbol(networkId, tokenAddress))
  return [title, symbol]
}

export const useMultiStoDetails = (networkId, tokenAddresses) => {
  const data = useSelector(selectMultiSTODetails(networkId, tokenAddresses))
  return data
}

export const useStos = (networkId) => {
  const STOaddresses = useSelector(selectSTOaddresses(networkId))
  const STOmap = useSelector(selectSTOmap(networkId))
  return [STOaddresses.reverse(), STOmap]
}

