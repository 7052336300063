import React, { useEffect } from 'react';
import { useSelector } from 'react-redux'

import intersection from "lodash/intersection"

import { selectUserRoles, selectAccessToken } from "utilities/selectors"

export const RestrictUserRole = ({roles, children}) => {
  const userRoles = useSelector(selectUserRoles)
  if (!roles) return children
  if (intersection(userRoles, roles).length > 0) {
    return children
  }  
  return <div>Role required: {JSON.stringify(roles)}</div>
}


const useAccessToken = (routeToLogin) => {
  const accessToken = useSelector(selectAccessToken)
  useEffect(()=>{
    if(!accessToken) {
      routeToLogin()
    }    
  },[accessToken])
  return accessToken
}

export const RestrictAccessToken = ({routeToLogin, children}) => {
  const accessToken = useSelector(selectAccessToken)
  useEffect(()=>{
    if(!accessToken) {
      routeToLogin()
    }    
  },[accessToken])
  //return <div>retricted</div>
  return children
}

export const OnlyUnauthorized = ({routeToMain, children}) => {
  const accessToken = useSelector(selectAccessToken)
  useEffect(()=>{
    if(accessToken) {
      routeToMain()
    }    
  },[accessToken])
  useEffect(()=>{
    if(accessToken) {
      routeToMain()
    }    
  },[])
  return children
}

