import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'

import { useForm, useFormState} from "react-hook-form"
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

import reduce from "lodash/reduce"
import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'

//import { investorTableSchema} from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"
import { ethAddress } from "utilities/joi/sto"

export const useRemoveSto = (networkId, onSend) => {
  const dispatch = useDispatch()
  const action = 'ETH_WLTOKEN_REMOVE_STO'
  const [data, error, status] = useThunkDataV3([action])

  const submit = formdata => {
    const { stoAddress } = {...formdata}
    dispatch({
      type: action, 
      payload: { 
        networkId, 
        stoAddress
      }
    })
    if(onSend) onSend()
  }
  const unmount = createUnmountDispatch(dispatch, action)
  return [ data, error, status, submit, unmount]
}


export const useRemoveStoForm = () => {
  const { register, handleSubmit, watch, formState: {errors}, resetField} = useForm({
    resolver: joiResolver(Joi.object({
      stoAddress: ethAddress(),
    }))
  });
  const stoAddress = watch('stoAddress')
  return [ 
    [register('stoAddress')],
    handleSubmit, 
    {
      errors: {
        stoAddress: get(errors,['stoAddress'], null)
      },
      fields: {
        stoAddress
      }
    },
    ()=>resetField('stoAddress'),
  ]
}