import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext, cancelled 
} from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

import { 
  handleContractSign
} from 'saga/ETH/helpers'

import { 
  putSuccessV2, putFetchingV2, putFailedV2, putCleanV2, putPendingV2,
} from 'saga/puts'

import { handlePost, handleGet, waitForTask, handlePostFile } from 'saga/ETH/helpersV2'

import get from "lodash/get"
import reduce from "lodash/reduce"

import { selectGtmAddress } from "utilities/selectors"





const ETHEREUM = 'ethereum'
const CONTRACTS = 'contracts'



//const selectGtmAddress = (tokenAddress) => (store) =>  get(store, ['ETH','polymath', tokenAddress, 'transferManager'], null)


export function* wAddToWhitelistSign(type, payload, meta) {
  const { tokenAddress, investorAddresses=[], privateKey, networkId } = {...payload}
  const generalTransferAddress = yield select(selectGtmAddress(networkId,tokenAddress))
  const sendStart = investorAddresses.map(x=>1609426800)
  const receiveStart = investorAddresses.map(x=>1609426800)
  const expiryTime = investorAddresses.map(x=>1704034800)
  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const signed_raw_tx = yield handleContractSign(
      privateKey, generalTransferAddress, 'modifyKYCDataMulti', 
      [investorAddresses, sendStart, receiveStart, expiryTime]
    )
    const task_data = yield handlePost(`/sto/${networkId}/token/${tokenAddress}/multi_add_to_whitelist`, { signed_raw_tx: signed_raw_tx.rawTransaction  })
    const {status, taskId} = {...task_data}
    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
}

export function* wRemoveFromWhitelistSign(type, payload, meta) {
  const { tokenAddress, investorAddresses=[], privateKey, networkId } = {...payload}
  const generalTransferAddress = yield select(selectGtmAddress(networkId,tokenAddress))
  console.warn(generalTransferAddress)
  const sendStart = investorAddresses.map(x=>0)
  const receiveStart = investorAddresses.map(x=>0)
  const expiryTime = investorAddresses.map(x=>0)
  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const signed_raw_tx = yield handleContractSign(
      privateKey, generalTransferAddress, 'modifyKYCDataMulti', 
      [investorAddresses, sendStart, receiveStart, expiryTime]
    )
    const task_data = yield handlePost(`/sto/${networkId}/token/${tokenAddress}/multi_remove_from_whitelist`, { signed_raw_tx: signed_raw_tx.rawTransaction  })
    const {status, taskId} = {...task_data}
    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
} 

export function* wMultiIssueFundSign(type, payload, meta) {
  const { tokenAddress, investorAddresses, tokenAmounts, fundIds, privateKey, networkId } = {...payload}
  ///const generalTransferAddress = yield select(selectGtmAddress(networkId,tokenAddress))

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const signed_raw_tx = yield handleContractSign(privateKey, tokenAddress, 'issueMulti', [investorAddresses, tokenAmounts])
    const task_data = yield handlePost(`/sto/${networkId}/token/${tokenAddress}/multi_issue_funds`, { fund_ids: fundIds, signed_raw_tx: signed_raw_tx.rawTransaction  })
    const {status, taskId} = {...task_data}
    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
}  

export function* wGetFunds(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`/sto/${networkId}/token/${tokenAddress}/fundlist`)
    const data = resp['data']
    yield putSuccessV2(type, data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  

export function* wGetEmail(type, payload, meta) {
  try {
    yield putFetchingV2(type)
    const resp = yield handleGet(`/config/get_email`)
    const data = resp['data']
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    //console.warn(err)
    yield putFailedV2(type, err.message)
  }
}  


export function* wSetEmail(type, payload, meta) {
  const { subject, message } = {...payload}
  try {
    yield putFetchingV2(type)
    const resp = yield handlePost('/config/edit_email', { subject, message })
    //const data = resp['data']
    const data = true
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    yield putFailedV2(type,err.message)
  }
}  

export function* wCreateFund(type, payload, meta) {
  const { tokenAddress, wallet_address, yen, token_amount, networkId } = {...payload}
  try {
    yield putFetchingV2(type)
    const resp = yield handlePost(`/sto/${networkId}/token/${tokenAddress}/create_fund`, { wallet_address, yen, token_amount })
    const data = resp['data']
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    const {response} =  {...err}
    const {data} =  {...response}
    //console.warn(err.response)
    yield putFailedV2(type,data)
  }
}  

export function* wCancelFund(type, payload, meta) {
  const { tokenAddress, fundIds, networkId } = {...payload}
  try {
    yield putFetchingV2(type)
    const resp = yield handlePost(`/sto/${networkId}/token/${tokenAddress}/cancel_fund`, 
      { fund_ids: fundIds}
    )
    const data = resp['data']
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    const {response} =  {...err}
    const {data} =  {...response}
    //console.warn(err.response)
    yield putFailedV2(type,data)
  }
}  
export function* wCreateUser(type, payload, meta) {
  const { email } = {...payload}
  try {
    yield putFetchingV2(type)
    const resp = yield handlePost('/sto/create_user', { email })
    const data = resp['data']
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    yield putFailedV2(type,err.message)
  }
}  

export function* wGetUsers(type, payload, meta) {

  try {
    yield putFetchingV2(type)
    const resp = yield handleGet(`/sto/get_user`)
    const data = resp['data']
    const users = data['users']
    yield putSuccessV2(type, users)
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message)
  }
}  

const investorReducer = (cur,nex) => {
  const nex_id = nex['investor_id'] || 'none'

  if(cur[nex_id]) {
    const _wallet = cur[nex_id]['wallet'] || []
    cur[nex_id]['wallet'] = [{
      address: nex['address'],
      balance: nex['balance'],
      isWhitelisted: nex['isWhitelisted']
    },..._wallet]
  } else {
    //const new_cur = cur[nex_id || 'none']
    cur[nex_id] = {
      investor_id: nex['investor_id'] || '-',
      email: nex['email'] || '-',
      wallet: [{      
        address: nex['address'],
        balance: nex['balance'],
        isWhitelisted: nex['isWhitelisted']
      }]
    }
  }
  return cur
}

export function* wGetInvestors(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`/sto/${networkId}/token/${tokenAddress}/investors`)
    const data = resp['data']
    const investorObject = reduce(data, investorReducer, {})
    const investorList = Object.keys(investorObject).map((key)=>({...investorObject[key]}))

    yield putSuccessV2(type, investorList, {subPath: [tokenAddress]})
  } 
  catch(err) {

    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  

export function* wLoadConfig(type, payload, meta) {
  const { network_id } = {...payload}
  const _network_id = 5777
  try {
    yield putFetchingV2(type, {subPath: [network_id]})
    const mock_config = {
      stoAddresses: ['0x7E27Bdd661533780a57838B844722d51Fe93f6F2'], //SPRT, PRJA
      stoMap: {
        '0x7E27Bdd661533780a57838B844722d51Fe93f6F2': { //SPRT
          title: 'Project WLT',
          symbol: 'WLT',
          coord: [35.66636, 139.75737]
        }
      }
    } 
    const _mock_config = {
      stoAddresses: ['0xDc5559860beE73B261904737E4fA2D10a46e9878', '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E'], //SPRT, PRJA
      stoMap: {
        '0xDc5559860beE73B261904737E4fA2D10a46e9878': { //SPRT
          balancerAddress: '0x88ACc4dB1B930354caa369f1bC8E35736f1BB2AC',
          title: 'Project SPRT',
          symbol: 'SPRT',
          coord: [35.66636, 139.75737],
          gtmAddress: '0x681Bb5f59577e385379c575994Cf2Ea9747f1179'
        },
        '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E': { //PRJA
          balancerAddress: '0x4bbd0c836Ba92BDcb58B5af3242C3Dc77c8FD8b0',
          title: 'Project A',
          symbol: 'PRJA',
          coord: [35.65889, 139.74541],
          gtmAddress: '0x7cc02b8A9f174aFA86FB4dB92D111a367189F3dC'
        }
      },
      balancerMap: {
        '0x88ACc4dB1B930354caa369f1bC8E35736f1BB2AC': {
          STO: '0xDc5559860beE73B261904737E4fA2D10a46e9878',  //SPRT
          stablecoin: '0x8186e1d916774ab8004cB84d7aDC945953ee966D'  //FDAI 0x8186e1d916774ab8004cB84d7aDC945953ee966D
        },
        '0x4bbd0c836Ba92BDcb58B5af3242C3Dc77c8FD8b0': {
          STO: '0x34B9Ab4dcc71975762b63F8C4801e01c2feE724E',  //PRJA
          stablecoin: '0x8186e1d916774ab8004cB84d7aDC945953ee966D'  //FDAI 0x8186e1d916774ab8004cB84d7aDC945953ee966D
        }
      }
    }

    yield put({type: 'R_ETH_POLYMATH_UPDATE_CONFIG', payload: {network_id: _network_id, config: mock_config} })
    
    yield putSuccessV2(type, mock_config, {subPath: [network_id]})
  } 
  catch(err) {
    yield putFailedV2(type,err.message, {subPath: [network_id]})
  }
}  


export function* wGetPause(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}
  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`v1/${networkId}/token/${tokenAddress}/ispaused`)
    const data = resp['data']
    yield putSuccessV2(type, data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  


export function* wPause(type, payload, meta) {
  const { tokenAddress, privateKey, networkId } = {...payload}
  const generalTransferAddress = yield select(selectGtmAddress(networkId,tokenAddress))
  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const signed_raw_tx = yield handleContractSign(
      privateKey, generalTransferAddress, 'pause'
    )
    const task_data = yield handlePost(
      `/sto/${networkId}/token/${tokenAddress}/pause`, 
      { signed_raw_tx: signed_raw_tx.rawTransaction }
    )
    const {status, taskId} = {...task_data}
    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
}

export function* wUnpause(type, payload, meta) {
  const { tokenAddress, privateKey, networkId } = {...payload}
  const generalTransferAddress = yield select(selectGtmAddress(networkId,tokenAddress))
  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const signed_raw_tx = yield handleContractSign(
      privateKey, generalTransferAddress, 'unpause'
    )
    const task_data = yield handlePost(
      `/sto/${networkId}/token/${tokenAddress}/unpause`, 
      { signed_raw_tx: signed_raw_tx.rawTransaction }
    )
    const {status, taskId} = {...task_data}
    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
}

export function* wGetInfo(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}
  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`sto/${networkId}/token/${tokenAddress}/sto_info`)
    const data = resp['data']
    yield putSuccessV2(type, data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  


export function* wLinkWallet(type, payload, meta) {
  const { userId, walletAddress } = {...payload}
  try {
    yield putFetchingV2(type)
    const resp = yield handlePost(`/sto/user/${userId}/link_wallet`, { wallet_address: walletAddress })
    const data = resp['data']
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    yield putFailedV2(type,err.message)
  }
}

export function* wUnlinkWallet(type, payload, meta) {
  const { walletAddress, userId } = {...payload}
  try {
    yield putFetchingV2(type)
    const resp = yield handlePost(`/sto/user/unlink_wallet`, { 
      wallet_address: walletAddress,
      user_id: userId
    })
    const data = resp['data']
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    yield putFailedV2(type,err.message)
  }
}  


export function* wGetTxList(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}
  try {

    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`sto/${networkId}/token/${tokenAddress}/txlist`)
    const data = resp['data']
    yield putSuccessV2(type, data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  

export function* wGetUnwhitelisted(type, payload, meta) {
  const { tokenAddress, networkId } = {...payload}

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const resp = yield handleGet(`/sto/${networkId}/token/${tokenAddress}/unwhitelisted_users`)
    const data = resp['data']
    const users = data['users']
    yield putSuccessV2(type, users, {subPath: [tokenAddress]})
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message, {subPath: [tokenAddress]})
  }
}  

export function* wUploadCsv(type, payload, meta) {
  const { file } = {...payload}

  try {
    yield putFetchingV2(type)
    var formData = new FormData()
    formData.append("file", file[0])
    const resp = yield handlePostFile('/sto/upload_csv', formData)
    const data = resp['data']
    yield putSuccessV2(type, data)
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message)
  }
}  

function* handleLinkClick(data, fileName='sample') {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${fileName}.csv`); //or any other extension
  document.body.appendChild(link);
  link.click();
  return 'SUCCESS'
}

export function* wExportCsv(type, payload, meta) {

  try {
    yield putFetchingV2(type)
    const resp = yield handleGet("/sto/export_csv")
    const data = resp['data']
    const status = yield handleLinkClick(data['csv'])
    yield putSuccessV2(type, status)
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message)
  }
}  

export function* wExportUserCsv(type, payload, meta) {
  try {   
    yield putFetchingV2(type)
    const resp = yield handleGet("/sto/export_user_csv")
    const data = resp['data']
    const status = yield handleLinkClick(data['csv'], 'users')
    yield putSuccessV2(type, status)  
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message)
  }
}  

export function* wImportUserCsv(type, payload, meta) {
  const { file } = {...payload}
  try {   
    yield putFetchingV2(type)
    var formData = new FormData()
    formData.append("file", file[0])
    const resp = yield handlePostFile('/sto/import_user_csv', formData)
    const data = resp['data']
    yield putSuccessV2(type, data) 
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type, err.message)
  }
} 




export function* wControllerTransfer(type, payload, meta) {
  const { tokenAddress, fromAddress, toAddress, tokenAmount, privateKey, networkId } = {...payload}
  ///const generalTransferAddress = yield select(selectGtmAddress(networkId,tokenAddress))
  const w3 = yield getContext('w3')

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const signed_raw_tx = yield handleContractSign(privateKey, tokenAddress, 'controllerTransfer', [fromAddress, toAddress, tokenAmount, w3.utils.toHex(''),w3.utils.toHex('')])
    const task_data = yield handlePost(`/v1/${networkId}/token/${tokenAddress}/controller_transfer`, { signed_raw_tx: signed_raw_tx.rawTransaction })
    const {status, taskId} = {...task_data}
    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
}  

export function* wControllerRedeem(type, payload, meta) {
  const { tokenAddress, investorAddress, tokenAmount, privateKey, networkId } = {...payload}
  ///const generalTransferAddress = yield select(selectGtmAddress(networkId,tokenAddress))
  const w3 = yield getContext('w3')

  try {
    yield putFetchingV2(type, {subPath: [tokenAddress]})
    const signed_raw_tx = yield handleContractSign(privateKey, tokenAddress, 'controllerRedeem', [investorAddress, tokenAmount, w3.utils.toHex(''),w3.utils.toHex('')])
    const task_data = yield handlePost(`/v1/${networkId}/token/${tokenAddress}/controller_redeem`, { signed_raw_tx: signed_raw_tx.rawTransaction })
    const {status, taskId} = {...task_data}
    yield putPendingV2(type, {subPath: [tokenAddress]})
    const tx_data = yield waitForTask(type, taskId)
    yield putSuccessV2(type, tx_data, {subPath: [tokenAddress]})
  } 
  catch(err) {
    console.warn(err)
    yield putFailedV2(type,err.message, {subPath: [tokenAddress]})
  }
}  