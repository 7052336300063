import { take, fork, } from 'redux-saga/effects'
import { eventChannel, END, channel } from 'redux-saga'

//import { } from './workers'

import { 
  wwOfferSell, wwOfferList, wwTakeOffer, wwDeleteOffer
} from './wrapped'

import { cancellableAsync} from 'saga/helpers'


const async_map = {
  'ETH_SWAPCAT_OFFER_SELL': wwOfferSell,
  'ETH_SWAPCAT_GET_OFFERS': wwOfferList,
  'ETH_SWAPCAT_TAKE_OFFER': wwTakeOffer,
  'ETH_SWAPCAT_DELETE_OFFER': wwDeleteOffer

}

export function* swapcatWatchAsync() {
  while(true){
    const { type, payload, meta } = yield take(Object.keys(async_map))
    yield fork(cancellableAsync, async_map[type], type, payload, meta)
  }     
}
