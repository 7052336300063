import React, {Fragment, useState, useEffect} from 'react';

import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

export const useLanguage = (lang) => {
  const { t, i18n } = useTranslation();
  const handleLangChange = value => {
    i18n.changeLanguage(value)
  }
  return [t, i18n.language, handleLangChange]
}

export const LanguageSelectV4 = props => {
  const {
    t= text=>null,
    language='placeholder',
    handleChange=()=>null
  } = {...props}

  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">{t('LANGUAGE')}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={language}
      label={t("LANGUAGE")}
      onChange={e=>handleChange(e.target.value)}
      sx={{color: 'black'}}
    >
      <MenuItem value={'en'}>en</MenuItem>
      <MenuItem value={'jp'}>jp</MenuItem>
    </Select>
  </FormControl>
}

export const LanguageSelectV2 = props => {
  const { lang, handleLang} = {...props}
  const { t, i18n } = useTranslation();
  const [ _lang, setLang ] = useState('en')
  useEffect(()=>{
    i18n.changeLanguage(lang)
    setLang(lang)
  },[lang])

  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">Language</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={_lang}
      label="Language"
      onChange={e=>handleLang(e.target.value)}
      sx={{color: 'black'}}
    >
      <MenuItem value={'en'}>en</MenuItem>
      <MenuItem value={'jp'}>jp</MenuItem>
    </Select>
  </FormControl>
}

export const LanguageSelect = props => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState('en')
  const handleChange = e => {
    i18n.changeLanguage(e.target.value)
    setLang(e.target.value)
  }
  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">Language</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={lang}
      label="Language"
      onChange={handleChange}
      sx={{color: 'black'}}
    >
      <MenuItem value={'en'}>en</MenuItem>
      <MenuItem value={'jp'}>jp</MenuItem>
    </Select>
  </FormControl>
}

export const LanguageSelectV3 = props => {
  const { t, i18n } = useTranslation();
  const handleChange = e => {
    i18n.changeLanguage(e.target.value)
  }
  return <FormControl sx={{marginLeft: 'auto', color: 'white'}}>
    <InputLabel id="demo-simple-select-label">{t('LANGUAGE')}</InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={i18n.language}
      label={t("LANGUAGE")}
      onChange={handleChange}
      sx={{color: 'black'}}
    >
      <MenuItem value={'en'}>en</MenuItem>
      <MenuItem value={'jp'}>jp</MenuItem>
    </Select>
  </FormControl>
}
