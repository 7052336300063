import React, {Fragment} from 'react';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';

import get from "lodash/get"
import reduce from "lodash/reduce"
import intersection from "lodash/intersection"

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { RestrictTokenRole } from 'components/organisms/ETH/sto/RestrictTokenRole' 
import { RestrictUserRole } from 'components/organisms/Auth/Restrict' 

import { useDefaultRoute } from 'components/useHooks/useRoute'



export const OptionCard = ({ option, handleRoute }) => {
  const { title, pathname, description, Icon } = {...option}
  const { t, i18n } = useTranslation();
  return <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}
  >
    <CardActionArea onClick={()=>handleRoute(pathname)}>
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pb: 3
          }}
        >
          <Icon fontSize="large"/>
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {t(title)}
        </Typography>
        <Typography
          align="center"
          color="textPrimary"
          variant="body1"
        >
          {t(description)}
        </Typography>
        
      </CardContent>

      <Box sx={{ flexGrow: 1 }} />
    </CardActionArea>
  </Card>
}

export const OptionPage = props => {
  const { routes, handleRoute } = {...props}
  const { t, i18n } = useTranslation();

  return <Fragment>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        //py: 8
      }}
    >
      <Container maxWidth={false}>
        <Box sx={{ pt: 3 }}>
          <Grid
            container
            spacing={3}
          >
            {routes.map(option=> {
              return <RestrictUserRole roles={option['restrictedUserRoles']}>
                <RestrictTokenRole roles={option['restrictedTokensRoles']}>
                  <Grid
                    item
                    //key={product.id}
                    lg={4}
                    md={6}
                    xs={12}
                  >
                    <OptionCard option={option} handleRoute={handleRoute}/>
                  </Grid>  
                </RestrictTokenRole>
              </RestrictUserRole>
            })}
          </Grid>
        </Box> 
      </Container>
    </Box>
  </Fragment>
}
