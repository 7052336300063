import { 
  race, actionChannel, call, put, takeEvery, all, take, fork, 
  cancel, select,delay, setContext, getContext
} from 'redux-saga/effects'

import { 
  createSuccess, createFailed, createPending,
  createFetching, createMeta, createClean
} from 'saga/helpers'

import { channel } from 'redux-saga'

import get from 'lodash/get'
import reduce from 'lodash/reduce'
import qs from 'qs'
import axios from 'axios';
import Web3 from "web3";


const POLYCLIENT = 'polyClient'
const W3 = 'w3'
const DELAY = 500
const ETHEREUM = 'ethereum'
const CONTRACTS = 'contracts'

const etherscan_url = 'https://api-ropsten.etherscan.io/api'
const etherscan_api_key = 'V2G7APATWHHRXGKDEX6KDVE6AVA692SBAG'

const proj_id = 'e37bf8530bbe4a0f9de481d912123d1e'
const eth_url ='https://ropsten.infura.io/v3/'
const full_url = `${eth_url}${proj_id}`

//export const w3 = new Web3(new Web3.providers.HttpProvider(full_url))

export function* handleAsync(
  name,
  endpoint, 
  params,
  options
) {

  const { address, holdData, handleError, 
    handleSuccess, memoize, method } = {...options}

  yield put({
    type: createFetching(name),       
    meta: { 
      name,
      address: address ? address : null,
      holdData,
      status: 'FETCHING'
    }
  })
  try {
    let func
    if(method ==='get' || method == null){
      func = handleGet(endpoint)
    } else if(method ==='post') { 
      func = handlePost(endpoint) 
    }

    //const api = yield getContext('api')
    /*
    const resp =  yield api.get(endpoint, { 
      params: params, 
      //headers: token ? createTokenHeader(token) : null 
    })
    */
    const resp = yield call(func, params)

    //if(handleSuccess) resp = yield handleSuccess(resp)

    const [ resp_data, type, payload, meta ] = pullResp(resp)

    if (type === 'SUCCESS') yield putSuccess(name, payload)
    
    return payload
  }

  catch (err) {
    console.log(err)
    console.log(err.response)
    let message = err.message

    //if(handleError) message = yield handleError(err)

    yield putFailed(name, message)
  }
}

export function* _handleFetch(
  name,
  endpoint, 
  params,
  options
) {

  const { address, holdData, handleError, 
    handleSuccess, memoize, method='get' } = {...options}

  const backEnd = yield getContext('backEnd')

  try {
    let resp
    if( method === 'get'){
      resp = yield backEnd[method](endpoint, { 
        params: params, 
        paramsSerializer: params => qs.stringify(params, { indices: false })
      })
    } 
    else if (method==='post') {
      resp = yield backEnd[method](
        endpoint, 
        params, 
        //token ? createTokenHeader(token) : null
      )
    }

    //const resp = yield call(func, params)

    //if(handleSuccess) resp = yield handleSuccess(resp)

    const [ resp_data, type, payload, meta ] = pullResp(resp)

    //if (type === 'SUCCESS') yield putSuccess(name, payload)
    
    return payload
  }

  catch (err) {
    console.log(err)
    console.log(err.response)
    let message = err.message

    //if(handleError) message = yield handleError(err)
    throw err
    //yield putFailed(name, message)
  }
}


export function* handleFetch(
  name,
  endpoint, 
  params,
  options
) {

  const { address, holdData, handleError, 
    handleSuccess, memoize, method='get', composite=false } = {...options}

  if(composite) {
    console.log('composite activated')
    const requestChannel = yield getContext('requestChannel')
    const callbackChannel = yield call(channel)
    yield put(requestChannel, {name, endpoint, params, callbackChannel})
    const result = yield take(callbackChannel)
    return result
  }

  const backEnd = yield getContext('backEnd')

  try {
    let resp
    if( method === 'get'){
      resp = yield backEnd[method](endpoint, { 
        params: params, 
        paramsSerializer: params => qs.stringify(params, { indices: false })
      })
    } 
    else if (method==='post') {
      resp = yield backEnd[method](
        endpoint, 
        params, 
        //token ? createTokenHeader(token) : null
      )
    }

    //const resp = yield call(func, params)

    //if(handleSuccess) resp = yield handleSuccess(resp)

    const [ resp_data, type, payload, meta ] = pullResp(resp)

    //if (type === 'SUCCESS') yield putSuccess(name, payload)
    
    return payload
  }

  catch (err) {
    console.log(err)
    console.log(err.response)
    let message = err.message

    //if(handleError) message = yield handleError(err)
    throw err
    //yield putFailed(name, message)
  }
}

function handlePost(endpoint) {
  return function* (args) {
    const api = yield getContext('api')
    const resp = yield api.post(
      endpoint, 
      args, 
      //token ? createTokenHeader(token) : null
    )
    return resp
  }
}
 
function handleGet(endpoint) {
  //const func = memoize ? memoized : runGetApi
  return function* (params) {
    const api = yield getContext('api')
    //const user_id = yield select(selectUserId)
    //const access_token = yield select(selectAccessToken)
    //const resp = yield func(user_id, endpoint, params, access_token)
    const resp = yield api.get(endpoint, { 
      params: params, 
      //headers: token ? createTokenHeader(token) : null 
    })
    return resp
  }  
}

export function* handleGetV2(endpoint, params={}) {
  /*
  const api = yield getContext('api')

  const resp = yield api.get(endpoint, { 
    params: params, 
    //headers: token ? createTokenHeader(token) : null 
  })
  */
  const resp = yield axios.get(endpoint,{params})
  const {data, status, statusText, config, headers, request} = {...resp}
  return data
}

export function* putSuccess(name, payload, options) {
  const { address, meta, subPath } = {...options}
  yield put({
    type: createSuccess(name),
    payload: meta ? { data: payload, meta } : payload,
    meta: {
      name, 
      address: address ? address : null,
      subPath,
      status: meta ? 'DATA_META': 'DATA'
    }
  })       
}

export function* putFailed(name, message, options) {
  const { address, subPath } = {...options}
  yield put({
    type: createFailed(name),
    payload: message,
    meta: {
      name,
      address: address ? address : null,
      subPath,
      status: 'ERROR', 
    }
  })     
}

export function* putFetching(name, options) {
  const { address, holdData, subPath } = {...options}
  yield put({
    type: createFetching(name),
    meta: {
      name,
      address: address ? address : null,
      holdData,
      subPath,
      status: 'FETCHING'
    }
  })     
}

export function* putClean(name, options) {
  const { address, holdData, subPath } = {...options}
  yield put({
    type: createClean(name),
    meta: {
      name,
      subPath,
      //address: address ? address : null,
      //holdData,
      status: 'CLEAN'
    }
  })     
}

export function* putPending(name, options) {
  const { address, subPath} = {...options}
  yield put({
    type: createPending(name),
    meta: {
      name,
      subPath,
      address: address ? address : null,
      status: 'PENDING'
    }
  })     
}

export function* putEvent(name, payload) {
  yield put({
    type: `${name}_EVENT`,
    payload: payload,
    meta: {
      name: `${name}_EVENT`, 
      //address: address ? address : null,
      status: 'DATA'
    }
  })       
}

function pullResp(resp) {
  const resp_data = get(resp, 'data', {})
  const type = get(resp_data, 'type', 'DEFAULT')
  const payload = get(resp_data, 'payload', {})
  const meta = get(resp_data, 'meta', {})
  return [ resp_data, type, payload, meta ]
}

export function* handleWeb3(
  name, methods, params,options
) {
  const { address, holdData, handleError, 
    handleSuccess, memoize, method } = {...options}

  const w3 = yield getContext('w3')
  yield put({
    type: createFetching(name),       
    meta: { 
      name,
      address: address ? address : null,
      holdData,
      status: 'FETCHING'
    }
  })
  try {
    const func = get(w3,methods, null)
    const resp = yield call(func, ...params)
    yield putSuccess(name, resp)
    return resp
  }

  catch (err) {
    console.log(err)
    console.log(err.response)
    let message = err.message
    yield putFailed(name, message)
  }
}
export function* handleES(
  name, params,options
) {
  const { address, holdData, handleError, 
    handleSuccess, memoize, method } = {...options}
  const apiES = yield getContext('apiES')
  yield put({
    type: createFetching(name),       
    meta: { 
      name,
      address: address ? address : null,
      holdData,
      status: 'FETCHING'
    }
  })
  try {
    const resp = yield apiES.get(null, { params: {...params, 'apikey': etherscan_api_key} })
    const { data } = resp
    const {status, message, result} = data
    yield putSuccess(name, result)
    return result
  }

  catch (err) {
    console.log(err)
    console.log(err.response)
    let message = err.message
    yield putFailed(name, message)
  }
}
/*
function getTransactionReceiptMined(txHash, interval) {
  const self = this;
  const transactionReceiptAsync = function(resolve, reject) {
    self.getTransactionReceipt(txHash, (error, receipt) => {
      if (error) {
        reject(error);
      } else if (receipt == null) {
        setTimeout(
          () => transactionReceiptAsync(resolve, reject),
          interval ? interval : 500);
      } else {
        resolve(receipt);
      }
    });
  };

  if (Array.isArray(txHash)) {
    return Promise.all(txHash.map(
      oneTxHash => self.getTransactionReceiptMined(oneTxHash, interval)));
  } else if (typeof txHash === "string") {
    return new Promise(transactionReceiptAsync);
  } else {
    throw new Error("Invalid Type: " + txHash);
  }
};
*/
export function* waitForTransactionReceipt (name, txHash) {
  yield putPending(name)
  const w3 = yield getContext('w3')
  let receipt

  while(true) {
    yield delay(3000)
    receipt = yield w3.eth.getTransactionReceipt(txHash);
    if(receipt) break
    
  }
  return receipt
}

export function* handleContract(
  contract, name, method, params=[], sign, options
) {
  const { address, holdData, handleError=null, 
    handleSuccess=null, memoize, value=null, event=null } = {...options}
  const ethereum = yield getContext(ETHEREUM)
  yield putFetching(name)

  if(sign){
    const w3 = yield getContext('w3')
    try {
      
      yield put({type: 'ETH_TX_PENDING_ON'})
      //yield put({type: 'R_ETH_TX_STATUS_SENDING'})
      const abi = contract.methods[method](...params).encodeABI() 
      const transactionParameters = { 
        //nonce: '0x00', // ignored by MetaMask
        //gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
        //gas: '0x2710', // customizable by user during MetaMask confirmation.
        to: contract._address, // Required except during contract publications.
        from: ethereum.selectedAddress, // must match user's active address.
        value: value ? w3.utils.toHex(value) : null, // Only required to send ether to the recipient from the initiating external account.
        data: abi
        //chainId: 3, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
      };
      yield put({type: 'R_ETH_TX_STATUS_SENDING'})
      const txHash = yield ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      yield put({type: 'R_ETH_TX_STATUS_PENDING'})
      const receipt = yield waitForTransactionReceipt(name, txHash)
      yield put({type: 'R_ETH_TX_STATUS_UPDATING'})
      let resp = receipt
      if(handleSuccess){
        resp = yield handleSuccess(resp)
      }
      yield putSuccess(name, resp)
      if(event) yield putEvent(name, resp) //remove in future
      return resp
    }
    catch (err) {
      //throw err
      yield put({type: 'R_ETH_TX_STATUS_STANDBY'})
      console.log(err)
      console.log(err.response)
      let message = err.message
      yield putFailed(name, message)
      return err
    }
    finally{
      yield put({type: 'ETH_TX_PENDING_OFF'})
    }

  } else {
    try {
      const func = get(contract.methods, method, null)
      //const resp = yield call(func().call, ...params)
      const resp = yield call(func().call, {from:ethereum.selectedAddress})
      yield putSuccess(name, resp)
      return resp
    }

    catch (err) {
      console.log(err)
      console.log(err.response)
      let message = err.message
      yield putFailed(name, message)
    }    
  }

}

export function* getSelectedAddress() {
  const ethereum  = yield getContext(ETHEREUM)
  const accounts = yield ethereum.request({ method: 'eth_accounts' })
  const selected_account = accounts[0]
  return selected_account
}

export function* loadDeployedContracts(contract_list) {
  const w3 = yield getContext(W3)
  const contracts = yield getContext(CONTRACTS)
  contract_list.forEach(item => {
    
    const type = item['type']
    if(type==='undeployed') return
    if(type === 'truffle'){
      const json = item['contract']
      const contractName = json['contractName']
      const abi = json['abi']
      const address = item['address']
      const bytecode = json['bytecode']
      contracts[contractName]= new w3.eth.Contract(abi, address, {data:bytecode})       
    } else {
      const contractName = item['contractName']
      if(contracts[contractName]) return null
      const abi = item['abi']
      const address = item['address']
      //const bytecode = json['bytecode']
      contracts[contractName]= new w3.eth.Contract(abi, address)             
    }
  })

}

export function* handleContractCallV2(
  contractAddress, method, methodArgs=[]
) { 
  const contracts = yield getContext('contracts')
  const contract =  contracts[contractAddress] 
  const resp = yield contract['methods'][method](...methodArgs).call()
  return resp
}


export function* handleContractSendV2(
  contractAddress, method, methodArgs=[], sendArgs=[]
) {

  const w3 = yield getContext('w3')
  const ethereum = yield getContext('ethereum')
  const selectedAddress = yield getSelectedAddress()

  const contracts = yield getContext('contracts')
  const contract =  contracts[contractAddress]

  const encoded_abi = contract['methods'][method](...methodArgs).encodeABI() 
  const constract_address = contract._address
  //const _nonce = yield w3.eth.getTransactionCount(selectedAddress)

  const transactionParameters = {
    //nonce: _nonce.toString(),
    //nonce: '0x00', // ignored by MetaMask
    //gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
    //gas: '0x2710', // customizable by user during MetaMask confirmation.
    to: contractAddress, // Required except during contract publications.
    from: selectedAddress, // must match user's active address.
    //value: value ? w3.utils.toHex(w3.utils.toWei(value,'ether')) : null, // Only required to send ether to the recipient from the initiating external account.
    //value: value && w3.utils.toHex(value),
    data: encoded_abi,
    //gasLimit: 4000000    //make this an option
    //chainId: 3, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
  };
  
  const txHash = yield ethereum.request({
    method: 'eth_sendTransaction',
    params: [transactionParameters],
  })
  //const receipt = yield w3.eth.getTransactionReceipt(txHash)

  return txHash
}


export function* waitForTransactionReceiptV2 (name, address, txHash) {
  yield putPending(name,  {subPath: [address]})
  const w3 = yield getContext('w3')
  let receipt

  while(true) {
    yield delay(3000)
    receipt = yield w3.eth.getTransactionReceipt(txHash);
    if(receipt) break
  }
  return receipt
}

//need to update using Decimals
const parseValue = (value, toWei=null, toHex=false) => {
  let _value = value
  if(typeof _value === 'number') _value = _value.toString()
  if(toWei) _value = Web3.utils.toWei(_value, toWei)
  if(toHex) _value = Web3.utils.toHex(_value) 
  return _value
}


function* methodReducer (methodArgs, contractName=null){
  const w3 = yield getContext('w3')
  const selectedAddress = yield getSelectedAddress()
  const contracts = yield getContext('contracts')
  const _reducer = (acc,cur)=> {
    let _value = cur['value']
    switch (cur['type']) {
      case 'selectedAddress':
        _value = selectedAddress
        break;
      case 'oneEther':
        _value = w3.utils.toWei('1', 'ether')
        break;
      case 'oneUnit':
        _value = w3.utils.toWei('1', cur['unit'])
        break;
      case 'contractAddress':
        _value = contracts[cur['contractName']]._address
        break;
      case 'inject':
        _value = cur['value']
        break;
      case 'input':
        //if(cur['addValue']) _value = cur['value'] + cur['addValue']
        if(cur['wei']) _value = parseValue(_value, cur['wei'], cur['toHex'])
        break;
      case 'list':
        _value = reduce(cur['items'],_reducer,[])
        break;
    }
    acc.push(_value)
    return acc
  }
  return reduce(methodArgs, _reducer, [])
}



export function* handleContractSign(
  privateKey, contractName, method, methodArgs=[], sendArgs=[], options
) {

  const { } = {...options}

  const w3 = yield getContext('w3')
  const ethereum = yield getContext('ethereum')
  const selectedAddress = yield getSelectedAddress()

  const sendArgs_obj = sendArgs.reduce((acc,cur,ind)=>{
    acc[cur['type']] = cur['value']
    return acc
  },{})

  const { value, gasLimit } = {...sendArgs_obj}
  const contracts = yield getContext('contracts')
  const contract =  contracts[contractName]

  //const _methodArgs = yield methodReducer(methodArgs, contractName)
  console.warn('contractName: ', contractName)
  console.warn('contract: ', contract)
  console.warn('method: ', method)
  console.warn('methodArgs: ', methodArgs)
  console.warn('value: ', value)

  const encoded_abi = contract['methods'][method](...methodArgs).encodeABI() 
  const contract_address = contract._address
  //const contract_address = contractName
  //const _nonce = yield w3.eth.getTransactionCount(selectedAddress)
  const block = yield w3.eth.getBlock("latest")
  
  const _nonce = yield w3.eth.getTransactionCount(selectedAddress)
  console.warn('getTransactionCount', _nonce)
  
  const transactionParameters = {
    //nonce: _nonce.toString(),
    nonce: _nonce, // ignored by MetaMask
    gasPrice: '50000000000', // customizable by user during MetaMask confirmation.
    gas: block.gasLimit, // customizable by user during MetaMask confirmation.
    to: contract_address, // Required except during contract publications.
    from: selectedAddress, // must match user's active address.
    //value: value ? w3.utils.toHex(w3.utils.toWei(value,'ether')) : null, // Only required to send ether to the recipient from the initiating external account.
    value: value && w3.utils.toHex(value),
    data: encoded_abi,
    //gasLimit: 4000000    //make this an option
    //chainId: 3, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
  };

  const _estGas = yield contract['methods'][method](...methodArgs).estimateGas(transactionParameters)
  console.warn('_estGas', _estGas)
  const signed_tx = yield w3.eth.accounts.signTransaction(transactionParameters, privateKey)
  

  return signed_tx
}