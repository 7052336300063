/* eslint max-len: 0, no-param-reassign: 0 */

import reduce from 'lodash/reduce'


export function createConstants(...constants) {
    return constants.reduce((acc, constant) => {
        acc[constant] = constant;
        return acc;
    }, {});
}

export function createReducer(initialState, reducerMap) {
  return (state = initialState, action) => {
    const reducer = reducerMap[action.type];
    return reducer
        ? reducer(state, action.payload)
        : state;
  };
}

export function combineSagas(instances, ...args){
  let saga_list = []
  args.map(saga => saga_list= [...saga_list, ...Object.keys(saga).map(key=>saga[key](instances))])
  return saga_list
}

export function parseJSON(response) {
    return response.data;
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}


export function handleDispatch(dispatch, action, input) {
  try {
    dispatch(action(...input))
  } catch(err_class) {
    //console.log(err_class)
    throw err_class
  }
}

export function resetField(field_obj, config) {
  config.forEach(field => field_obj[field].input.onChange(field_obj[field].meta.initial))
}


//doesnt work correctly?
export const replacer = (key,value) => {
  if (typeof value === "function") {
    return value.name + "()";
  }
  return value;   
}

export function createTokenHeader(token){
  return ({
    headers: {
      'Authorization': `Bearer ${token}`
    },
  })
}


export const ConditionalWrapper = ({ condition, wrapper, children }) => 
  condition ? wrapper(children) : children;


export function reduceBools(bools) {
  return reduce(bools, (acc,nex)=>{
    if(nex) return nex
    return acc
  }, false)
}


export const calcFetching = status => {
  return status==='FETCHING'
}

export const calcSuccess = status => {
  return status==='SUCCESS'
}
export const calcError = status => {
  return status==='ERROR'
}

export const calcPending = status => {
  return status==='PENDING'
}

export function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

function addressAvatar(_string) {
  return {
    sx: {
      bgcolor: stringToColor(_string),
    },
    children: `${_string[2]}${_string[3]}`,
  };
}