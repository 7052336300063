import React from 'react';
import { 
  //useRouteMatch,
  //useParams,
  useLocation,
  useHistory
} from 'react-router-dom'

import qs from 'qs'

export const useDefaultRoute = props => {
  const history = useHistory()
  //const match =  useRouteMatch()
  //const params = useParams()
  const location = useLocation()
  const query = qs.parse(location['search'], { ignoreQueryPrefix: true })
  const { networkId, tokenAddress} = {...query}
  const _networkId = networkId ? networkId : 1
  
  const handleRoute = (pathname, search) => {
    const _search = qs.stringify({ networkId: _networkId, tokenAddress, ...search})
    history.push({pathname: pathname, search: `?${_search}`})
  }
  return handleRoute
}