import { 
  cancelled, 
  call, 
  put, 
  takeEvery, 
  all, 
  take, 
  fork, 
  cancel, 
  select,
  delay,
  race
} from 'redux-saga/effects'

import get from 'lodash/get'

import { createTokenHeader } from "utilities/misc"

import axios from 'axios';

export const createUnmount = (type) => `${type}_UNMOUNT` 
export const createFailed = (type) => `${type}_FAILED`
export const createCancelled = (type) => `${type}_CANCELLED`
export const createBlocked = (type) => `${type}_BLOCKED`
export const createSuccess = (type) => `${type}_SUCCESS`
export const createFetching = (type) => `${type}_FETCHING`
export const createPending = (type) => `${type}_PENDING`
export const createMeta = (type) => `${type}_META`
export const createClean = (type) => `${type}_CLEAN`
//could be better
//fix later
export function omitThunkKey(meta={}) {
  const nMeta = {...meta}
  const nThunk = {...nMeta.thunk}
  delete nThunk['key']
  nMeta.thunk = nThunk
  return nMeta
}

function* checkConn() {
  try {
    console.log('checking connection...')
    yield axios.get("https://ipv4.icanhazip.com")
    //yield put({type:'CONN'})
  }
  catch(err){
    console.log('unable to connect')
    yield put({type:'DISCONN'})
    yield delay(2000)  }
}

export function* handleSyncRedux({
  name, 
  address, 
  func, 
  args,
  holdData,
  handleSuccess=null, 
  handleError=null
}) {
  try {
    let resp = yield call(func, ...args)
    if(handleSuccess){
      resp = yield handleSuccess(resp)
    }
    yield put({
      type: createSuccess(name),
      payload: resp,
      meta: {
        name, 
        address: address ? address : null,
        status: 'DATA'
      }
    })
    return resp
  }
  catch (err) {
    //let message = err.message
    if(handleError){
      message = yield handleError(err)
    }

    const response_type = getResponseType(err)
    const [code, message] = getResponse(response_type,err)
    //const status = get(err, 'status', null)

    yield put({
      type: createFailed(name),
      payload: {code,message},
      meta: {
        name,
        address: address ? address : null,
        status: 'ERROR'
      }
    })
  }
  finally { }
}

export function* handleAsyncRedux({
  //meta, 
  name, 
  address, 
  func, 
  args,
  holdData,
  handleSuccess=null, 
  handleError=null
}) {

  yield put({
    type: createFetching(name),       
    meta: { 
      name,
      address: address ? address : null,
      holdData,
      status: 'FETCHING'
    }
  })
  try {
    let resp = yield call(func, ...args)
    if(handleSuccess){
      resp = yield handleSuccess(resp)
    }
    yield put({
      type: createSuccess(name),
      payload: resp,
      meta: {
        name, 
        address: address ? address : null,
        status: 'DATA'
      }
    })
    return resp
  }
  catch (err) {
    let message = err.message
    if(handleError){
      message = yield handleError(err)
    }
    yield put({
      type: createFailed(name),
      payload: message,
      meta: {
        name,
        address: address ? address : null,
        status: 'ERROR'
      }
    })
  }

  finally {
    if (yield cancelled()) {
      yield put({
        type: createCancelled(name), 
        meta: {
          name, 
          address: address ? address : null,
          status: 'CLEAN'
        }
      })
    } 
  }
}

export function getResponse(type, err){
  const responses = {
    DEFAULT_MARSHMALLOW_ERROR: [get(err, 'response.data.errors', [1000]), get(err, 'response.data.msg', null)],
    DEFAULT_AUTH_ERROR: [get(err, 'response.data.errors', [1000]), get(err, 'response.data.message', null)],
    NETWORK_ERROR: [[9999], get(err, 'message', 'you should not see this')],
  
    default: [1000, err.message]
  }
  return responses[type] || responses['default']
}

//use reducer
export function getResponseType(err){
  let response_type = 'default'

  // SERVER ERRORS
  if(get(err, 'response.data.msg', null)){
    response_type = 'DEFAULT_MARSHMALLOW_ERROR'
  }
  if(get(err, 'response.data.message', null)){
    response_type = 'DEFAULT_AUTH_ERROR'
  }

  // INTERNAL ERRORS
  // Later

  /*
  if(response_type === 'DEFAULT_AUTH_ERROR' || response_type === 'DEFAULT_MARSHMALLOW_ERROR'){
    console.log('a response type for Auth was not found')
    console.log(err)
    console.log(err.message)   
    console.log(get(err, 'response.data', null))
  }
  */
  if(err.message === 'Network Error') {
    response_type = 'NETWORK_ERROR'
  }
  if(response_type === 'default'){
    console.log('a response type for Auth was not found')
    console.log(err)
    console.log(err.response)
    console.log(err.message)   
  }
  return response_type
}

export function authHandleAsyncRedux({  
  name, 
  address, 
  func, 
  args,
  holdData,
  handleSuccess=null, 
  handleError=null
}) {
  if(!handleSuccess) {
    handleSuccess = resp => resp.data
  }
  if(!handleError) {
    handleError = function* (err) {
      //let err_resp
      const response_type = getResponseType(err)
      const [code, message] = getResponse(response_type,err)

      const status = get(err, ['response', 'status'], null)

      if(message === 'Network Error') {
        yield checkConn()
      }
      
      if(status === 401 && message === 'Token has expired') {
        yield put({
          type: 'REMOVE_ACCESS_TOKEN'
        })
      }
      
      return ({code, message, status})
      /*
      err_resp = get(err, 'response.data.msg', null)  //backend updated to not send msg, is this still needed? 
      if(!err_resp){err_resp = get(err, 'response.data.message', err.message)}
      if(err_resp === 'Network Error') {
        yield checkConn()
      }
      return err_resp
      */
    }
  }
  return handleAsyncRedux({name, address, func, args, holdData, handleSuccess, handleError})
}

export function* handleAsync({
  meta, 
  type, 
  payload, 
  func, 
  args, 
  handleSuccess=null, 
  handleError=null
}) {
  try {
    let resp = yield call(func, ...args)
    if(handleSuccess){
      resp = yield handleSuccess(resp)
    }
    yield put({
      type: createSuccess(type),
      payload: resp,
      meta: {...meta, status: 'CLEAN'}
    })
    return resp
  }
  catch (err) {
    let message = err.message
    if(handleError){
      message = yield handleError(err)
    }
    yield put({
      type: `${type}_FAILED`,
      payload: message,
      error: true,
      meta: {...meta, status: 'CLEAN'}
    })
  }

  finally {
    if (yield cancelled()) {
      yield put({type: createCancelled(type)})
    } 
  }
}


const getAuthData = (resp, _default='You should not see this') => get(resp, 'data', _default)

export function authHandleAsync({meta, type, func, args, handleSuccess=null, handleError=null}) {
  if(!handleSuccess) {
    handleSuccess = getAuthData
  }
  if(!handleError) {
    handleError = err => {
      let err_resp
      err_resp = get(err, 'response.data.msg', null)
      if(!err_resp){err_resp = get(err, 'response.data.message', err.message)}
      return err_resp
    }
  }
  
  return handleAsync({meta, type, func, args, handleSuccess, handleError})
}

export function cleanObj(obj) {
  for (var propName in obj) { 
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
      delete obj[propName];
    }
  }
  return obj
}

export function enhanceAction(action) {
  const { type, promise } = action
  const unmount = { 
    type: createUnmount(type),
    meta: {
      name: type,
      status: 'CLEAN'
    } 
  }
  //const failed = createFailed(type)
  //const blocked = createBlocked(type)
  return({ type, promise, unmount })
}

export function handlePost(api, endpoint, token) {
  return function* (arg) {
    const resp = yield api.post(
      endpoint, 
      arg, 
      token ? createTokenHeader(token) : null
    )
    return resp
  }
}


// createTokenHeader needs clean up
export function handleGet(api, endpoint, token) {
  return function* (arg) {
    const resp = yield api.get(endpoint, { 
      params: arg, 
      headers: token ? createTokenHeader(token).headers : null 
    })
    return resp
  }
}



export function* cancellableAsync(worker, type, payload, meta) {
  yield race([
    call(worker, type, payload, meta),
    take(createUnmount(type))
  ])  
}

/* To be added in the future
//could be better
//fix later
export function omitThunkKey(meta) {
  const nMeta = {...meta}
  const nThunk = {...nMeta.thunk}
  delete nThunk['key']
  nMeta.thunk = nThunk
  return nMeta
}

function* checkConn() {
  try {
    console.log('checking connection...')
    yield axios.get("https://ipv4.icanhazip.com")
    //yield put({type:'CONN'})
  }
  catch(err){
    console.log('unable to connect')
    yield put({type:'DISCONN'})
    yield delay(2000)  }
}

function getResponse(type, err){
  const responses = {
    INVALID_OBJECT_ID: [1001, "Invalid object id", null],
    ACCOUNT_NAME_NO_EXIST: [1003, "Account name does not exist", null],
    NULL_OBJECT_ID: [1002, "Object id is null", null],
    default: [1000, err.message, err]
  }
  return responses[type] || responses['default']
}

function getResponseType(err){
  let response_type = 'default'
  const error_code = get(err, 'data.code',null)
  if(error_code === 4){
    response_type = 'INVALID_OBJECT_ID'
  }
  if(error_code === 7){
    response_type = 'NULL_OBJECT_ID'
  }
  if(err.message === 'Account does not exist'){
    response_type = 'ACCOUNT_NAME_NO_EXIST'
  }
  return response_type
}

export function* _handleAsync({meta, type, payload, func, args, handleSuccess=null, handleError=null}) {
  try {
    let resp = yield call(func, ...args)
    if(handleSuccess){
      resp = yield handleSuccess(resp)
    }
    yield put({
      type: `${type}_SUCCESS`,
      payload: resp,
      meta
    })
    return resp
  }
  catch (err) {

    const response_type = getResponseType(err)
    const [code, message, response] = getResponse(response_type,err)

    yield put({
      type: `${type}_FAILED`,
      payload: { code, message, response},
      error: true,
      meta
    })
  }
  finally {
    if (yield cancelled()) {
      yield put({type: createCancelled(type)})
    }   
  }
}


export function* handleAsync({
  meta, 
  type, 
  payload, 
  func, 
  args, 
  handleSuccess=null, 
  handleError=null
}) {
  try {
    let resp = yield call(func, ...args)
    if(handleSuccess){
      resp = yield handleSuccess(resp)
    }
    yield put({
      type: createSuccess(type),
      payload: resp,
      meta
    })
    return resp
  }
  catch (err) {
    let message = err.message
    //GXC doesnt return err.response on failed request
    /*
    if(!err.response){
      message = 'No response from request'
    } 
    else if(handleError){
      message = yield handleError(err)
    }
    
    if(handleError){
      message = yield handleError(err)
    }
    yield put({
      type: `${type}_FAILED`,
      payload: message,
      error: true,
      meta
    })
  }

  finally {
    if (yield cancelled()) {
      yield put({type: createCancelled(type)})
    } 
  }
}
*/


/*
export function* _handleAsync({meta, type, payload, func, args, handleSuccess=null, handleError=null}) {
  try {
    let resp = yield call(func, ...args)
    if(handleSuccess){
      resp = yield handleSuccess(resp)
    }
    yield put({
      type: `${type}_SUCCESS`,
      payload: resp,
      meta,
      status: 'CLEAN'
    })
    //yield put({ type: `CONN` })
    return resp
  }
  catch (err) {
    const status = get(err, 'status', null)
    //auto message format from python JWT library
    const msg = get(err, 'response.data.msg', null)
    if(!err.response){
      yield checkConn()

      // All queue are placed in local first
      // on dismount, local queue (and promise) is cancelled
      // Global queue are forwarded to global channel
      if(meta.queue==='LOCAL' || meta.queue==='GLOBAL'){ 
        yield put({
          type: `${type}_LOCAL_QUEUED`, 
          meta: omitThunkKey(meta), 
          status: 'QUEUED'
        }) 
        yield take('CONN')
        yield call(handleAsync,{
          meta,
          type,
          func,
          args,
          handleSuccess,
          handleError
        })  
        return null
      } 
  
      yield put({
        type: `${type}_FAILED`,
        payload: 'Can not connect',
        error: true,
        meta: meta.queue ? omitThunkKey(meta) : meta,
        status: meta.queue ? null : 'CLEAN'
      })
      return null     
    }

    if(status === 401 && msg === 'Token has expired') {
      yield put({
        type: 'LOGOUT_USER'
      })
    }
    
    let message = err
    if(handleError){
      message = yield handleError(err)
    }    

    yield put({
      type: `${type}_FAILED`,
      payload: message,
      error: true,
      meta,
      status: 'CLEAN'
    })
  }
  finally {
    if (yield cancelled()) {
      yield put({type: createCancelled(type)})
      if(meta.queue === 'GLOBAL'){
        yield put({
          type: `${type}_GLOBAL_QUEUED`, 
          meta: omitThunkKey(meta), 
          queue: {type, payload, meta},
          //status: 'QUEUED'  //local queue already updates status
        })
        return null     
      }
    }   
  }
}
*/