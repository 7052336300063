import React, { useState, useContext, useEffect, useRef, Fragment } from 'react';
import { useSelector, useStore, useDispatch } from 'react-redux'

import get from "lodash/get"

import { useThunkDataV3 } from 'components/useHooks/useThunk'
import { createUserSchema } from "utilities/joi/sto"
import { createUnmountDispatch } from "utilities/ETH"

import { getHasMetaMask, getNetworkId } from 'utilities/selectors'


export const useMetaMask = () => {
  const dispatch = useDispatch() 
  const [data, error, status] = useThunkDataV3('ETH_LOAD_METAMASK')
  const hasMetaMask = useSelector(getHasMetaMask)

  const handleLoad = () => {
    dispatch({type:'ETH_LOAD_METAMASK'})
  }
  const unmount = () => {
    dispatch({type: 'ETH_LOAD_METAMASK_UNMOUNT', meta: {name:'ETH_LOAD_METAMASK', status:'CLEAN' }})
  }
  return [data, error, status, handleLoad, unmount]
}

export const useProvider = () => {
  const dispatch = useDispatch() 
  const [data, error, status] = useThunkDataV3('ETH_LOAD_PROVIDER')

  const handleLoad = () => {
    dispatch({type:'ETH_LOAD_PROVIDER'})
  }
  const unmount = createUnmountDispatch(dispatch, 'ETH_LOAD_PROVIDER')

  return [data, error, status, handleLoad, unmount]
}
